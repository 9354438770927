export function LoginSecurityIcon() {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3.46154L9.5 0L0 3.46154V12.7692C0 16.9615 2.28704 20.7308 5.84074 22.3462L9.5 24L13.1593 22.3462C16.713 20.7308 19 16.9615 19 12.7692V3.46154ZM16.8889 12.7692C16.8889 16.0385 15.0944 18.9615 12.35 20.2308L9.5 21.5L6.65 20.1923C3.90556 18.9615 2.11111 16 2.11111 12.7692V5.11538L9.5 2.42308L16.8889 5.11538V12.7692Z"
        fill="#E75113"
      />
      <path
        d="M6.3684 10L4.89062 11.6154L8.05729 15.0769C8.2684 15.3077 8.5147 15.4231 8.79618 15.4231C9.07766 15.4231 9.35914 15.2692 9.53507 15.0385L14.8128 8.88462L13.2647 7.30769L8.76099 12.6154L6.3684 10Z"
        fill="#E75113"
      />
    </svg>
  );
}
