import {getHandle} from '~/lib/utils';
import {UserAvatar} from './global/UserAvatar';
import {Link, useOutletContext} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';
import {Button} from './Button';
import {NotaTa} from './NotaTa';
import {FollowUser} from './FollowUser';

export function NotificationCard({notification, type, index, length}) {
  const {customer} = useOutletContext();
  if (type === 'review') {
    if (notification.type === 'review') {
      return (
        <div
          key={notification.id}
          className={
            index === 0 && index === length - 1
              ? 'w-full'
              : index === 0
              ? 'pb-5 w-full'
              : index === length - 1
              ? 'border-line border-t pt-5 w-full'
              : 'border-line border-t py-5 w-full'
          }
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <UserAvatar customer={notification.user} size={48} />
              <div className="ml-3 mr-4">
                <div>
                  <NotaTa
                    customer={notification.user}
                    nota={notification.review?.stars}
                  />
                  <span className="text-primary">
                    {notification.user?.firstName || 'Un cititor'}{' '}
                    {notification.user?.lastName || null}
                  </span>{' '}
                  a scris o recenzie la{' '}
                  <Link
                    to={`/carti/${getHandle(
                      notification.review?.product_handle,
                    )}`}
                    className="text-primary"
                  >
                    {notification.review?.product_title}
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <Image
                data={
                  notification.review?.product_image?.url &&
                  notification.review?.product_image?.url !== 'undefined'
                    ? {
                        url: notification.review?.product_image?.url,
                        width: notification.review?.product_image?.width,
                        height: notification.review?.product_image?.height,
                        altText: notification.review?.title,
                      }
                    : {
                        url: `https://via.placeholder.com/400x600/eeeeee/ffffff?text=...`,
                        width: 400,
                        height: 600,
                        altText: 'Product',
                      }
                }
                className="w-12"
              />
            </div>
          </div>
        </div>
      );
    }
  }
  if (notification.type === 'follower') {
    return (
      <div
        key={notification.id}
        className={
          index === 0
            ? 'pb-5 w-full'
            : index === length - 1
            ? 'border-line border-t pt-5 w-full'
            : 'border-line border-t py-5 w-full'
        }
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <UserAvatar customer={notification.user} size={48} />
            <div className="ml-3 mr-4">
              <div>
                <span className="text-primary">
                  {notification.user.firstName || 'Un cititor'}{' '}
                  {notification.user.lastName || null}
                </span>{' '}
                te urmărește
              </div>
            </div>
          </div>
          <div>
            <FollowUser
              user={{id: notification.user.customer}}
              customer={customer}
            />
          </div>
        </div>
      </div>
    );
  }
  if (notification.type === 'review_answer') {
    return (
      <div
        key={notification.id}
        className={
          index === 0
            ? 'pb-5 w-full'
            : index === length - 1
            ? 'border-line border-t pt-5 w-full'
            : 'border-line border-t py-5 w-full'
        }
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <UserAvatar customer={notification.user} size={48} />
            <div className="ml-3 mr-4">
              <div>
                <span className="text-primary">
                  {notification.user?.firstName || 'Un cititor'}{' '}
                  {notification.user?.lastName || null}
                </span>{' '}
                a răspuns la recenzia ta <em>"{notification.review_title}"</em>{' '}
                {notification.answer?.product_handle ? (
                  <span>
                    pentru cartea{' '}
                    <Link
                      to={`/carti/${getHandle(
                        notification.answer?.product_handle,
                      )}`}
                      className="text-primary"
                    >
                      {notification.answer.product_title.split('-')[0]}
                    </Link>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <Button
              url={`/carti/${getHandle(notification.answer?.product_handle)}`}
              label="Vezi"
              variant="sm_primary"
            />
          </div>
        </div>
      </div>
    );
  }
  if (notification.type === 'question_answer') {
    return (
      <div
        key={notification.id}
        className={
          index === 0
            ? 'pb-5 w-full'
            : index === length - 1
            ? 'border-line border-t pt-5 w-full'
            : 'border-line border-t py-5 w-full'
        }
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <UserAvatar customer={notification.user} size={48} />
            <div className="ml-3 mr-4">
              <div>
                <span className="text-primary">
                  {notification.user?.firstName || 'Un cititor'}{' '}
                  {notification.user?.lastName || null}
                </span>{' '}
                a răspuns la întrebarea ta pentru cartea{' '}
                {notification.answer?.product_handle &&
                notification.answer?.product_title ? (
                  <Link
                    to={`/carti/${getHandle(
                      notification.answer?.product_handle,
                    )}`}
                    className="text-primary"
                  >
                    {notification.answer.product_title.split('-')[0]}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
          {notification.answer?.product_handle ? (
            <div>
              <Button
                url={`/carti/${getHandle(notification.answer?.product_handle)}`}
                label="Vezi"
                variant="sm_primary"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return `Notification ${type}`;
}
