export function BackIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L1 10.5M1 10.5L9 20M1 10.5L21 10.5"
        stroke="#E7EFFB"
        strokeWidth="1.5"
      />
    </svg>
  );
}
