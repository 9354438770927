import {UserAvatar} from '~/components';

export function ProfileHeader({
  customer,
  title,
  subtitle,
  metrics = [],
  actions = null,
  showAvatar = true,
  shelve,
}) {
  return (
    <div className="py-4 md:py-12 lg:py-20 xl:py-28">
      <div className="container mx-auto px-4">
        <div className="text-center flex flex-col items-center">
          {showAvatar ? (
            <UserAvatar
              customer={customer}
              size={120}
              className="mb-6 md:mb-10"
              disableMarginRight={true}
              background="#ffffff"
              showEdit={false}
            />
          ) : null}
          <h1 className="font-sans font-bold text-3xl md:text-5xl">{title}</h1>
          {subtitle ? (
            <div className="flex items-start">
              <p className="font-serif mt-2 md:mt-5 mb-7 md:mb-0 flex items-center justify-center">
                <span className="opacity-70 ml-1">{subtitle}</span>
              </p>
            </div>
          ) : null}
          {metrics.length > 0 ? (
            <div className="flex items-start">
              {metrics.map((metric, index) => {
                return (
                  <p
                    key={metric.id}
                    className={
                      index > 0
                        ? 'font-serif mt-5 flex items-center justify-center mb-0 pl-3'
                        : 'font-serif mt-5 flex items-center justify-center mb-0'
                    }
                  >
                    <span className="mr-3">{metric.icon}</span>
                    {metric.value}
                    <span className="opacity-70 ml-1 hidden md:block">
                      {metric.value === 1
                        ? metric.label.singular
                        : metric.label.plural}
                    </span>
                  </p>
                );
              })}
            </div>
          ) : null}
        </div>

        {actions}
      </div>
    </div>
  );
}
