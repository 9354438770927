import {useState, useEffect, useCallback} from 'react';
import {Dialog} from '@headlessui/react';
import {CloseIcon, Button, ArrowRightIcon, SearchIcon} from '~/components';
import {v4 as uuidv4} from 'uuid';
import remove from 'lodash/remove';
import find from 'lodash/find';
import {flattenConnection, Image} from '@shopify/hydrogen';

import {SpinnerIcon, CheckIcon, WarningIcon, Toast} from '~/components';
import {Link, useFetcher, useOutletContext} from '@remix-run/react';
import {getAuthor} from '~/lib/utils';

export function CreateList({
  className = 'text-lg text-left md:text-center p-5 md:text-primary border-b md:border-primary md:py-1 md:px-0',
  showArrow = false,
  icon,
}) {
  const {customer} = useOutletContext();
  const labels = [
    {title: 'Acasă', handle: 'acasa', id: uuidv4()},
    {title: 'Acțiune', handle: 'actiune', id: uuidv4()},
    {title: 'Atlas', handle: 'atlas', id: uuidv4()},
    {title: 'Aventură', handle: 'aventura', id: uuidv4()},
    {title: 'Animale', handle: 'animale', id: uuidv4()},
    {title: 'Arte', handle: 'arte', id: uuidv4()},
    {title: 'Basme', handle: 'basme', id: uuidv4()},
    {title: 'Benzi desenate', handle: 'benzi-desenate', id: uuidv4()},
    {title: 'Biografie', handle: 'biografie', id: uuidv4()},
    {title: 'Calculatoare', handle: 'calculatoare', id: uuidv4()},
    {title: 'Cărți ilustrate', handle: 'carti-ilustrate', id: uuidv4()},
    {title: 'Cărți pop-up', handle: 'carti-pop-up', id: uuidv4()},
    {title: 'Clasic', handle: 'clasic', id: uuidv4()},
  ];
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isNextStepAvailable, setIsNextStepAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [searchProducts, setSearchProducts] = useState([]);
  const [term, setTerm] = useState('');
  const [toasts, setToasts] = useState([]);

  const fetcher = useFetcher();

  useEffect(() => {
    if (
      fetcher.type == 'actionReload' &&
      fetcher.state == 'loading' &&
      !fetcher.data
    ) {
      //window.location.reload();
    }

    if (fetcher.data?.products) {
      setSearchProducts(fetcher.data?.products);
    }
  }, [fetcher.state]);

  useEffect(() => {
    if (
      fetcher.submission?.action === '/api/lists/create' &&
      fetcher.data &&
      fetcher.type === 'actionReload'
    ) {
      setIsLoading(false);
      resetForm();
      setOpen(false);
    }
  }, [fetcher.submission]);

  const closeModal = () => {
    setOpen(false);
  };

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  useEffect(() => {
    if (step === 1 && title.length > 0 && title.length < 70) {
      setIsNextStepAvailable(true);
    }

    if (step === 2 && selectedLabels.length > 0 && selectedLabels.length <= 5) {
      setIsNextStepAvailable(true);
    }

    if (
      step === 3 &&
      selectedProducts.length > 0 &&
      selectedProducts.length <= 5
    ) {
      setIsNextStepAvailable(true);
    }
  }, [step, title, description, selectedLabels, selectedProducts]);

  const handleSearch = async (event) => {
    setTerm(event.target.value);
    if (event.target.value.length > 2) {
      fetcher.submit(
        {term: event.target.value},
        {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          action: `/api/lists/search`,
        },
      );
    } else {
      setSearchProducts([]);
    }
  };

  const handleTitleChange = (event) => {
    if (event.target.value.length <= 70) {
      setTitle(event.target.value);
    }

    if (event.target.value.length === 0) {
      setIsNextStepAvailable(false);
    }
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 3000) {
      setDescription(event.target.value);
    }

    if (event.target.value.length === 0) {
      setIsNextStepAvailable(false);
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
    const nextStep = step + 1;
    if (nextStep === 3 && selectedProducts.length === 0) {
      setIsNextStepAvailable(false);
    }

    if (nextStep === 2 && selectedLabels.length === 0) {
      setIsNextStepAvailable(false);
    }
  };
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSelectedLabelsChange = (label) => {
    const isLabelSelected = find(selectedLabels, function (o) {
      return o.id === label.id;
    });

    if (isLabelSelected) {
      const newSelectedLabels = remove(selectedLabels, function (n) {
        return n.id !== label.id;
      });
      if (newSelectedLabels.length === 0) {
        setIsNextStepAvailable(false);
      }
      setSelectedLabels(newSelectedLabels);
    } else {
      if (selectedLabels.length < 5) {
        const newSelectedLabels = [...selectedLabels, {...label}];
        setSelectedLabels(newSelectedLabels);
      } else {
        addToasts([
          {
            id: uuidv4(),
            message: 'Poti selecta un maximum de 5 etichete',
            type: 'warning',
            icon: <WarningIcon />,
          },
        ]);
      }
    }
  };

  const handleSelectedProductsChange = (product) => {
    const isProductSelected = find(selectedProducts, function (o) {
      return o.id === product.id;
    });

    if (isProductSelected) {
      const newSelectedProducts = remove(selectedProducts, function (n) {
        return n.id !== product.id;
      });
      if (newSelectedProducts.length === 0) {
        setIsNextStepAvailable(false);
      }
      setSelectedProducts(newSelectedProducts);
    } else {
      const author =
        product && product.collections
          ? getAuthor(flattenConnection(product.collections))
          : 'Necunoscut';
      let p = {
        id: product.id,
        image: product.featuredImage,
        title: product.title,
        handle: product.handle,
        author: author.title,
      };
      const newSelectedProducts = [...selectedProducts, {...p}];
      setSelectedProducts(newSelectedProducts);
    }
  };

  const handleCreateList = async () => {
    setIsLoading(true);

    fetcher.submit(
      {
        title,
        description: description || '',
        labels: JSON.stringify([...selectedLabels]),
        products: JSON.stringify([...selectedProducts]),
      },
      {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        action: `/api/lists/create`,
      },
    );
  };

  const resetForm = () => {
    setStep(1);
    setTitle('');
    setDescription('');
    setSelectedLabels([]);
    setSelectedProducts([]);
    setIsNextStepAvailable(false);
    setSearchProducts([]);
    setTerm('');
  };

  if (!customer) {
    return (
      <Link to="/profil/login" className={className}>
        {icon || null}
        Listă nouă
        {showArrow ? <ArrowRightIcon /> : null}
      </Link>
    );
  }

  return (
    <>
      <button
        onClick={() => {
          setOpen(true);
        }}
        className={className}
      >
        {icon || null}
        Listă nouă
        {showArrow ? <ArrowRightIcon /> : null}
      </button>
      <>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between border-b border-line pt-4 pb-3 px-5"
                >
                  {step === 1 ? 'Listă nouă' : null}
                  {step === 2 ? 'Etichete' : null}
                  {step === 3 ? 'Cărți' : null}
                  <button
                    className="w-8 h-8 flex items-center justify-center"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <CloseIcon />
                  </button>
                </Dialog.Title>
                {step === 3 ? (
                  <>
                    <div className="py-4 px-5 font-serif border-b borrder-line">
                      <div className="relative border boder-line rounded-lg">
                        <form
                          action="/api/lists/search"
                          onSubmit={handleSearch}
                        >
                          <input
                            type="text"
                            placeholder="Caută cărți sau autori"
                            className="focus:outline-none focus:ring-2 focus:ring-gray-300 h-10 py-0 px-4 rounded-lg w-full font-serif text-lg font-normal border-none"
                            autoComplete="off"
                            onChange={handleSearch}
                            name="q"
                          />
                          <button
                            type="submit"
                            className="absolute w-10 h-10 flex items-center justify-center top-0 right-0"
                          >
                            <SearchIcon />
                          </button>
                        </form>
                      </div>

                      {selectedProducts.length > 0 ? (
                        <div className="flex flex-wrap mt-3">
                          {selectedProducts.map((product) => {
                            return (
                              <div
                                key={product.id}
                                className="pr-5 w-1/4 pt-1 pb-1 relative"
                              >
                                {product.featuredImage ? (
                                  <div>
                                    <Image
                                      data={{
                                        width: product.featuredImage.width,
                                        height: product.featuredImage.height,
                                        url:
                                          product.featuredImage.url ||
                                          'https://via.placeholder.com/400x600/eeeeee/ffffff?text=...',
                                        altText: product.title,
                                      }}
                                      className="w-11 mr-3"
                                    />
                                    <button
                                      onClick={() => {
                                        handleSelectedProductsChange(product);
                                      }}
                                      className="absolute right-6 top-0 bg-body h-4 w-4 rounded-full flex items-center justify-center"
                                    >
                                      <CloseIcon size={8} />
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="py-4 px-5 font-serif">
                      {searchProducts.length > 0 &&
                        searchProducts.map((product, index) => {
                          return (
                            <div key={product.id}>
                              <div>
                                <label
                                  className={
                                    index === searchProducts.length - 1
                                      ? 'flex justify-between items-center cursor-pointer text-lg'
                                      : 'flex justify-between items-center cursor-pointer text-lg mb-3'
                                  }
                                >
                                  <div className="flex">
                                    {product.featuredImage ? (
                                      <Image
                                        data={{
                                          width: product.featuredImage.width,
                                          height: product.featuredImage.height,
                                          url:
                                            product.featuredImage.url ||
                                            'https://via.placeholder.com/400x600/eeeeee/ffffff?text=...',
                                          altText: product.title,
                                        }}
                                        className="w-7 mr-3"
                                      />
                                    ) : null}
                                    {product.title.split('-')[0]}
                                  </div>
                                  <input
                                    type="checkbox"
                                    value={product.id}
                                    checked={
                                      find(selectedProducts, function (o) {
                                        return o.id === product.id;
                                      })
                                        ? true
                                        : false
                                    }
                                    name={product.id}
                                    onChange={() => {
                                      handleSelectedProductsChange(product);
                                    }}
                                    className="hidden"
                                  />

                                  <span className="w-5 h-5 rounded-full border border-line flex items-center justify-center">
                                    {find(selectedProducts, function (o) {
                                      return o.id === product.id;
                                    }) ? (
                                      <span
                                        className="bg-primary rounded-full"
                                        style={{width: '10px', height: '10px'}}
                                      ></span>
                                    ) : null}
                                  </span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : null}
                {step === 2 ? (
                  <div className="py-4 px-5 font-serif">
                    <div className="uppercase mb-2">Generale</div>
                    {labels.map((label, index) => {
                      return (
                        <div key={label.id}>
                          <div>
                            <label
                              className={
                                index === labels.length - 1
                                  ? 'flex justify-between items-center cursor-pointer text-lg'
                                  : 'flex justify-between items-center cursor-pointer text-lg mb-3'
                              }
                            >
                              {label.title}
                              <input
                                type="checkbox"
                                value={label.id}
                                checked={
                                  find(selectedLabels, function (o) {
                                    return o.handle === label.handle;
                                  })
                                    ? true
                                    : false
                                }
                                name={label.handle}
                                onChange={() => {
                                  handleSelectedLabelsChange(label);
                                }}
                                className="hidden"
                              />

                              <span className="w-5 h-5 rounded-full border border-black border-opacity-10 flex items-center justify-center">
                                {find(selectedLabels, function (o) {
                                  return o.handle === label.handle;
                                }) ? (
                                  <span
                                    className="bg-primary rounded-full"
                                    style={{width: '10px', height: '10px'}}
                                  ></span>
                                ) : null}
                              </span>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {step === 1 ? (
                  <div className="py-4 px-5 font-serif">
                    <div className="flex justify-between text-xs font-serif mb-2">
                      <span className="uppercase">Titlu</span>
                      <span className="opacity-50">
                        {70 - title.length} caractere rămase
                      </span>
                    </div>

                    <input
                      className="border-gray-300 px-4 h-10 border border-100 rounded-lg w-full"
                      type="text"
                      placeholder="Titlu"
                      value={title}
                      onChange={handleTitleChange}
                    />

                    <div className="flex justify-between text-xs font-serif mb-2 mt-5">
                      <span className="uppercase">Descriere</span>
                    </div>
                    <textarea
                      cols="30"
                      rows="6"
                      value={description}
                      className="border-gray-300 px-4 py-2 w-full border border-100 rounded-lg"
                      placeholder="Scrie aici"
                      onChange={handleDescriptionChange}
                    ></textarea>
                  </div>
                ) : null}
                <div className="py-4 px-5 flex flex-col justify-center border-t border-line">
                  {step === 2 ? (
                    <p className="text-center text-xs opacity-50 mb-4">
                      Maxim 5 etichete
                    </p>
                  ) : null}
                  {step === 1 || step === 2 ? (
                    <Button
                      handleClick={handleNextStep}
                      variant="sm_primary"
                      label="Continuă"
                      className="w-full"
                      disabled={!isNextStepAvailable}
                    />
                  ) : null}

                  {step === 3 ? (
                    <Button
                      handleClick={handleCreateList}
                      variant="sm_primary"
                      label={
                        isLoading ? <SpinnerIcon size={30} /> : 'Crează lista'
                      }
                      className="w-full justify-center flex"
                      disabled={!isNextStepAvailable || isLoading}
                    />
                  ) : null}

                  {step > 1 ? (
                    <Button
                      handleClick={handlePreviousStep}
                      variant="naked"
                      label="Înapoi"
                      className="w-full"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
