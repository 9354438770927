import {useEffect, useState, useCallback} from 'react';
import {
  AddToList,
  AddToShelve,
  Button,
  HeartIcon,
  ListIcon,
  Toast,
} from '~/components';
import {AddReview, SeeReview} from '~/components';
import {remove} from 'lodash/remove';
import {useFetcher, useOutletContext} from '@remix-run/react';

export function ProductShelve({shelve, product, firebaseProduct, author}) {
  const [open, setOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [writeReviewOpen, setWriteReviewOpen] = useState(false);
  const [seeReviewOpen, setSeeReviewOpen] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [error, setError] = useState(null);
  const {customer} = useOutletContext();

  let fetcher = useFetcher();

  const addShelve = ({
    product_image_src,
    product_image_width,
    product_image_height,
    product_image_alt,
    author_handle,
    author_id,
    author_title,
    product_handle,
    product_id,
    title,
    shelve,
  }) => {
    fetcher.submit(
      {
        product_image_src,
        product_image_width,
        product_image_height,
        product_image_alt,
        author_handle,
        author_id,
        author_title,
        product_handle,
        product_id,
        title,
        shelve,
      },
      {method: 'post', action: `/api/shelves/add`},
    );
  };

  const addReview = ({
    review,
    title,
    stars,
    product_handle,
    product_id,
    product_title,
    product_image_src,
    product_image_width,
    product_image_height,
    product_image_alt,
    product_author,
    shelve,
  }) => {
    setError(null);
    if (customer) {
      fetcher.submit(
        {
          review,
          title,
          stars,
          product_handle,
          product_id,
          product_title,
          product_image_src,
          product_image_width,
          product_image_height,
          product_image_alt: product_image_alt || title,
          product_author,
          shelve,
        },
        {
          method: 'post',
          action: `/api/reviews/add`,
        },
      );
    }
  };

  const addToasts = useCallback(
    (items) => {
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  useEffect(() => {
    if (
      fetcher.type == 'actionReload' &&
      fetcher.state == 'loading' &&
      !fetcher.data?.error
    ) {
      window.location.reload();
    }

    if (
      fetcher.state !== 'idle' &&
      fetcher.state !== 'submitting' &&
      fetcher.data?.error
    ) {
      setError(fetcher.data.error);
    }
  }, [fetcher.state]);

  return (
    <>
      <div className="space-y-2 py-3 border-t border-line">
        <div className="hidden font-serif uppercase text-xs tracking-widest mb-4">
          Salvează
        </div>

        <div className="flex">
          {customer ? (
            <Button
              handleClick={
                firebaseProduct?.userReview
                  ? () => {
                      setSeeReviewOpen(true);
                    }
                  : () => {
                      setWriteReviewOpen(true);
                    }
              }
              variant={
                firebaseProduct?.userReview ? 'sm_secondary' : 'sm_primary'
              }
              className="flex-grow md:mt-0"
              label={
                firebaseProduct?.userReview
                  ? 'Vezi recenzie'
                  : 'Scrie o recenzie'
              }
            />
          ) : null}

          {!customer ? (
            <Button
              url="/profil/login"
              variant="sm_primary"
              className="flex-grow md:mt-0"
              label="Scrie o recenzie"
            />
          ) : null}
        </div>

        <div className="flex flex-row gap-2">
          {customer ? (
            <Button
              handleClick={() => {
                setOpen(true);
              }}
              variant="sm_secondary"
              className="flex-grow"
              label={
                <div className="flex items-center justify-center">
                  <HeartIcon />{' '}
                  {shelve ? (
                    <span className="ml-3">
                      {shelve?.shelve === 'carti-cumparate-citite'
                        ? 'Cărți cumpărate, citite'
                        : null}
                      {shelve?.shelve === 'carti-cumparate-necitite'
                        ? 'Cărți cumpărate, necitite'
                        : null}
                      {shelve?.shelve === 'wishlist' ? 'Wishlist' : null}

                      {!shelve?.shelve ? 'Adauga in raft' : null}
                    </span>
                  ) : (
                    <span className="ml-3">Adaugă în raftul meu</span>
                  )}
                </div>
              }
            />
          ) : null}

          {!customer ? (
            <Button
              url="/profil/login"
              variant="sm_secondary"
              className="flex-grow"
              label={
                <div className="flex items-center justify-center">
                  <HeartIcon />{' '}
                  <span className="ml-3">Adaugă în raftul meu</span>
                </div>
              }
            />
          ) : null}

          {/*******************/}
          {/*******************/}
          {/*******************/}
          {/*******************/}

          {customer ? (
            <Button
              handleClick={() => {
                setListOpen(true);
              }}
              variant="sm_secondary"
              className="flex-grow"
              label={
                <div className="flex items-center justify-center">
                  <HeartIcon /> <span className="ml-3">Adaugă în listă</span>
                </div>
              }
            />
          ) : null}

          {!customer ? (
            <Button
              url="/profil/login"
              variant="sm_secondary"
              className="flex-grow"
              label={
                <div className="flex items-center justify-center">
                  <ListIcon size={14} fill="#514d71" />{' '}
                  <span className="ml-3">Adaugă în listă</span>
                </div>
              }
            />
          ) : null}
        </div>
      </div>

      {/*******************/}
      {/*******************/}
      {/*******************/}
      {/*******************/}
      {customer ? (
        <>
          <AddToShelve
            open={open}
            setOpen={setOpen}
            shelve={shelve}
            product={product}
            author={author}
            customer={customer}
            addToasts={addToasts}
            addShelve={addShelve}
          />
          <AddToList
            open={listOpen}
            setOpen={setListOpen}
            product={{
              id: product.id,
              handle: product.handle,
              image: product.featuredImage,
              author: author?.title || 'Necunoscut',
              title: product.title,
            }}
            addToasts={addToasts}
          />
        </>
      ) : null}

      {customer && !firebaseProduct?.userReview ? (
        <AddReview
          customer={customer}
          id={product.id}
          handle={product.handle}
          title={product.title}
          image={{
            url: product.featuredImage.url,
            width: product.featuredImage.width,
            height: product.featuredImage.height,
            altText: product.title,
          }}
          author={author?.title || 'Necunoscut'}
          open={writeReviewOpen}
          setOpen={setWriteReviewOpen}
          addToasts={addToasts}
          addReview={addReview}
          error={error}
        />
      ) : null}

      {customer && firebaseProduct?.userReview ? (
        <SeeReview
          review={firebaseProduct.userReview}
          open={seeReviewOpen}
          setOpen={setSeeReviewOpen}
          title={product?.title}
        />
      ) : null}

      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-[100] w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
