import {Button} from './Button';
import {Text} from './Text';

export function NotFound({type = 'page'}) {
  const heading = `Această pagină nu există`;
  const description = `Verifică adresa sau vezi toate cărțile.`;

  return (
    <>
      <div className="container mx-auto px-4 pr-0 md:pr-4 py-10 md:py-20 xl:py-20">
        <div className="flex flex-col justify-center items-center text-center mx-auto">
          <h1 className="text-center text-4xl md:text-5xl text-gray-900 mb-6 mt-6 font-sans font-bold">
            {heading}
          </h1>
          <Text width="narrow" as="p">
            {description}
          </Text>
          <Button
            variant="secondary"
            url={'/'}
            label="Vezi cărți"
            className="max-w-xs mx-auto mt-4"
          />
        </div>
      </div>
    </>
  );
}
