export function CategoryIcon() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 20.55 0.45 21 1 21H7C8.375 21 9.55 21.925 9.9 23.25C10.025 23.7 10.4 24 10.875 24H11.15C11.6 24 12 23.7 12.125 23.25C12.475 21.925 13.65 21 15.025 21H21.025C21.575 21 22.025 20.55 22.025 20V5C22.025 4.45 21.575 4 21.025 4H15C13.925 4 12.85 4.375 12 5.025V5C12 2.25 9.75 0 7 0H6C5.45 0 5 0.45 5 1V4H1C0.45 4 0 4.45 0 5V20ZM15 6H20V19H15C13.925 19 12.85 19.35 12 20.025V9C12 7.35 13.35 6 15 6ZM7 2C8.65 2 10 3.35 10 5V16.025C9.15 15.375 8.075 15 7 15V2ZM2 6H5V16C5 16.55 5.45 17 6 17H7C8.65 17 10 18.35 10 20V20.025C9.15 19.375 8.075 19 7 19H2V6Z"
        fill="white"
      />
    </svg>
  );
}
