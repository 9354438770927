import {Button, ListProductCard, SpinnerIcon} from '~/components';

export function ListProductGrid({
  products,
  isLoading,
  nextPage,
  fetchMoreProducts,
}) {
  return (
    <div className="container mx-auto px-0 md:px-4">
      <div className="px-4 md:px-0">
        <div className={nextPage ? 'mb-12 lg:mb-16' : 'mb-12 lg:mb-24'}>
          <div className="">
            {products &&
              products.map((product, index) => {
                return (
                  <ListProductCard
                    key={product.id}
                    product={product}
                    index={index}
                  />
                );
              })}
          </div>
        </div>
        {nextPage && (
          <div className="flex items-center justify-center  pb-8 md:pb-28">
            <Button
              disabled={isLoading}
              variant="secondary"
              handleClick={fetchMoreProducts}
              width="full"
              prefetch="intent"
              label={isLoading ? <SpinnerIcon size={24} /> : 'Mai multe cărți'}
            />
          </div>
        )}
      </div>
    </div>
  );
}
