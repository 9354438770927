import {useFetcher, useNavigate, useOutletContext} from '@remix-run/react';
import {useState, useEffect} from 'react';
import {
  SpinnerIcon,
  StarFullHoverIcon,
  StarFullIcon,
  StarHoverIcon,
  StarIcon,
} from './icons';

const stars = [1, 2, 3, 4, 5];
export function NotaCititor({
  nota,
  product = null,
  firebaseProduct = null,
  author = null,
}) {
  const [selectedStars, setSelectedStars] = useState(null);
  const [hoveredStars, setHoveredStars] = useState(null);
  const [focusedStar, setFocusedStar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {customer} = useOutletContext();
  const navigate = useNavigate();

  const fetcher = useFetcher();

  const handleSelectedStars = (star) => {
    setSelectedStars(star);
    if (customer) {
      setIsLoading(true);
      fetcher.submit(
        {
          review: '',
          title: '',
          stars: star,
          product_handle: product.handle,
          product_id: product.id,
          product_title: product.title,
          product_image_src: product.featuredImage.url,
          product_image_width: product.featuredImage.width,
          product_image_height: product.featuredImage.height,
          product_image_alt: product.featuredImage.alt || product.title,
          product_author: author,
          shelve: '',
        },
        {method: 'post', action: `/api/reviews/add`},
      );
    } else {
      navigate('/profil/login');
    }
  };

  //   useEffect(() => {
  //     console.log(fetcher);
  //   }, [fetcher.state]);

  const handleHoveredStars = (star) => {
    setHoveredStars(star);
  };
  const handleFocusedStar = (star) => {
    setFocusedStar(star);
  };
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-1">
        {[1, 2, 3, 4, 5].map((star) => {
          return (
            <button
              key={star}
              onClick={() => {
                handleSelectedStars(star);
              }}
              onMouseOver={() => {
                handleHoveredStars(star);
              }}
              onFocus={() => {
                handleFocusedStar(star);
              }}
              onMouseLeave={() => {
                handleHoveredStars(0);
              }}
              className="text-primary disabled:text-gray-400"
              disabled={isLoading}
            >
              {selectedStars >= star ? (
                hoveredStars >= star ? (
                  <StarFullIcon size={20} fill="currentColor" />
                ) : hoveredStars > 0 ? (
                  <StarFullHoverIcon
                    size={20}
                    stroke="currentColor"
                    fill="currentColor"
                  />
                ) : (
                  <StarFullIcon size={20} fill="currentColor" />
                )
              ) : hoveredStars >= star ? (
                <StarHoverIcon
                  size={20}
                  stroke="currentColor"
                  fill="currentColor"
                />
              ) : (
                <StarIcon size={20} stroke="currentColor" />
              )}
            </button>
          );
        })}
      </div>
      {selectedStars ? (
        <>
          <div>{selectedStars}/5</div>
          {isLoading ? <SpinnerIcon size={26} /> : null}
        </>
      ) : (
        <div>0/5</div>
      )}
    </div>
  );
}
