import {useIsHomePath} from '~/lib/utils';
import {
  Drawer,
  useDrawer,
  Text,
  Cart,
  CartLoading,
  Link,
  LogoGirlIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  MenuMobileIcon,
  AccountMobileIcon,
  SearchMobileIcon,
  BagIcon,
  ArrowDownIcon,
  CloseIcon,
  CategoryIcon,
  ParentsIcon,
  AgeIcon,
  MoreIcon,
  ThemeIcon,
  SeriesIcon,
  ListIcon,
  AuthorIcon,
  VideoIcon,
  AboutUsIcon,
  ConnectIcon,
  LegalIcon,
  UsersIcon,
  NotificationIcon,
  HeartIcon,
  ProfileIcon,
  ListsIcon,
  FileIcon,
  FollowingIcon,
  FollowersIcon,
  SettingsIcon,
  LogoutIcon,
  ArrowRightIcon,
  BackIcon,
} from '~/components';
import {useFetcher, useNavigate} from '@remix-run/react';
import {useParams, Form, Await, useMatches} from '@remix-run/react';
import {useWindowScroll} from 'react-use';
import {Suspense, useEffect, useState, useMemo} from 'react';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import {flattenConnection} from '@shopify/hydrogen';
import {SearchForm} from './SearchForm';
import {SearchCard} from '../cards/SearchCard';

import {apiCall} from '~/lib/utils';
import {UserAvatar} from './UserAvatar';

export function Layout(props) {
  const {children, layout} = props;
  return (
    <>
      <div className="min-h-screen max-w-screen text-gray-700 font-sans flex flex-col">
        <Header
          title={layout?.shop.name ?? 'Carti pentru copilul meu'}
          menu={layout?.headerMenu}
          customer={layout?.customer}
        />
        <main role="main" id="mainContent" className="flex-grow">
          {children}
          <Footer />
        </main>
      </div>
    </>
  );
}

function Header(props) {
  const isHome = useIsHomePath();

  const {title, menu, customer} = props;

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      {menu && (
        <MenuDrawer
          isOpen={isMenuOpen}
          onClose={closeMenu}
          menu={menu}
          customer={customer}
        />
      )}
      <DesktopHeader
        isHome={isHome}
        title={title}
        menu={menu}
        openCart={openCart}
        customer={customer}
      />
      <MobileHeader
        isHome={isHome}
        title={title}
        openCart={openCart}
        openMenu={openMenu}
      />
    </>
  );
}

function CartDrawer({isOpen, onClose}) {
  const [root] = useMatches();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      heading="Coșul tău"
      openFrom="right"
    >
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({isOpen, onClose, menu, customer}) {
  const [title, setTitle] = useState('Recenzii');
  const [items, setItems] = useState(menu?.items || []);

  const [previousTitle, setPreviousTitle] = useState('Recenzii');
  const [previousItems, setPreviousItems] = useState(menu?.items || []);

  const close = () => {
    onClose();

    setTimeout(() => {
      setTitle('Recenzii');
      setItems(menu?.items || []);
    }, 1000);
  };

  const handleBack = () => {
    setItems(previousItems);
    setTitle(previousTitle);

    setPreviousItems(menu?.items || []);
    setPreviousTitle('Recenzii');
  };
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      openFrom="left"
      heading={
        title === 'Recenzii' ? (
          title
        ) : (
          <span className="flex items-center">
            <button onClick={handleBack}>
              <BackIcon />
            </button>{' '}
            <span className="ml-3">{title}</span>
          </span>
        )
      }
      background="#5F4156"
      text="light"
    >
      <div
        className="overflow-scroll grid h-full items-start border-t border-white border-opacity-10"
        style={{paddingBottom: '170px'}}
      >
        <MenuMobileNav
          links={items}
          onClose={onClose}
          title={title}
          setTitle={setTitle}
          setItems={setItems}
          setPreviousItems={setPreviousItems}
          setPreviousTitle={setPreviousTitle}
          customer={customer}
        />

        <div
          className="text-center absolute bottom-0 w-full pt-5 pb-10"
          style={{
            color: '#D4E6F466',
            background: 'rgb(95, 65, 86)',
          }}
        >
          <span className="opacity-70">
            ©{new Date().getFullYear()} Editura Univers
            <br />
            Toate drepturile rezervate
          </span>
        </div>
      </div>
    </Drawer>
  );
}

function MenuMobileNav({
  links,
  onClose,
  title,
  setTitle,
  setItems,
  setPreviousItems,
  setPreviousTitle,
  customer = null,
}) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    setTitle(item.title.split('|')[0]);
    setItems(item.items);

    setPreviousTitle(title);
    setPreviousItems(links);
  };
  const handleLogout = () => {
    navigate('/profil/logout');
    onClose();
  };

  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8">
      {(links || []).map((item, index) => {
        let title = item.title.split('|')[0];
        let type = item.title.split('|')[1] || null;
        if (type == 1) {
          return (
            <button
              key={item.id}
              type="button"
              //to={item.url}
              onClick={() => {
                handleClick(item);
              }}
              className="text-2xl text-left py-4 flex justify-between items-center"
              style={{color: '#D4E6F4'}}
            >
              <Text as="span" size="2xl">
                {title}
              </Text>
              <ArrowRightIcon strokeColor="#ffffff" />
            </button>
          );
        }

        if (type == 2) {
          return (
            <>
              <button
                key={item.id}
                type="button"
                //to={item.url}
                onClick={() => {
                  handleClick(item);
                }}
                className={'text-white text-xl text-left flex items-center'}
              >
                {title && title === 'Categorii' ? (
                  <div className="pr-4">
                    <CategoryIcon />
                  </div>
                ) : null}
                {title && title === 'Pentru părinți' ? (
                  <div className="pr-4">
                    <ParentsIcon />
                  </div>
                ) : null}
                {title && title === 'Vârstă' ? (
                  <div className="pr-4">
                    <AgeIcon />
                  </div>
                ) : null}
                {title && title === 'Mai mult' ? (
                  <div className="pr-4">
                    <MoreIcon />
                  </div>
                ) : null}
                {title && title === 'Teme' ? (
                  <div className="pr-4">
                    <ThemeIcon />
                  </div>
                ) : null}
                {title && title === 'Serii' ? (
                  <div className="pr-4">
                    <SeriesIcon />
                  </div>
                ) : null}
                {title && title === 'Liste' ? (
                  <div className="pr-4">
                    <ListIcon />
                  </div>
                ) : null}
                {title && title === 'Autori' ? (
                  <div className="pr-4">
                    <AuthorIcon />
                  </div>
                ) : null}
                {title && title === 'Utilizatori' ? (
                  <div className="pr-4">
                    <UsersIcon />
                  </div>
                ) : null}
                {title && title === 'Despre noi' ? (
                  <div className="pr-4">
                    <AboutUsIcon />
                  </div>
                ) : null}
                {title && title === 'Connect' ? (
                  <div className="pr-4">
                    <ConnectIcon />
                  </div>
                ) : null}
                {title && title === 'Legal' ? (
                  <div className="pr-4">
                    <LegalIcon />
                  </div>
                ) : null}

                {title && title === 'Contul meu' ? (
                  <div className="pr-4">
                    <ProfileIcon fill="" stroke="#ffffff" />
                  </div>
                ) : null}
                <Text as="span" size="xl">
                  {title}
                </Text>
              </button>
              {item.items ? (
                <div className="mb-4 grid gap-4" style={{marginLeft: '40px'}}>
                  {item.items.map((childItem) => {
                    title = childItem.title.split('|')[0];

                    return (
                      <button
                        key={childItem.id}
                        className="text-left"
                        style={{color: '#D4E6F4'}}
                        onClick={() => {
                          navigate(
                            childItem.url
                              .replace(
                                'https://cartipentrucopilulmeu.myshopify.com',
                                '',
                              )
                              .replace('pages', 'pagini')
                              .replace('collections', 'categorii'),
                          );
                          onClose();
                        }}
                      >
                        <Text as="span" size="copy">
                          {title}
                        </Text>
                      </button>
                    );
                  })}
                </div>
              ) : null}
            </>
          );
        }

        return (
          <Link key={item.id} to={item.url} style={{color: '#D4E6F4'}}>
            <Text as="span" size="copy">
              {title}
            </Text>
          </Link>
        );
      })}

      {customer && title === 'Recenzii' ? (
        <>
          <button
            type="button"
            //to={item.url}
            onClick={() => {
              handleClick({
                id: 'account',
                isExternal: false,
                target: '_self',
                type: 'HTTP',
                title: 'Contul meu|1',
                to: '/',
                url: '/',
                items: [
                  {
                    id: 'account',
                    isExternal: false,
                    target: '_self',
                    type: 'HTTP',
                    title: 'Contul meu|2',
                    to: '/',
                    url: '/',
                    items: [
                      {
                        id: 'profil',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Profil',
                        to: '/profil',
                        url: '/profil',
                      },
                      {
                        id: 'raft',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Raft',
                        to: '/profil/raft',
                        url: '/profil/raft',
                      },
                      {
                        id: 'liste',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Liste',
                        to: '/profil/liste',
                        url: '/profil/liste',
                      },
                      {
                        id: 'notificari',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Notificări',
                        to: '/profil/notificari',
                        url: '/profil/notificari',
                      },
                      {
                        id: 'recenzii',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Recenzii',
                        to: '/profil/recenzii',
                        url: '/profil/recenzii',
                      },
                      {
                        id: 'urmaresc',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Urmăresc',
                        to: '/profil/urmaresc',
                        url: '/profil/urmaresc',
                      },
                      {
                        id: 'urmaritori',
                        isExternal: false,
                        target: '_self',
                        type: 'HTTP',
                        title: 'Urmăritori',
                        to: '/profil/urmaritori',
                        url: '/profil/urmaritori',
                      },
                    ],
                  },
                ],
              });
            }}
            className="text-2xl text-left py-4 flex justify-between items-center"
            style={{color: '#D4E6F4'}}
          >
            <Text as="span" size="2xl">
              Contul meu
            </Text>
            <ArrowRightIcon strokeColor="#ffffff" />
          </button>

          <Form method="post" action="/profil/logout" onSubmit={handleLogout}>
            <button
              type="submit"
              className="text-2xl text-left py-4 flex justify-between items-center"
              style={{color: 'rgb(212, 230, 244)'}}
            >
              <span>Logout</span>
            </button>
          </Form>
        </>
      ) : null}

      {!customer && title === 'Recenzii' ? (
        <button
          onClick={() => {
            navigate('/profil/login');
            onClose();
          }}
          className="text-2xl text-left py-4 flex justify-between items-center"
          style={{color: 'rgb(212, 230, 244)'}}
        >
          Login
        </button>
      ) : null}
    </nav>
  );
}

function MobileHeader({title, isHome, openCart, openMenu}) {
  // useHeaderStyleFix(containerStyle, setContainerStyle, isHome);

  const params = useParams();

  return (
    <header
      role="banner"
      className={`${'bg-body text-contrast'} flex lg:hidden items-center sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 py-2 md:px-8`}
    >
      <Link
        className="flex items-center justify-start flex-grow w-full h-full"
        to="/"
      >
        <LogoGirlIcon />
      </Link>
      <div className="flex items-center justify-end w-full gap-4 text-primary">
        <Form
          method="get"
          action={params.lang ? `/${params.lang}/cauta` : '/cauta'}
          className="items-center gap-2 sm:flex"
        >
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8"
          >
            <SearchMobileIcon />
          </button>
        </Form>
        <Link
          to="/profil"
          className="relative flex items-center justify-center px-4"
        >
          <AccountMobileIcon />
        </Link>
        {/* <CartCount isHome={isHome} openCart={openCart} /> */}
        <button
          onClick={openMenu}
          className="relative flex items-center justify-center pr-4"
        >
          <MenuMobileIcon />
        </button>
      </div>
    </header>
  );
}

function DesktopHeader({isHome, menu, openCart, title, customer}) {
  const [term, setTerm] = useState('');
  const [isAccountDropOpen, setIsAccountDropOpen] = useState(false);
  const [isSearchDropOpen, setIsSearchDropOpen] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [openMenu, setOpenMenu] = useState('');

  useEffect(() => {
    return () => {
      setOpenMenu('');
    };
  }, []);

  const handleMenuToggle = (title) => {
    setOpenMenu(title);
  };

  //TODO: replace ssearch with CreateListSearch
  const handleSearch = async (event) => {
    setTerm(event.target.value);
    if (event.target.value.length > 2) {
      //const query = `query {products(first: 5, query: "title:${event.target.value}*") {edges {node {id,title,createdAt,featuredImage{url,width,height}}}}}`;
      const query = `query {products(first: 5, query: "title:${event.target.value}*") {edges {node {id,title,handle,images(first: 1){edges{node{id,height,width,url,altText}}}}}}}`;
      const results = await apiCall(query);
      const products = flattenConnection(results?.data?.products);
      for (let index = 0; index < products.length; index++) {
        products[index].images = flattenConnection(products[index].images);
      }
      setSearchProducts(products);
      setIsSearchDropOpen(true);
    } else {
      setSearchProducts([]);
      setIsSearchDropOpen(false);
    }
  };

  return (
    <header className="hidden h-nav lg:flex items-center sticky transition duration-300 bg-body z-40 top-0 justify-between w-full leading-none gap-8 px-12 py-5">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between w-full">
          <div>
            <a href="/" className="flex items-center">
              <span className="mr-2">
                <LogoGirlIcon />
              </span>
              <span className="leading-5 font-serif hidden">
                CĂRȚI PENTRU
                <br />
                COPILUL MEU
              </span>
            </a>
          </div>
          <div className="text-xl font-serif justify-center grow hidden lg:flex">
            {(menu?.items || []).map((item) => {
              return (
                <button
                  key={item.id}
                  className="text-primary px-3 flex items-center"
                  onClick={() => {
                    handleMenuToggle(item?.title);
                  }}
                >
                  <span className="mr-2">{item.title.split('|')[0]}</span>
                  <ArrowDownIcon />
                </button>
              );
            })}

            {/*****NAVIGATION******/}
            <div>
              {menu && menu.items.length > 0 ? (
                <div>
                  <div>
                    {(menu?.items || []).map((link, i) => {
                      if (i === 0) {
                        return (
                          <div
                            key={link.id}
                            className={
                              link?.title === openMenu
                                ? 'absolute translate-y-0 transition-transform duration-200 left-0 right-0 top-0 bg-menu z-50 text-white'
                                : 'absolute -translate-y-full transition-transform duration-200 left-0 right-0 top-0 bg-menu z-50 text-white'
                            }
                          >
                            <div className="flex justify-between py-5 px-8 mb-8">
                              <span className="text-2xl uppercase">Cărți</span>
                              <button
                                onClick={() => {
                                  setOpenMenu('');
                                }}
                                className="text-white border border-menuLink rounded-lg w-10 h-10 flex justify-center items-center"
                              >
                                <CloseIcon stroke="#D4E6F4" />
                              </button>
                            </div>
                            <div className="pb-14 px-8 flex">
                              <div className="pr-6 flex-grow">
                                <div className="flex">
                                  <div className="w-12">
                                    <CategoryIcon />
                                  </div>
                                  <div>
                                    <div className="text-sm uppercase font-bold mb-4">
                                      {link.items[0]?.title.split('|')[0]}
                                    </div>
                                    <div>
                                      <ul className="mb-10">
                                        {link.items[0].items &&
                                          link.items[0].items.length > 0 &&
                                          link.items[0].items.map(
                                            (grandChildLink) => {
                                              return (
                                                <li
                                                  key={grandChildLink.id}
                                                  className="mb-1"
                                                >
                                                  <a
                                                    href={grandChildLink.url
                                                      .replace(
                                                        'https://cartipentrucopilulmeu.myshopify.com',
                                                        '',
                                                      )
                                                      .replace(
                                                        'pages',
                                                        'pagini',
                                                      )
                                                      .replace(
                                                        'collections',
                                                        'categorii',
                                                      )}
                                                    className="text-menuLink leading-8 flex"
                                                  >
                                                    {grandChildLink?.title}
                                                  </a>
                                                </li>
                                              );
                                            },
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pr-6 flex-grow">
                                <div className="flex">
                                  {link.items[1] ? (
                                    <div className="flex">
                                      <div className="w-12">
                                        <ParentsIcon />
                                      </div>
                                      <div>
                                        <div className="text-sm uppercase font-bold mb-4">
                                          {link.items[1]?.title.split('|')[0]}
                                        </div>
                                        <div>
                                          <ul className="mb-10">
                                            {link.items[1]?.items &&
                                              link.items[1]?.items.length > 0 &&
                                              link.items[1]?.items.map(
                                                (grandChildLink) => {
                                                  return (
                                                    <li
                                                      key={grandChildLink.id}
                                                      className="mb-1"
                                                    >
                                                      <a
                                                        href={grandChildLink.url
                                                          .replace(
                                                            'https://cartipentrucopilulmeu.myshopify.com',
                                                            '',
                                                          )
                                                          .replace(
                                                            'collections',
                                                            'categorii',
                                                          )}
                                                        className="text-menuLink leading-8 flex"
                                                      >
                                                        {grandChildLink?.title}
                                                      </a>
                                                    </li>
                                                  );
                                                },
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="flex">
                                  {link.items[2] ? (
                                    <div className="flex">
                                      <div className="w-12">
                                        <AgeIcon />
                                      </div>
                                      <div>
                                        <div className="text-sm uppercase font-bold mb-4">
                                          {link.items[2]?.title.split('|')[0]}
                                        </div>
                                        <div>
                                          <ul className="mb-10">
                                            {link.items[2]?.items &&
                                              link.items[2]?.items.length > 0 &&
                                              link.items[2]?.items.map(
                                                (grandChildLink) => {
                                                  return (
                                                    <li
                                                      key={grandChildLink.id}
                                                      className="mb-1"
                                                    >
                                                      <a
                                                        href={grandChildLink.url
                                                          .replace(
                                                            'https://cartipentrucopilulmeu.myshopify.com',
                                                            '',
                                                          )
                                                          .replace(
                                                            'collections',
                                                            'categorii',
                                                          )}
                                                        className="text-menuLink leading-8 flex"
                                                      >
                                                        {grandChildLink?.title}
                                                      </a>
                                                    </li>
                                                  );
                                                },
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="flex">
                                  {link.items[3] ? (
                                    <div className="flex">
                                      <div className="w-12">
                                        <MoreIcon />
                                      </div>
                                      <div>
                                        <div className="text-sm uppercase font-bold mb-4">
                                          {link.items[3]?.title.split('|')[0]}
                                        </div>
                                        <div>
                                          <ul>
                                            {link.items[3]?.items &&
                                              link.items[3]?.items.length > 0 &&
                                              link.items[3]?.items.map(
                                                (grandChildLink) => {
                                                  return (
                                                    <li
                                                      key={grandChildLink.id}
                                                      className="mb-1"
                                                    >
                                                      <a
                                                        href={grandChildLink.url
                                                          .replace(
                                                            'https://cartipentrucopilulmeu.myshopify.com',
                                                            '',
                                                          )
                                                          .replace(
                                                            'collections',
                                                            'categorii',
                                                          )}
                                                        className="text-menuLink leading-8 flex"
                                                      >
                                                        {grandChildLink?.title}
                                                      </a>
                                                    </li>
                                                  );
                                                },
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="pr-6 flex-grow">
                                <div className="flex">
                                  {link.items[4] ? (
                                    <div className="flex">
                                      <div className="w-12">
                                        <ThemeIcon />
                                      </div>
                                      <div>
                                        <div className="text-sm uppercase font-bold mb-4">
                                          {link.items[4]?.title.split('|')[0]}
                                        </div>
                                        <div>
                                          <ul>
                                            {link.items[4]?.items &&
                                              link.items[4]?.items.length > 0 &&
                                              link.items[4]?.items.map(
                                                (grandChildLink) => {
                                                  return (
                                                    <li
                                                      key={grandChildLink.id}
                                                      className="mb-1"
                                                    >
                                                      <a
                                                        href={grandChildLink.url
                                                          .replace(
                                                            'https://cartipentrucopilulmeu.myshopify.com',
                                                            '',
                                                          )
                                                          .replace(
                                                            'collections',
                                                            'categorii',
                                                          )}
                                                        className="text-menuLink leading-8 flex"
                                                      >
                                                        {grandChildLink?.title.replace(
                                                          'Tema ',
                                                          '',
                                                        )}
                                                      </a>
                                                    </li>
                                                  );
                                                },
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="pr-6 flex-grow">
                                <div className="flex">
                                  {link.items[5]?.title.split('|')[0] ===
                                  'NONAME' ? null : (
                                    <div className="w-12">
                                      <SeriesIcon />
                                    </div>
                                  )}
                                  <div>
                                    {link.items[5]?.title.split('|')[0] ===
                                    'NONAME' ? null : (
                                      <div className="text-sm uppercase font-bold mb-4">
                                        {link.items[5]?.title.split('|')[0]}
                                      </div>
                                    )}
                                    <div>
                                      <ul>
                                        {link.items[5]?.items &&
                                          link.items[5]?.items.length > 0 &&
                                          link.items[5]?.items.map(
                                            (grandChildLink) => {
                                              return (
                                                <li
                                                  key={grandChildLink.id}
                                                  className="mb-1"
                                                >
                                                  <a
                                                    href={grandChildLink.url
                                                      .replace(
                                                        'https://cartipentrucopilulmeu.myshopify.com',
                                                        '',
                                                      )
                                                      .replace(
                                                        'collections',
                                                        'categorii',
                                                      )}
                                                    className="text-menuLink leading-8 flex"
                                                  >
                                                    {grandChildLink.title.replace(
                                                      'Serie ',
                                                      '',
                                                    )}
                                                  </a>
                                                </li>
                                              );
                                            },
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      return (
                        <div
                          key={link.id}
                          className={
                            link.title === openMenu
                              ? 'absolute translate-y-0 transition-transform duration-200 left-0 right-0 top-0 bg-menu z-50 text-white'
                              : 'absolute -translate-y-full transition-transform duration-200 left-0 right-0 top-0 bg-menu z-50 text-white'
                          }
                        >
                          <div className="flex justify-between py-5 px-8 mb-8">
                            <span className="text-2xl uppercase">
                              {i == 1 ? 'Comunitate' : 'Utile'}
                            </span>
                            <button
                              onClick={() => {
                                setOpenMenu('');
                              }}
                              className="text-white border border-menuLink rounded-lg w-10 h-10 flex justify-center items-center"
                            >
                              <CloseIcon stroke="#D4E6F4" />
                            </button>
                          </div>
                          <div className="px-8 pb-14 flex">
                            {link.items.map((childLink, index) => {
                              return (
                                <div key={childLink.id} className="flex-grow">
                                  <div className="flex">
                                    <div className="w-12">
                                      {childLink.title === 'Liste' ? (
                                        <ListIcon />
                                      ) : null}
                                      {childLink.title === 'Autori' ? (
                                        <AuthorIcon />
                                      ) : null}
                                      {childLink.title === 'Utilizatori' ? (
                                        <UsersIcon />
                                      ) : null}
                                      {childLink.title === 'Mai mult' ? (
                                        <VideoIcon />
                                      ) : null}
                                      {childLink.title === 'Despre noi' ? (
                                        <AboutUsIcon />
                                      ) : null}
                                      {childLink.title === 'Connect' ? (
                                        <ConnectIcon />
                                      ) : null}
                                      {childLink.title === 'Legal' ? (
                                        <LegalIcon />
                                      ) : null}
                                    </div>
                                    <div>
                                      <div className="text-sm uppercase font-bold mb-4">
                                        {childLink.title.split('|')[0]}
                                      </div>
                                      <div>
                                        <ul>
                                          {childLink.items &&
                                            childLink.items.length > 0 &&
                                            childLink.items.map(
                                              (grandChildLink) => {
                                                return (
                                                  <li
                                                    key={grandChildLink.id}
                                                    className="mb-1"
                                                  >
                                                    <a
                                                      href={grandChildLink.url
                                                        .replace(
                                                          'https://cartipentrucopilulmeu.myshopify.com',
                                                          '',
                                                        )
                                                        .replace(
                                                          'pages',
                                                          'pagini',
                                                        )
                                                        .replace(
                                                          'collections',
                                                          'categorii',
                                                        )}
                                                      className="text-menuLink leading-8 flex"
                                                    >
                                                      {grandChildLink.title}
                                                    </a>
                                                  </li>
                                                );
                                              },
                                            )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {openMenu !== '' ? (
              <button
                className="hidden lg:block fixed top-0 bottom-0 bg-transparent right-0 left-0 z-40"
                onClick={() => {
                  setOpenMenu('');
                }}
              ></button>
            ) : null}
            {/*****END NAVIGATION******/}
          </div>

          <div className="flex-grow  hidden lg:flex">
            <div className="flex-grow  hidden lg:flex">
              <div className="w-full relative">
                <SearchForm
                  handleSearch={handleSearch}
                  term={term}
                  setIsSearchDropOpen={setIsSearchDropOpen}
                />
                {isSearchDropOpen && searchProducts.length > 0 ? (
                  <>
                    <div
                      className="absolute w-full top-full mt-2 bg-white rounded-lg z-40"
                      style={{
                        boxShadow: '10px 0px 24px 0px rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      <div className="uppercase text-xs font-serif mb-3 px-4 mt-4">
                        Cărți
                      </div>

                      {searchProducts.map((item) => {
                        return (
                          <SearchCard
                            key={item.id}
                            setIsSearchDropOpen={setIsSearchDropOpen}
                            setTerm={setTerm}
                            item={item}
                          />
                        );
                      })}
                      {searchProducts.length === 5 ? (
                        <div className="text-center w-full">
                          <a
                            href={`/cauta?q=${term}`}
                            className="text-primary font-serif border-t border-line px-4 py-3 w-full flex items-center justify-center"
                          >
                            Mai multe rezultate
                          </a>
                        </div>
                      ) : null}
                    </div>

                    <button
                      className="fixed top-0 left-0 right-0 bottom-0 flex z-30"
                      onClick={() => {
                        setIsSearchDropOpen(false);
                      }}
                    ></button>
                  </>
                ) : null}
              </div>
            </div>

            <div className="justify-end flex items-center">
              <a
                href="/profil/raft"
                className="h-10 px-4 rounded-lg bg-primary text-white lg:text-lg font-serif ml-2 flex items-center"
              >
                <HeartIcon />
                <span className="ml-3">Raftul meu</span>
              </a>
              <a
                href="/profil/notificari"
                className="text-primary px-5 flex items-center justify-center relative"
              >
                <span>
                  <NotificationIcon />
                  {customer ? (
                    <span
                      className="bg-primary rounded-full flex absolute right-3 -top-2"
                      style={{width: '6px', height: '6px'}}
                    ></span>
                  ) : null}
                </span>
              </a>
              {!customer ? (
                <a
                  href="/profil/login"
                  className="h-10 px-4 flex items-center justify-center rounded-lg bg-secondary text-onSecondary text-lg font-serif mr-4"
                  style={{minWidth: '90px'}}
                >
                  Login
                </a>
              ) : (
                <>
                  <div className="relative">
                    <button
                      className="flex items-center relative mr-4"
                      onClick={() => {
                        setIsAccountDropOpen(!isAccountDropOpen);
                      }}
                    >
                      <UserAvatar
                        size={40}
                        customer={customer}
                        background="#ffffff"
                      />
                      <ArrowDownIcon />
                    </button>
                    {isAccountDropOpen ? (
                      <div
                        className="absolute top-full mt-3 right-0 bg-white z-50 rounded-lg w-60 font-serif"
                        style={{
                          boxShadow: '36px 0px 18px -18px rgba(0, 0, 0, 0.15)',
                        }}
                      >
                        <ul>
                          <li className="mt-2">
                            <a
                              href="/profil"
                              className="pr-5 pl-10 relative py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <ProfileIcon />
                              </span>
                              <span className="ml-3">Profilul meu</span>
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href="/profil/comenzi"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <BagIcon size={16} />
                              </span>
                              <span className="ml-3">Comenzile mele</span>
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="/profil/raft"
                              className="pr-5 pl-10 relative py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <HeartIcon />
                              </span>
                              <span className="ml-3">Raftul meu</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/profil/liste"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <ListsIcon />
                              </span>
                              <span className="ml-3">Listele mele</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/profil/notificari"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <NotificationIcon size={16} />
                              </span>
                              <span className="ml-3">Notificări</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/profil/recenzii"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <FileIcon size={16} />
                              </span>
                              <span className="ml-3">Recenziile mele</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/profil/urmaresc"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <FollowingIcon />
                              </span>
                              <span className="ml-3">Urmăresc</span>
                            </a>
                          </li>
                          <li className="mb-2">
                            <a
                              href="/profil/urmaritori"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <FollowersIcon width={23} height={18} />
                              </span>
                              <span className="ml-3">Urmăritori</span>
                            </a>
                          </li>
                          {/* <li className="border-t border-line pt-2">
                            <a
                              href="/profil/setari"
                              className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                            >
                              <span className="absolute left-5">
                                <SettingsIcon />
                              </span>
                              <span className="ml-3">Setări cont</span>
                            </a>
                          </li> */}
                          <li className="border-t border-line pt-2 mb-2">
                            <Form method="post" action="/profil/logout">
                              <button
                                type="submit"
                                className="pr-5 pl-10 py-2 flex items-center text-primary text-lg"
                              >
                                <span className="absolute left-5">
                                  <LogoutIcon />
                                </span>
                                <span className="ml-3">Logout</span>
                              </button>
                            </Form>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </>
              )}
              {/* <CartCount isHome={isHome} openCart={openCart} /> */}
            </div>
            {isAccountDropOpen ? (
              <button
                className="fixed top-0 bottom-0 left-0 right-0 h-full w-full block z-30"
                onClick={() => {
                  setIsAccountDropOpen(!isAccountDropOpen);
                }}
              ></button>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
}

function CartCount({isHome, openCart}) {
  const [root] = useMatches();

  return (
    <Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({openCart, dark, count}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <BagIcon width={24} height={25} />
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center text-primary w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center text-primary w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

function Footer() {
  return (
    <footer role="contentinfo">
      <div className="relative font-serif text-center pb-14 lg:pb-28">
        <div className="text-xl mb-5 mx-auto max-w-4xl flex items-center justify-center">
          <a
            href="https://www.facebook.com/cartipentrucopilulmeu"
            target="_blank"
            rel="noreferrer nofollow"
            className="px-4 py-2 inline-flex text-primary"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/cartipentrucopilulmeu/"
            target="_blank"
            rel="noreferrer nofollow"
            className="px-4 py-2 inline-flex text-primary"
          >
            <InstagramIcon />
          </a>

          <a
            href="https://www.youtube.com/channel/UCGB0WdMGvHBHsCoAR6bPdtQ"
            target="_blank"
            rel="noreferrer nofollow"
            className="px-4 py-2 inline-flex text-primary"
          >
            <YoutubeIcon />
          </a>
        </div>
        <div className="text-xl mb-5 mx-auto max-w-4xl">
          <a href="/" className="px-4 py-2 inline-flex text-primary">
            Cărți
          </a>
          <a
            href="/pagini/despre-noi"
            className="px-4 py-2 inline-flex text-primary"
          >
            Despre noi
          </a>
          <a
            href="/pagini/colaboratori"
            className="px-4 py-2 inline-flex text-primary"
          >
            Colaboratori
          </a>
          <a
            href="/pagini/termeni-si-conditii"
            className="px-4 py-2 inline-flex text-primary"
          >
            Termeni și Condiții
          </a>
          <a
            href="/pagini/confidentialitate"
            className="px-4 py-2 inline-flex text-primary"
          >
            Confidențialitate
          </a>
        </div>
        <p className="text-sm px-4">
          © {new Date().getFullYear()} Editura Univers, toate drepturile
          rezervate. Version 4.7
        </p>
      </div>
    </footer>
  );
}
