/**
 * A shared component that specifies the icon to render when opened
 */
export function SearchMobileIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 17L24 24" stroke="#E75113" strokeWidth="1.2" />
      <circle cx="10" cy="10" r="9.4" stroke="#E75113" strokeWidth="1.2" />
    </svg>
  );
}
