export function ArrowRightIcon({strokeColor = '#E75113'}) {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 9L4.5 5L1 1" stroke={strokeColor} strokeWidth="1.5" />
    </svg>
  );
}
