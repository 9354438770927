import {Dialog} from '@headlessui/react';
import {CloseIcon, NotaTa} from '~/components';

export function SeeReview({review, open, setOpen, title, customer}) {
  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle">&#8203;</span>
          <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between pt-4 pb-3 px-5"
            >
              {review.title || 'Recenzia ta'}
              <button
                className="w-8 h-8 flex items-center justify-center"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </button>
            </Dialog.Title>
            <div className="py-4 px-5 font-serif bg-secondary text-onSecondary">
              <h3 className="text-xl text-center">
                Cum ți s-a părut <em>&ldquo;{title}&ldquo;?</em>
              </h3>
            </div>

            <div className="py-4 px-5 uppercase font-serif text-xs flex items-center">
              <span className="text-black">Nota ta </span>
              <span className="ml-2">
                <NotaTa
                  nota={parseInt(review?.stars) || 0}
                  customer={customer}
                />
              </span>
            </div>

            <div className="pb-4 px-5 font-serif text-black">
              {review.review}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
