export function ParentsIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 0H20C19.45 0 19 0.45 19 1C19 1.55 19.45 2 20 2H21C21.55 2 22 2.45 22 3V11.025C21.15 10.375 20.075 10 19 10C17.325 10 15.775 10.825 14.85 12.2C13.325 10.625 10.8 10.575 9.225 12.125C9.2 12.15 9.175 12.175 9.15 12.2C8.225 10.825 6.675 10 5 10C3.925 10 2.85 10.375 2 11.025V3C2 2.45 2.45 2 3 2H4C4.55 2 5 1.55 5 1C5 0.45 4.55 0 4 0H3C1.35 0 0 1.35 0 3V15C0 17.75 2.25 20 5 20C7.75 20 10 17.75 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 17.75 16.25 20 19 20C21.75 20 24 17.75 24 15V3C24 1.35 22.65 0 21 0ZM5 18C3.35 18 2 16.65 2 15C2 13.35 3.35 12 5 12C6.65 12 8 13.35 8 15C8 16.65 6.65 18 5 18ZM19 18C17.35 18 16 16.65 16 15C16 13.35 17.35 12 19 12C20.65 12 22 13.35 22 15C22 16.65 20.65 18 19 18Z"
        fill="white"
      />
      <path
        d="M5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16Z"
        fill="#FBD7CF"
      />
      <path
        d="M19 16C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14C18.4477 14 18 14.4477 18 15C18 15.5523 18.4477 16 19 16Z"
        fill="#FBD7CF"
      />
    </svg>
  );
}
