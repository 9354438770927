import {useEffect, useState} from 'react';
import {Transition} from '@headlessui/react';
import {CloseIcon, Button} from '~/components';

export function Toast({toast, removeToasts}) {
  const [isShowing, setIsShowing] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowing(false);
      removeToasts([{id: toast.id}]);
    }, toast.duration || 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleRemove = () => {
    setIsShowing(false);
    removeToasts([{id: toast.id}]);
  };

  return (
    <div>
      <Transition key={toast.id} show={isShowing}>
        <div
          className={
            toast.type === 'success'
              ? `flex items-center justify-between py-4 px-4 mb-3 bg-success overflow-hidden rounded-xl shadow-md`
              : toast.type === 'warning'
              ? `flex items-center justify-between py-4 px-4 mb-3 bg-warning overflow-hidden rounded-xl shadow-md`
              : toast.type === 'error'
              ? `flex items-center justify-between py-4 px-4 mb-3 bg-error overflow-hidden rounded-xl shadow-md`
              : toast.type === 'info'
              ? `flex items-center justify-between py-4 px-4 mb-3 bg-info overflow-hidden rounded-xl shadow-md`
              : `flex items-center justify-between py-4 px-4 mb-3 bg-white overflow-hidden rounded-xl shadow-md`
          }
        >
          {toast.icon ? (
            <div
              className="flex justify-center mr-2"
              style={{flex: '0 0 50px'}}
            >
              {toast.icon}
            </div>
          ) : null}
          <div className="flex-grow text-black text-left text-opacity-80">
            {toast.message}
          </div>
          <div>
            <Button
              variant="naked"
              handleClick={handleRemove}
              label={<CloseIcon />}
              className="text-black text-opacity-80"
            />
          </div>
        </div>
      </Transition>
    </div>
  );
}
