export function ListIcon({fill = 'white', size = 24}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 24H22C23.1 24 24 23.1 24 22V10C24 8.9 23.1 8 22 8V6C22 4.9 21.1 4 20 4H19.95L19.975 2.025C20 0.925 19.125 0.025 18.025 0H18H6C4.9 0 4 0.9 4 2V4C2.9 4 2 4.9 2 6V8C0.9 8 0 8.9 0 10V22C0 23.1 0.9 24 2 24ZM6 2H17.975L17.95 3.95H6V2ZM4 6H20V8H4V6ZM2 10H22V22H2V10Z"
        fill={fill}
      />
    </svg>
  );
}
