export function FollowingIcon() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7001 4.5251C3.7001 6.39546 4.53734 7.60803 5.64993 8.08638C6.12448 8.29041 6.67572 8.29041 7.15026 8.08638C8.26285 7.60803 9.1001 6.39546 9.1001 4.5251C9.1001 3.8308 8.89937 3.23679 8.49698 2.81869C8.09914 2.40531 7.44275 2.1001 6.4001 2.1001C5.48463 2.1001 4.81658 2.39798 4.37943 2.8283C3.94011 3.26075 3.7001 3.85981 3.7001 4.5251Z"
        stroke="#E75113"
      />
      <path
        d="M11.9715 12.7237C11.6815 11.2014 10.3506 10.1001 8.80102 10.1001L3.99859 10.1001C2.44897 10.1001 1.11807 11.2014 0.828113 12.7237C0.711794 13.3344 1.17995 13.9001 1.8016 13.9001H10.998C11.6197 13.9001 12.0878 13.3344 11.9715 12.7237Z"
        stroke="#E75113"
      />
      <path
        d="M8.5 3.575C8.5 6.00048 9.66499 7.56692 11.2283 8.11235C11.7254 8.28576 12.2746 8.28576 12.7717 8.11235C14.335 7.56692 15.5 6.00048 15.5 3.575C15.5 2.70874 15.2439 1.95368 14.7151 1.41637C14.1893 0.882214 13.3316 0.5 12 0.5C10.8241 0.5 9.95471 0.875068 9.38245 1.42587C8.80888 1.97793 8.5 2.73829 8.5 3.575Z"
        fill="white"
        stroke="#E75113"
      />
      <path
        d="M19.0926 13.7628C18.7141 11.6434 16.8708 10.1001 14.7179 10.1001L9.28212 10.1001C7.12917 10.1001 5.28589 11.6434 4.90743 13.7628C4.7457 14.6684 5.44201 15.5001 6.36198 15.5001L17.638 15.5001C18.558 15.5001 19.2543 14.6684 19.0926 13.7628Z"
        fill="white"
        stroke="#E75113"
      />
    </svg>
  );
}
