export function AnswerIcon() {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6499 1L1.6499 6L6.6499 11"
        stroke="#E75113"
        strokeWidth="1.5"
      />
      <path
        d="M1.77991 6C3.44657 6 7.57991 6 10.7799 6C13.9799 6 14.7799 8.66667 14.7799 10V13"
        stroke="#E75113"
        strokeWidth="1.5"
      />
    </svg>
  );
}
