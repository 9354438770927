import {Link} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';
import {
  BooksInShelveIcon,
  Button,
  CollectionActions,
  CollectionMetrics,
  CommentsIcon,
  LikeIcon,
} from '~/components';

export function AuthorCard({author}) {
  return (
    <div key={author.id} className="mb-4">
      <div
        className="flex items-center justify-center rounded-2xl overflow-hidden object-cover"
        style={{height: '400px'}}
      >
        <Link to={`/categorii/${author.handle}`}>
          <Image data={author.image} />
        </Link>
      </div>
      <div className="text-xl text-center py-4">{author.title}</div>
      <div className="border-y border-line">
        <CollectionMetrics
          followers={author.followers}
          likes={author.likes}
          margin={0}
          padding={3}
        />
      </div>
      {/* <CollectionActions
        handle={author.handle}
        collection={{
          ...author,
          following: author.following,
        }}
        hideLike={true}
      /> */}
      <Button
        url={`/categorii/${author.handle}`}
        variant="sm_primary"
        label="Pagina autorului"
      />
    </div>
  );
}
