import {useState} from 'react';
import {ProductDescriptionModal} from '~/components';

export function ProductDescription({content}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        className="text-primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <span className="text-black">...</span> (continuă)
      </button>
      <div>
        <ProductDescriptionModal
          description={content}
          open={open}
          setOpen={setOpen}
          title="Descriere"
        />
      </div>
    </div>
  );
}
