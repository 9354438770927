export function ShowPublicCollections({productCollections, showLink}) {
  let trueIndex = 0;
  return productCollections.map((collection, index) => {
    if (
      collection.title.includes('Varsta') ||
      collection.title.includes('Autor') ||
      collection.title.includes('Slide') ||
      collection.title === 'Carti'
    )
      return null;

    trueIndex++;

    if (trueIndex === 1) {
      if (showLink) {
        return (
          <a key={collection.handle} href={`/categorii/${collection.handle}`}>
            {collection.title}
          </a>
        );
      }
      return `${collection.title}`;
    }

    if (showLink) {
      return (
        <span key={collection.handle}>
          , <a href={`/categorii/${collection.handle}`}>{collection.title}</a>
        </span>
      );
    }
    return `, ${collection.title}`;
  });
}
