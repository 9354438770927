export function CreateListIcon() {
  return (
    <svg
      width="50"
      height="55"
      viewBox="0 0 50 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.322 55H50V0H15.2542V7.41304H4.60048V12.6739H0V55H34.322ZM34.6247 53.8043V12.6739H8.83777V8.6087H42.2518V53.8043H34.6247ZM21.1259 13.8696H28.6925V33.2989L24.8789 29.712L21.0654 33.2989V13.8696H21.1259ZM48.7893 53.8043H43.4625V7.41304H21.1259V1.19565H48.7893V53.8043ZM16.4649 1.19565H19.9758V7.41304H16.4649V1.19565ZM5.81114 8.6087H7.62712V12.6739H5.81114V8.6087ZM1.21065 13.8696H3.20823V53.8043H1.21065V13.8696ZM4.41889 53.8043V13.8696H19.9758V36.1087L25 31.3261L30.0242 36.1087V13.8696H33.4746V53.8043H4.41889Z"
        fill="black"
      />
    </svg>
  );
}
