import {useState, useEffect} from 'react';
import {Dialog} from '@headlessui/react';
import {
  CloseIcon,
  Button,
  SpinnerIcon,
  ReviewStarSelection,
} from '~/components';

export function AddReview({
  handle,
  id,
  customer,
  title,
  author,
  image,
  open,
  setOpen,
  addToasts,
  stars,
  setStars,
  addReview,
  error,
}) {
  const [selectedStars, setSelectedStars] = useState(null);
  const [hoveredStars, setHoveredStars] = useState(null);
  const [focusedStar, setFocusedStar] = useState(null);
  const [reviewTitle, setReviewTitle] = useState('');
  const [review, setReview] = useState('');
  const [shelves, setShelves] = useState([
    {id: 'carti-cumparate-citite', title: 'Cărți cumpărate, citite'},
    {id: 'carti-cumparate-necitite', title: 'Cărți cumpărate, necitite'},
    {id: 'wishlist', title: 'Wishlist'},
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedShelve, setSelectedShelve] = useState('');

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (stars) {
      setSelectedStars(stars);
    }
  }, [stars]);

  const handleReviewTitleChange = (event) => {
    if (event.target.value.length <= 50) {
      setReviewTitle(event.target.value);
    }
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const handleHoveredStars = (star) => {
    setHoveredStars(star);
  };
  const handleSelectedStars = (star) => {
    setSelectedStars(star);
    if (setStars) {
      setStars(star);
    }
  };
  const handleFocusedStar = (star) => {
    setFocusedStar(star);
  };
  const handleSelectedShelveChange = (event) => {
    setSelectedShelve(event.target.value);
  };
  const handleReviewSubmit = async () => {
    setIsLoading(true);

    addReview({
      review,
      title: reviewTitle,
      stars: selectedStars,
      product_handle: handle,
      product_id: id,
      product_title: title,
      product_image_src: image.url,
      product_image_width: image.width,
      product_image_height: image.height,
      product_image_alt: image.alt,
      product_author: author,
      shelve: selectedShelve,
    });
  };

  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between pt-4 pb-3 px-5"
            >
              Scrie o recenzie
              <button
                className="w-8 h-8 flex items-center justify-center"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </button>
            </Dialog.Title>
            <div className="py-4 px-5 font-serif bg-secondary text-onSecondary">
              <h3 className="text-xl text-center">
                Cum ți s-a părut <em>&ldquo;{title}&ldquo;?</em>
              </h3>
            </div>
            <div className="py-4 px-5 uppercase font-serif text-xs">
              Nota ta
            </div>
            <div className="pb-4 px-5 font-serif flex flex-col items-center justify-center">
              <ReviewStarSelection
                handleSelectedStars={handleSelectedStars}
                handleFocusedStar={handleFocusedStar}
                handleHoveredStars={handleHoveredStars}
                hoveredStars={hoveredStars}
                selectedStars={selectedStars}
                focusedStar={focusedStar}
              />
              <div className="text-xl mt-4">
                {selectedStars || 0}/5{' '}
                {selectedStars === 1 ? '- Nu a fost pe gustul meu' : null}
                {selectedStars === 2 ? '- A mers, dar nu am recitit-o' : null}
                {selectedStars === 3 ? '- Nu a fost rea deloc' : null}
                {selectedStars === 4 ? '- Mi-a plăcut!' : null}
                {selectedStars === 5 ? '- Excelentă. O recitim mereu' : null}
              </div>
            </div>
            <div className="pb-4 px-5 font-serif flex flex-col items-start justify-center">
              <div className="mt-4 w-full">
                <div className="text-left flex flex-col w-full">
                  <div className="flex justify-between text-xs font-serif mb-2">
                    <span className="uppercase">Titlul recenziei</span>
                    <span className="opacity-50">
                      {50 - reviewTitle.length} caractere rămase
                    </span>
                  </div>

                  <input
                    className="px-4 h-10 border border-100 border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-300 focus:border-gray-300"
                    type="text"
                    placeholder="Titlu"
                    value={reviewTitle}
                    onChange={handleReviewTitleChange}
                  />
                  {error?.field === 'title' && error?.message ? (
                    <p className="text-primary mt-1">{error.message}</p>
                  ) : null}
                  <div className="flex justify-between text-xs font-serif mb-2 mt-5">
                    <span className="uppercase">Recenzia ta</span>
                    <span className="opacity-50">opțional</span>
                  </div>
                  <textarea
                    cols="30"
                    rows="6"
                    value={review}
                    className="px-4 py-2 border border-100 border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-300 focus:border-gray-300"
                    placeholder="Scrie aici"
                    onChange={handleReviewChange}
                  ></textarea>

                  <div className="flex justify-between text-xs font-serif mb-2 mt-5">
                    <span className="uppercase">Adaugă în raftul tău</span>
                    <span className="opacity-50">opțional</span>
                  </div>
                  <div>
                    <select
                      onChange={handleSelectedShelveChange}
                      className="border border-100 border-gray-300 rounded-lg px-4 h-10 w-full focus:ring-2 focus:ring-gray-300 focus:border-gray-300"
                      value={selectedShelve || ''}
                    >
                      <option value="" defaultValue={selectedShelve === ''}>
                        Alege
                      </option>
                      {shelves.map((shelve) => {
                        return (
                          <option
                            key={shelve.id}
                            value={shelve.id}
                            //selected={shelve === selectedShelve.id}
                          >
                            {shelve.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <Button
                    label={isLoading ? <SpinnerIcon size="34" /> : 'Salvează'}
                    variant="sm_primary"
                    disabled={!selectedStars || !title || isLoading}
                    handleClick={handleReviewSubmit}
                    className="mt-3 flex justify-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
