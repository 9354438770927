export function UsersIcon({fill = 'white'}) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7 10H19C20.65 10 22 8.65 22 7V4C22 3.45 21.55 3 21 3H18V1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1V3H1C0.45 3 0 3.45 0 4V7C0 8.65 1.35 10 3 10H4.3C5.075 12.6 7.3 14.525 10 14.925V18H8C6.35 18 5 19.35 5 21V23C5 23.55 5.45 24 6 24H16C16.55 24 17 23.55 17 23V21C17 19.35 15.65 18 14 18H12V14.925C14.7 14.525 16.925 12.6 17.7 10ZM14 20C14.55 20 15 20.45 15 21V22H7V21C7 20.45 7.45 20 8 20H14ZM20 5V7C20 7.55 19.55 8 19 8H18V5H20ZM3 8C2.45 8 2 7.55 2 7V5H4V8H3ZM6 8V2H16V8C16 10.75 13.75 13 11 13C8.25 13 6 10.75 6 8Z"
        fill={fill}
      />
    </svg>
  );
}
