export function WarningIcon({stroke}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        style={{stroke: stroke || '#E75113'}}
      />
      <rect
        x="11.25"
        y="5"
        width="1.5"
        height="10"
        style={{fill: stroke || '#E75113'}}
      />
      <circle cx="12" cy="18" r="1" style={{fill: stroke || '#E75113'}} />
    </svg>
  );
}
