import {CollectionSwimlane} from './CollectionSwimlane';

export function CollectionsList({
  label,
  title,
  subtitle,
  collections,
  className,
}) {
  return (
    <div className={className ? className : `pt-10 pb-10 xl:pt-20 xl:pb-20`}>
      <div className="container mx-auto px-4 pr-0 md:pr-4 md:px-4">
        <div className="text-primary uppercase mb-3 font-sans tracking-wider text-lg lg:text-2xl">
          {label}
        </div>
        <div
          className={
            subtitle
              ? 'text-black font-sans font-bold text-2xl lg:text-3xl mb-3'
              : 'text-black font-sans font-bold mb-6 text-2xl lg:text-3xl lg:mb-10'
          }
        >
          {title}
        </div>
        {subtitle ? (
          <div className="text-black font-serif mb-6 text-lg lg:mb-8">
            {subtitle}
          </div>
        ) : null}

        <CollectionSwimlane
          collections={collections}
          count={collections.length}
        />
      </div>
    </div>
  );
}
