/**
 * A shared component that specifies the icon to render when opened
 */
export function SearchIcon({size = 15}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 10L14 14" stroke="#E75113" strokeWidth="1.5" />
      <circle cx="6" cy="6" r="5.25" stroke="#E75113" strokeWidth="1.5" />
    </svg>
  );
}
