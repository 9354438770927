export function ThemeIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6251 2H23.0001C23.5501 2 24.0001 1.55 24.0001 1C24.0001 0.45 23.5501 0 23.0001 0H4.00013C1.80013 0 0.000133578 1.775 0.000133578 3.975C0.000133578 5.125 0.500134 6.25 1.37513 7C-0.274866 8.425 -0.474866 10.9 0.925134 12.55C1.05013 12.7 1.20013 12.85 1.37513 13C-0.299866 14.425 -0.474866 16.95 0.950134 18.625C1.72513 19.5 2.82513 20 4.00013 20H23.0001C23.5501 20 24.0001 19.55 24.0001 19C24.0001 18.45 23.5501 18 23.0001 18H21.6251L21.3501 17.45C20.9001 16.55 20.9001 15.475 21.3501 14.55L21.6251 14H23.0001C23.5501 14 24.0001 13.55 24.0001 13C24.0001 12.45 23.5501 12 23.0001 12H21.6251L21.3501 11.45C20.9001 10.55 20.9001 9.475 21.3501 8.55L21.6251 8H23.0001C23.5501 8 24.0001 7.55 24.0001 7C24.0001 6.45 23.5501 6 23.0001 6H21.6251L21.3501 5.45C20.9001 4.55 20.9001 3.475 21.3501 2.55L21.6251 2ZM2.00013 10C2.00013 8.9 2.90013 8 4.00013 8H7.00013V12H4.00013C2.90013 12 2.00013 11.1 2.00013 10ZM4.00013 18C2.90013 18 2.00013 17.1 2.00013 16C2.00013 14.9 2.90013 14 4.00013 14H7.00013V15C7.00013 15.55 7.45013 16 8.00013 16C8.20013 16 8.40013 15.95 8.55013 15.825L11.0001 14.2L13.4501 15.825C13.9001 16.125 14.5251 16 14.8251 15.55C14.9251 15.375 15.0001 15.2 15.0001 15V14H19.4001C18.8501 15.275 18.8501 16.725 19.4001 18H4.00013ZM13.0001 13.125L11.5501 12.15C11.2251 11.925 10.7751 11.925 10.4501 12.15L9.00013 13.125V6H13.0001V13.125ZM15.0001 12V8H19.4001C18.8501 9.275 18.8501 10.725 19.4001 12H15.0001ZM15.0001 6V5C15.0001 4.45 14.5501 4 14.0001 4H8.00013C7.45013 4 7.00013 4.45 7.00013 5V6H4.00013C2.90013 6 2.00013 5.1 2.00013 4C2.00013 2.9 2.90013 2 4.00013 2H19.4001C18.8501 3.275 18.8501 4.725 19.4001 6H15.0001Z"
        fill="white"
      />
    </svg>
  );
}
