export function StarHoverIcon({
  size = 20,
  fill = '#ffb99d',
  stroke = '#E75113',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 20 19`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55163 1.03827C9.73504 0.666646 10.265 0.666646 10.4484 1.03827L12.5518 5.30025C12.7703 5.74296 13.1926 6.04982 13.6812 6.12081L18.3846 6.80425C18.7947 6.86384 18.9584 7.36783 18.6617 7.65709L15.2583 10.9746C14.9047 11.3192 14.7434 11.8157 14.8269 12.3023L15.6303 16.9866C15.7004 17.3951 15.2717 17.7066 14.9048 17.5137L10.698 15.3021C10.261 15.0723 9.73897 15.0723 9.30199 15.3021L5.09516 17.5137C4.72835 17.7066 4.29963 17.3951 4.36969 16.9866L5.17312 12.3023C5.25658 11.8157 5.09525 11.3192 4.74173 10.9746L1.33833 7.6571C1.04158 7.36783 1.20533 6.86384 1.61544 6.80425L6.31881 6.12081C6.80738 6.04982 7.22973 5.74296 7.44822 5.30025L9.55163 1.03827Z"
        style={{fill, stroke}}
      />
    </svg>
  );
}
