import {useState, useEffect, useCallback} from 'react';
import {Button, CheckIcon, LikeIcon, SpinnerIcon, Toast} from '~/components';
import remove from 'lodash/remove';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {v4 as uuidv4} from 'uuid';

export function ReviewLikes({review}) {
  const {customer} = useOutletContext();
  const [toasts, setToasts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(review.isLiked);
  const fetcher = useFetcher();

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  const handleUnlike = () => {
    setIsLoading(true);
    fetcher.submit(
      {id: review.id},
      {method: 'post', action: `/api/reviews/${review.id}/unlike`},
    );
  };
  const handleLike = () => {
    setIsLoading(true);
    fetcher.submit(
      {id: review.id},
      {method: 'post', action: `/api/reviews/${review.id}/like`},
    );
  };

  useEffect(() => {
    if (fetcher.type == 'actionReload' && fetcher.state == 'loading') {
      addToasts([
        {
          id: uuidv4(),
          message: isLiked ? 'Like sters!' : 'Like adăugat! Va mulțumim!',
          type: 'success',
          icon: <CheckIcon />,
        },
      ]);
      setIsLiked(!isLiked);
      setIsLoading(false);
      //window.location.reload();
    }
  }, [fetcher.state]);

  if (customer) {
    return (
      <>
        <Button
          label={
            isLoading ? (
              review.likes ? (
                <span className="flex items-center">
                  <SpinnerIcon
                    color={isLiked ? 'text-white' : 'text-black'}
                    size={18}
                  />
                  <span className="ml-2">({review.likes})</span>
                </span>
              ) : (
                <span className="flex items-center">
                  <SpinnerIcon
                    color={isLiked ? 'text-white' : 'text-black'}
                    size={18}
                  />
                  <span className="ml-2">(0)</span>
                </span>
              )
            ) : review.likes ? (
              <span className="flex items-center">
                <LikeIcon />
                <span className="ml-2">({review.likes})</span>
              </span>
            ) : (
              <span className="flex items-center">
                <LikeIcon />
                <span className="ml-2">(0)</span>
              </span>
            )
          }
          variant="naked"
          handleClick={isLiked ? handleUnlike : handleLike}
          className={
            isLiked
              ? 'h-8 !py-0 !px-3 rounded-lg bg-onSecondary !text-secondary'
              : 'h-8 !py-0 !px-3 rounded-lg bg-secondary !text-onSecondary'
          }
          disabled={isLoading}
        />
        <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
          {toasts.map((toast) => {
            return (
              <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
            );
          })}
        </div>
      </>
    );
  }

  return (
    <>
      <Button
        label={
          review.likes ? (
            <span className="flex items-center">
              <LikeIcon />
              <span className="ml-2">({review.likes})</span>
            </span>
          ) : (
            <span className="flex items-center">
              <LikeIcon />
              <span className="ml-2">(0)</span>
            </span>
          )
        }
        variant="naked"
        url="/profil/login"
        className={'h-8 !py-0 !px-3 rounded-lg bg-secondary !text-onSecondary'}
      />
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
