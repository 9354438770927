export function CeleMaiBuneCartiAleAnuluiIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5947 10.8848C40.0363 8.73503 37.311 6.78488 33.5493 6.7772C28.7129 6.7772 24.4906 10.3781 23.7306 15.1613C23.6845 15.4454 23.7306 16.29 24.6288 16.4051L28.9892 17.0961C29.5727 17.1883 30.1331 16.789 30.2252 16.1978C30.4785 14.5778 31.9141 13.3571 33.5493 13.3571C34.601 13.3571 35.5453 13.8868 36.2746 14.7467C37.2649 15.9138 37.5797 19.2459 34.1865 20.0214C33.795 20.1135 33.4725 20.3822 33.3574 20.7661C33.2422 21.15 33.342 21.5646 33.6261 21.8487L36.1825 24.413L18.1495 37.5037C18.1879 37.2196 18.2109 36.9355 18.2109 36.6437C18.2109 32.8586 15.1401 29.7875 11.3554 29.7798C10.9255 29.7798 10.5033 29.8259 10.0888 29.9027L20.9823 6.03245C21.2279 5.48733 20.9899 4.8424 20.4449 4.59671C19.8998 4.35102 19.255 4.58903 19.0093 5.13415L7.08711 31.2847C5.51335 32.5361 4.5 34.4709 4.5 36.6361C4.5 40.4289 7.57075 43.5 11.3554 43.5C12.8448 43.5 14.2189 43.0163 15.3474 42.2101C15.3628 42.2025 15.3781 42.2025 15.3858 42.1871C15.3858 42.1871 39.1304 24.9351 39.1534 24.9121C41.4181 23.3689 42.9381 21.0041 43.3757 18.2708C43.7902 15.6604 43.1607 13.0346 41.5947 10.8848ZM11.3554 41.3272C8.76834 41.3272 6.67256 39.2312 6.67256 36.6361C6.67256 34.0487 8.76834 31.9526 11.3554 31.9526C13.9426 31.9526 16.0383 34.0487 16.0383 36.6437C16.0383 39.2312 13.9426 41.3272 11.3554 41.3272ZM41.2338 17.9407C40.8961 20.0598 39.7138 21.9101 37.9558 23.1155L36.336 21.4955C38.2015 20.8122 40.4124 16.8888 38.0249 13.4722C37.1805 12.2591 35.814 11.1919 33.5493 11.1919C31.2232 11.1919 29.1581 12.6814 28.3597 14.8005L26.1104 14.4473C27.1007 11.2457 30.1254 8.95001 33.5416 8.95001C36.1594 8.95001 38.616 10.4779 39.8367 12.1593C41.065 13.8408 41.5563 15.8907 41.2338 17.9407Z"
        fill="#514D71"
      />
    </svg>
  );
}
