export function MinusIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      style={{fill: 'currentcolor'}}
    >
      <rect height="1" width="18" y="9" x="1"></rect>
    </svg>
  );
}
