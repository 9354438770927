import {useState, useEffect} from 'react';
import {Image} from '@shopify/hydrogen';
import {useFetcher} from '@remix-run/react';
import {Metrics} from './Metrics';

export function Slide({slide, handle, author}) {
  const [data, setData] = useState(null);
  const [userReview, setUserReview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [average, setAverage] = useState(0);
  const [open, setOpen] = useState(false);

  const product = useFetcher();

  useEffect(() => {
    product.load(`/api/carti/${handle}/details`);
  }, []);

  useEffect(() => {
    if (product.type === 'done') {
      setData(product?.data);
      setUserReview(product.data.review);
    }
  }, [product.type]);

  useEffect(() => {
    if (data) {
      let total = data?.count_reviews || 0;
      let five_star_reviews = data?.count_five_star_reviews || 0;
      let four_star_reviews = data?.count_four_star_reviews || 0;
      let three_star_reviews = data?.count_three_star_reviews || 0;
      let two_star_reviews = data?.count_two_star_reviews || 0;
      let one_star_reviews = data?.count_one_star_reviews || 0;
      let total_five = five_star_reviews * 5;
      let total_four = four_star_reviews * 4;
      let total_three = three_star_reviews * 3;
      let total_two = two_star_reviews * 2;
      let total_one = one_star_reviews * 1;

      let tempAverage =
        total > 0
          ? (total_five + total_four + total_three + total_two + total_one) /
            total
          : 0;

      setAverage(tempAverage);
      setIsLoading(false);
    }
  }, [data]);
  return (
    <div
      key={slide.id}
      className="flex flex-col items-center text-center px-4 mb-7 lg:absolute lg:bottom-0 lg:right-0 lg:left-0 lg:pt-20 lg:mb-0 lg:px-0 lg:text-left lg:flex-row lg:items-end -mt-14 lg:-mt-40 lg:pl-32"
      style={{
        background:
          'linear-gradient(180deg, rgba(255,243,239,0) 0%, rgba(255,243,239,1) 70%)',
      }}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center lg:flex-row lg:items-end">
          <div className="mb-6 lg:mb-0 lg:pr-6">
            <a href={`/carti/${handle}`}>
              <Image
                data={{...slide.featuredImage, altText: 'Slide'}}
                style={{maxWidth: '216px'}}
              />
            </a>
          </div>
          <div className="lg:pl-6">
            <div className="text-xs bg-body bg-opacity-60 tracking-widest border rounded border-solid-gray inline-flex py-1 px-2 items-center justify-center leading-none font-serif">
              RECOMANDARE
            </div>
            <div className="text-3xl font-serif mt-3 lg:text-5xl">
              <a href={`/carti/${handle}`}>{slide.title.split('-')[0]}</a>
            </div>
            {author ? (
              <div className="text-lg font-serif mt-3 lg:text-xl">
                {author?.title.replace('Autor', '')}
              </div>
            ) : null}
            <Metrics
              className="flex text-lg font-serif justify-between pt-4 lg:text-xl"
              isDataLoading={isLoading}
              count_reviews={data?.count_reviews}
              count_reviews_with_description={
                data?.count_reviews_with_description
              }
              average={average}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
