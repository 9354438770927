import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ro from 'dayjs/locale/ro';

export function DateFromTimestamp({timestamp}) {
  dayjs.extend(relativeTime);
  dayjs.locale(ro);
  const now = Math.floor(+new Date() / 1000);

  if (timestamp && timestamp._seconds) {
    const isLessThanADay = now - timestamp._seconds < 86400;
    return isLessThanADay
      ? dayjs.unix(timestamp._seconds).fromNow()
      : dayjs.unix(timestamp._seconds).format('DD MMM YYYY');
  }

  if (timestamp && timestamp.seconds) {
    const isLessThanADay = now - timestamp.seconds < 86400;
    return isLessThanADay
      ? dayjs.unix(timestamp.seconds).fromNow()
      : dayjs.unix(timestamp.seconds).format('DD MMM YYYY');
  }

  return dayjs(timestamp).fromNow();
}
