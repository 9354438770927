export function NotificationsIcon() {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1299_28820)">
        <path
          d="M9.50002 0C5.80557 0 2.81483 3.01613 2.81483 6.74194V10.2194L0.879644 12.171C-0.316652 13.3774 -0.316652 15.329 0.879644 16.5C1.47779 17.0677 2.25187 17.3871 3.06113 17.3871H15.9741C17.663 17.3871 19.0352 16.0032 19.0352 14.3C19.0352 13.4839 18.7185 12.7032 18.1556 12.1355L16.1852 10.2194V6.74194C16.1852 3.01613 13.1945 0 9.50002 0ZM16.6074 13.6613C16.9593 14.0161 16.9593 14.6194 16.6074 15.0097C16.4315 15.1516 16.1852 15.2581 15.9389 15.2581H3.06113C2.53335 15.2581 2.11113 14.8323 2.11113 14.3C2.11113 14.0516 2.21668 13.8032 2.39261 13.6258L4.92594 11.071V6.74194C4.92594 4.1871 6.96668 2.12903 9.50002 2.12903C12.0333 2.12903 14.0741 4.1871 14.0741 6.74194V11.071L16.6074 13.6613Z"
          fill="#E75113"
        />
        <path d="M12.3152 19.871H6.68555V22H12.3152V19.871Z" fill="#E75113" />
      </g>
      <defs>
        <clipPath id="clip0_1299_28820">
          <rect width="19" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
