export function LikeIcon({width = 14, height = 16}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4264 8.40948C3.4264 8.19007 3.52683 7.95639 3.74618 7.67409C3.91041 7.46274 4.09939 7.2732 4.30671 7.06526C4.38742 6.98431 4.47091 6.90057 4.5568 6.81168C5.12477 6.22382 5.3425 5.44607 5.40907 5.15962C5.43912 5.03027 5.44693 4.90889 5.44693 4.80391V1.53421C5.44693 1.44264 5.52116 1.36842 5.61272 1.36842H6.46583C7.15254 1.36842 7.70923 1.92511 7.70923 2.61182V4.85258C7.70923 5.95132 8.59994 6.84202 9.69868 6.84202H11.2361C12.1928 6.84202 12.9364 7.67487 12.8284 8.62549L12.3077 13.2096C12.2158 14.0195 11.5305 14.6314 10.7154 14.6314H4.3106C3.82112 14.6314 3.4264 14.2366 3.4264 13.7495L3.4264 8.40948Z"
        style={{stroke: 'currentColor'}}
        strokeWidth="1.10525"
      />
      <mask id="path-2-inside-1_141_3603" fill="white">
        <rect y="7.28162" width="3.59205" height="7.90251" rx="1.07761" />
      </mask>
      <rect
        y="7.28162"
        width="3.59205"
        height="7.90251"
        rx="1.07761"
        style={{stroke: 'currentColor'}}
        strokeWidth="2.21049"
        mask="url(#path-2-inside-1_141_3603)"
      />
    </svg>
  );
}
