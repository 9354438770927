export function DiscoverIcon() {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54 4H19.2542V11.5478H8.60048V16.9043H4V60H54V4ZM20.4649 5.21739H23.9758V11.5478H20.4649V5.21739ZM9.81114 12.7652H11.6271V16.9043H9.81114V12.7652ZM5.21065 18.1217H7.20823V58.7826H5.21065V18.1217ZM23.7337 58.7826H8.41889V18.1217H37.414V58.7826H23.7337ZM38.6247 58.7826V16.9043H12.8378V12.7652H46.2518V58.7826H38.6247ZM52.7893 58.7826H47.4625V11.5478H25.1259V5.21739H52.7893V58.7826Z"
        fill="black"
      />
      <path d="M53.7144 33.7142L64.0002 44.0002" stroke="black" />
      <path
        d="M58.3574 23.4285C58.3574 31.6733 51.6736 38.3571 43.4287 38.3571C35.1838 38.3571 28.5 31.6733 28.5 23.4285C28.5 15.1837 35.1838 8.5 43.4287 8.5C51.6736 8.5 58.3574 15.1837 58.3574 23.4285Z"
        fill="#FFF3EF"
        stroke="black"
      />
    </svg>
  );
}
