import {useOutletContext} from '@remix-run/react';
import {FileIcon, FollowersIcon, UserAvatar, FollowUser} from '~/components';
import {colors} from '../lib/utils';

export function UserCard({user}) {
  const {customer} = useOutletContext();
  let customer_id = customer?.id
    ? customer.id.replace('gid://shopify/Customer/', '')
    : null;
  return (
    <div
      className={
        user?.id === customer_id
          ? 'bg-white rounded-lg w-full border-2 border-primary'
          : 'bg-white rounded-lg w-full'
      }
    >
      <div className="pb-8 border-b border-borderLine">
        <div className="flex justify-center pt-9 px-5">
          <UserAvatar
            size={152}
            customer={user}
            background={colors()[Math.floor(Math.random() * colors().length)]}
          />
        </div>
        <div className="text-xl font-serif px-5 mt-9">
          {user?.firstName} {user?.lastName}
        </div>
      </div>
      <div className="flex border-b border-borderLine px-5 py-5">
        <div className="flex flex-grow">
          <FileIcon /> <span className="ml-2">{user.count_reviews || 0}</span>
        </div>
        <div className="flex flex-grow">
          <FollowersIcon fill="#e75113" />
          <span className="ml-2">{user.count_followers || 0}</span>
        </div>
      </div>
      <div className="flex justify-center items-center px-5 py-4">
        {user?.id === customer_id ? (
          <div className="text-center opacity-50 font-serif_italic">
            Contul tău
          </div>
        ) : (
          <FollowUser user={user} customer={customer} />
        )}
      </div>
    </div>
  );
}
