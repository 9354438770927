import {Dialog} from '@headlessui/react';
import {CloseIcon} from '~/components';
import {Button} from '~/components';

export function ProductDescriptionModal({title, description, open, setOpen}) {
  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

          <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between pt-4 pb-3 px-5"
            >
              {title}
              <button
                className="w-8 h-8 flex items-center justify-center"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </button>
            </Dialog.Title>

            <div className="pb-4 px-5 font-serif">
              <div
                className="prose dark:prose-invert"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <div className="flex justify-center mt-4">
                <Button
                  handleClick={() => {
                    setOpen(false);
                  }}
                  variant="sm_primary"
                  label="Închide"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
