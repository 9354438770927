import {useState, useCallback} from 'react';
import remove from 'lodash/remove';
import {AddToList, Button} from '~/components';
// import {AddReview, SeeReview, Toast} from '~/components';
import {SeeReview, Toast, AddReview} from '~/components';

export function ProductCardActions({
  customer,
  id,
  handle,
  url,
  title,
  image,
  author,
  userReview,
  addReview,
  open,
  setOpen,
  listOpen,
  setListOpen,
  isDataLoading,
  showList = true,
  error,
}) {
  const [toasts, setToasts] = useState([]);

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  if (isDataLoading) return null;

  return (
    <>
      <div className=" px-4 pt-4">
        <div className="flex flex-col gap-2">
          {customer && !userReview ? (
            <Button
              label="Scrie o recenzie"
              variant="sm_primary"
              handleClick={() => {
                setOpen(true);
              }}
              className="w-full"
            />
          ) : null}
          {customer && userReview ? (
            <Button
              label="Vezi recenzia"
              variant="sm_secondary"
              handleClick={() => {
                setOpen(true);
              }}
              className="w-full"
            />
          ) : null}

          {!customer ? (
            <a
              href="/profil/login"
              className="block font-serif m-0 items-center justify-center text-lg text-center rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors px-6 py-2 text-white bg-primary hover:bg-gray-800 active:bg-gray-700"
            >
              Scrie o recenzie
            </a>
          ) : null}
          {customer && showList ? (
            <Button
              label="Adaugă în listă"
              variant="sm_secondary"
              handleClick={() => {
                setListOpen(true);
              }}
              className="w-full"
            />
          ) : null}
        </div>

        <a
          className="bg-none text-primary block font-serif m-0 w-full items-center justify-center text-lg text-center px-6 py-4 rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed"
          href={url}
        >
          Vezi detalii
        </a>
      </div>
      {customer && !userReview ? (
        <AddReview
          customer={customer}
          id={id}
          handle={handle}
          title={title}
          image={image}
          author={author || 'Necunoscut'}
          open={open}
          setOpen={setOpen}
          addToasts={addToasts}
          addReview={addReview}
          error={error}
        />
      ) : null}

      {customer && showList ? (
        <AddToList
          open={listOpen}
          setOpen={setListOpen}
          product={{id, handle, image, author, title}}
          addToasts={addToasts}
        />
      ) : null}

      {userReview ? (
        <SeeReview
          review={userReview}
          open={open}
          setOpen={setOpen}
          title={title}
          customer={customer}
        />
      ) : null}

      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
