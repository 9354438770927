import {flattenConnection, Image} from '@shopify/hydrogen';
import {getAuthor, getHandle} from '~/lib/utils';
import {FileIcon, StarIcon} from './icons';
import {Slide} from './Slide';

export function Slider({items}) {
  const slides = flattenConnection(items);

  return (
    <div className="">
      {slides.map((slide) => {
        const {products} = slide;
        const items = flattenConnection(products);
        return (
          <div
            className="flex flex-col w-full relative lg:pb-24 lg:mb-8"
            key={slide.id}
          >
            <div className="w-full">
              {slide?.image ? (
                <Image
                  data={{...slide.image, altText: slide.title}}
                  className="w-full"
                  style={{
                    maxWidth: '1550px',
                    width: '100%',
                    height: 'auto',
                    margin: '0 auto',
                  }}
                />
              ) : (
                'NO IMAGE'
              )}
            </div>
            {items ? (
              items.map((slide) => {
                const handle = getHandle(slide.handle);
                const collections = flattenConnection(slide?.collections);
                const author = getAuthor(collections);
                return (
                  <Slide
                    key={slide.id}
                    slide={slide}
                    handle={handle}
                    author={author}
                  />
                );
              })
            ) : (
              <div
                className="flex flex-col items-center text-center px-4 mb-7 lg:absolute lg:bottom-0 lg:right-0 lg:left-0 lg:pt-20 lg:mb-0 lg:px-0 lg:text-left lg:flex-row lg:items-end -mt-14 lg:-mt-40 lg:pl-32"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255,243,239,0) 0%, rgba(255,243,239,1) 70%)',
                }}
              >
                <div className="mb-6 lg:mb-0 lg:pr-6">
                  <Image
                    src="https://via.placeholder.com/432x602/eeeeee/ffffff?text=..."
                    width="432"
                    height="602"
                    style={{maxWidth: '216px'}}
                    alt="Placeholder"
                  />
                </div>
                <div className="lg:pl-6">
                  <div className="text-3xl font-serif mt-3 lg:text-5xl">
                    No Product
                  </div>
                  <div className="text-lg font-serif mt-3 lg:text-xl">
                    Adaugati produse in colectia selectata
                  </div>
                  <div className="flex text-lg font-serif mt-3 justify-center lg:justify-start lg:text-xl">
                    <span className="flex items-center">
                      <StarIcon /> <span className="ml-2">4,7</span>
                    </span>
                    <span className="flex items-center ml-4">
                      <FileIcon /> <span className="ml-2"> 233</span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
