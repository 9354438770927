import {useEffect, useState} from 'react';

import {DateFromTimestamp} from '~/components';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {UserAvatar} from './global/UserAvatar';

export function AnswerCard({answer, resource, isLast}) {
  let localCustomer = JSON.parse(localStorage.getItem(answer.customer));
  const {customer} = useOutletContext();
  const customer_id = customer?.id.replace('gid://shopify/Customer/', '');
  const [author, setAuthor] = useState(localCustomer);
  const fetcher = useFetcher();

  useEffect(() => {
    if (answer?.customer && !localCustomer) {
      fetcher.load(`/api/users/${answer.customer}`);
    }
  }, [answer.customer]);

  useEffect(() => {
    if (!fetcher.data) return;

    if (!localCustomer) {
      localStorage.setItem(fetcher.data.id, JSON.stringify({...fetcher.data}));
    }
    setAuthor({...fetcher.data});
  }, [fetcher.data]);

  return (
    <div
      className={
        isLast
          ? 'flex items-center'
          : 'flex items-center mb-4 pb-4 border-b border-gray-100'
      }
    >
      <div>
        <UserAvatar size={64} customer={author} />
      </div>
      <div>
        <div>
          {author?.firstName} {author?.lastName}{' '}
          {answer.customer === customer_id &&
          resource.customer !== answer.customer ? (
            <em className="text-sm opacity-60">(tu)</em>
          ) : null}
          {answer.customer === resource.customer &&
          resource.customer !== customer_id ? (
            <em className="text-sm opacity-60">
              (a răspuns la propria întrebare)
            </em>
          ) : null}
          {answer.customer === resource.customer &&
          resource.customer === customer_id ? (
            <em className="text-sm opacity-60">(tu)</em>
          ) : null}
        </div>
        <div className="text-black opacity-60 text-sm mb-2">
          <DateFromTimestamp timestamp={answer.created_at} />
        </div>
        <div>{answer.answer}</div>
      </div>
    </div>
  );
}
