/**
 * A shared component that specifies the icon to render when opened
 */
export function LogoGirlIcon() {
  return (
    <svg
      height="54"
      x="0px"
      y="0px"
      viewBox="0 0 121.4 121.9"
      style={{enableBackground: 'new 0 0 121.4 121.9'}}
    >
      <g>
        <path
          d="M11,118.8L0.3,108.3c0,0,0,0,0,0c0,0,7.2-2.7,19-2.4c11.3,0.3,27.3,4.7,38.2,14.5c0.3,0.2,0,0.6-0.3,0.5
		C51.5,118.4,33.1,111.4,11,118.8C11,118.8,11,118.8,11,118.8z"
        />
        <path
          d="M110.3,118.8l10.7-10.4c0,0,0,0,0,0c0,0-7.2-2.7-19-2.4c-11.4,0.3-27.4,4.8-38.4,14.7c-0.2,0.2,0,0.6,0.3,0.4
		C69.4,118.5,88.1,111.3,110.3,118.8C110.3,118.8,110.3,118.8,110.3,118.8z"
        />
        <g>
          <path
            d="M56.9,116c-5.8-4.6-21.1-14.9-40.1-14.2c0,0,0,0,0,0l-6.5-11.8c0,0,0,0,0,0c0,0,28.6-1.6,47.3,25.4
			C57.9,115.8,57.4,116.3,56.9,116z"
          />
          <path
            d="M64.4,116c5.8-4.6,21.1-14.9,40.1-14.2c0,0,0,0,0,0l6.5-11.8c0,0,0,0,0,0c0,0-28.6-1.6-47.3,25.4
			C63.4,115.8,64,116.3,64.4,116z"
          />
        </g>
        <path
          d="M95.8,73.3c-1.2,1.2,1.5,4.2,3.4,5.5c1.3,0.9,3.8,3,4.3,5c0.1,0.5,0.2,1,0,1.4c-0.1,0.2-0.2,0.5-0.4,0.7
		c-0.7,0.8-1.6,0.9-2.7,0.6c-0.4-0.1-0.9-0.3-1.3-0.5c-1.5-0.7-2.9-1.6-3.5-1.5c-1,0.2-0.3,1.4,0.5,2.2c-1.2,0.3-2.4,0.7-3.5,1
		c-1.1,0.4-2.2,0.8-3.2,1.2c-17.5,6.9-25.8,19.8-27.9,23.5c-0.3,0.4-0.9,0.4-1.1,0c-7-12-18.4-18.9-28.6-22.8c1-3,2.6-4.8,4.9-4.7
		c11.5,0.7,17.7,3,17.9,1.4s2.1-11.8-1-15.3c-3.1-3.5-10,2.3-12.3-0.5c-0.5-0.6-1.5-1.8-2.6-3.5c-0.8-1.3-1.6-3-2.1-4.8
		C36.2,61,36,59.6,36,58.1c0,0-1.2-2.4-0.8-4c0.3-1.6,3.2-2,3.4-3c0.2-1-2.5-7.8-2.4-10.3c0-2.5,1.5-7.1,5.1-9.3
		c1.7-1.1,4.7-2.7,8.1-3.9c2.9-1.1,6.1-2,9.2-2.1c1,0,1.9,0,2.8,0.1c7.5,0.8,14.1,4.9,16.1,6.8c0,0,2.7-1.4,5.8-7.4
		c3.1-6,4.1-4.7,6.2-2.6c2.1,2.1,5.3,4.8,4.8,6.7c-0.5,1.9-0.5,3.8,1.5,5.7c2,1.9,0.5,4.3-0.1,5.8c-0.6,1.6,3.2,6.3-1.9,7.9
		c-5.2,1.5-10.4,2.5-10.6,1.6c-0.2-0.9,2.9-7.2,1.5-10.4c-1.4-3.1-3.6-4.5-4.5-3c-0.9,1.5,2.6,6.9-0.7,15.6
		c-3.2,8.8-6.7,11.8-5.3,11.9s6.1-2.6,7.3-1.3c1.2,1.3,5.1,5.2,5,4.2c-0.1-1,0.9-12.9,7.8-17c6.9-4.2,7.9,1.6,7.9,2.6
		c0,1-2,8.8-0.2,8.7s3.3-1.8,4.2,0.7c0.9,2.5,1.6,9.4-1.8,12.4C101.2,77.7,97,72.1,95.8,73.3z"
        />
        <path
          d="M11.6,58.3l2,1.3c0.1,0.1,0.2,0.2,0.2,0.3l0,2.4c0,0.3,0.4,0.5,0.6,0.3l1.9-1.5c0.1-0.1,0.2-0.1,0.3-0.1l2.3,0.7
		c0.3,0.1,0.6-0.2,0.5-0.5l-0.8-2.3c0-0.1,0-0.2,0-0.3l1.4-2c0.2-0.3,0-0.6-0.3-0.6l-2.4,0.1c-0.1,0-0.2-0.1-0.3-0.2l-1.4-1.9
		c-0.2-0.3-0.6-0.2-0.7,0.1l-0.7,2.3c0,0.1-0.1,0.2-0.2,0.3l-2.3,0.8C11.4,57.7,11.3,58.1,11.6,58.3z"
        />
        <path
          d="M13.1,33l1.2,2.7c0.1,0.1,0.1,0.3,0,0.4l-1.5,2.5c-0.2,0.3,0.1,0.7,0.4,0.7l2.9-0.3c0.2,0,0.3,0,0.4,0.2l1.9,2.2
		c0.3,0.3,0.7,0.2,0.8-0.2l0.6-2.9c0-0.1,0.1-0.3,0.3-0.3l2.7-1.1c0.4-0.1,0.4-0.6,0.1-0.8l-2.6-1.5c-0.1-0.1-0.2-0.2-0.2-0.4
		l-0.2-2.9c0-0.4-0.5-0.6-0.8-0.3l-2.2,2c-0.1,0.1-0.3,0.1-0.4,0.1l-2.9-0.7C13.2,32.3,12.9,32.6,13.1,33z"
        />
        <path
          d="M20.9,77.6l1.6,0.3c0.1,0,0.2,0.1,0.2,0.1l0.7,1.5c0.1,0.2,0.4,0.2,0.5,0l0.8-1.5c0-0.1,0.1-0.1,0.2-0.1l1.6-0.2
		c0.2,0,0.3-0.3,0.2-0.4l-1.2-1.2c-0.1-0.1-0.1-0.1-0.1-0.2l0.3-1.6c0-0.2-0.2-0.4-0.4-0.3l-1.5,0.7c-0.1,0-0.2,0-0.2,0l-1.4-0.8
		c-0.2-0.1-0.4,0-0.4,0.3l0.2,1.6c0,0.1,0,0.2-0.1,0.2l-1.2,1.1C20.6,77.3,20.7,77.6,20.9,77.6z"
        />
        <path
          d="M28.3,13.9l3.2-1.3c0.2-0.1,0.3-0.2,0.3-0.4l0.7-3.4c0.1-0.4,0.7-0.6,1-0.2l2.3,2.7c0.1,0.1,0.3,0.2,0.5,0.2l3.5-0.4
		c0.5,0,0.8,0.4,0.5,0.8l-1.8,3c-0.1,0.2-0.1,0.3,0,0.5l1.4,3.2c0.2,0.4-0.2,0.9-0.6,0.8l-3.4-0.8c-0.2,0-0.4,0-0.5,0.1L32.7,21
		c-0.3,0.3-0.9,0.1-0.9-0.4l-0.3-3.5c0-0.2-0.1-0.3-0.3-0.4l-3-1.8C27.8,14.7,27.9,14.1,28.3,13.9z"
        />
        <path
          d="M55.3,11.7l2.8-3.6c0.1-0.2,0.2-0.4,0.1-0.7L56.8,3c-0.2-0.6,0.4-1.1,0.9-0.9L62,3.7c0.2,0.1,0.5,0,0.7-0.1l3.7-2.7
		c0.5-0.3,1.2,0,1.1,0.6l-0.1,4.6c0,0.2,0.1,0.5,0.3,0.6l3.7,2.7c0.5,0.4,0.3,1.1-0.2,1.3L66.8,12c-0.2,0.1-0.4,0.2-0.5,0.5
		l-1.4,4.3c-0.2,0.6-0.9,0.7-1.3,0.2L61,13.2c-0.1-0.2-0.4-0.3-0.6-0.3l-4.6,0C55.2,12.8,54.9,12.1,55.3,11.7z"
        />
      </g>
    </svg>
  );
}
