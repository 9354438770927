import {CollectionCardSmall} from '~/components';
import {getHandle} from '~/lib/utils';

export function CollectionSwimlane({collections, count = '', uid}) {
  const classes = `swimlane hiddenScroll md:pb-8 grid-cols-93 sm:grid-col-50${count} md:grid-cols-50${count} lg:grid-cols-20`;

  return (
    <div>
      <div className={classes}>
        {collections &&
          collections.map((collection) => {
            const handle = getHandle(collection.handle);
            return (
              <CollectionCardSmall
                key={collection.id}
                image={{
                  src: collection.image?.transformedSrc,
                  width: collection.image?.width,
                  height: collection.image?.height,
                }}
                image_type={'icon'}
                title={collection.title}
                id={collection.id}
                handle={handle}
                count={collection.count?.value || 0}
                url={`/carti/${handle}`}
              />
            );
          })}
      </div>
    </div>
  );
}
