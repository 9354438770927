import {useState, useEffect} from 'react';
import {Dialog} from '@headlessui/react';
import {Button, CheckIcon, SpinnerIcon} from '~/components';
import {v4 as uuidv4} from 'uuid';
import {useFetcher, useSearchParams} from '@remix-run/react';

export function AddToList({open, setOpen, product, addToasts}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [list, setList] = useState(null);

  const [nextPage, setNextPage] = useState(false);
  const [endCursor, setEndCursor] = useState('');
  const [items, setItems] = useState([]);

  const fetcher = useFetcher();

  function fetchMoreItems() {
    let limit = searchParams.get('limit');
    limit = limit ? `&limit=${limit}` : '';
    fetcher.load(`/profil/liste?index${limit}&endCursor=${endCursor}`);
  }

  useEffect(() => {
    let limit = searchParams.get('limit');
    limit = limit ? `&limit=${limit}` : '';
    fetcher.load(`/profil/liste?index${limit}&endCursor=${endCursor}`);
  }, []);

  useEffect(() => {
    if (!fetcher.data || fetcher.submission) return;

    const fetcherData = fetcher.data;

    setIsDataLoading(false);
    setItems((prev) => [...prev, ...fetcherData.data.items]);
    setNextPage(fetcherData.data?.pageInfo?.hasNextPage);
    setEndCursor(fetcherData.data?.pageInfo?.endCursor);
  }, [fetcher.data]);

  useEffect(() => {
    if (fetcher.data?.response == 'ok') {
      setIsLoading(false);
      setOpen(false);
      addToasts([
        {
          id: uuidv4(),
          message: 'Carte adăugată în listă',
          type: 'success',
          icon: <CheckIcon />,
        },
      ]);
    }
  }, [fetcher.data]);

  const handleChange = (id) => {
    setList(id);
  };

  const handleAddToList = () => {
    if (!list) return;

    setIsLoading(true);
    fetcher.submit(
      {
        product: JSON.stringify(product),
        list,
      },
      {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        action: `/api/lists/products/add`,
      },
    );
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="text-lg text-center font-medium leading-6 text-gray-900 font-serif flex justify-center pt-4 pb-3 px-5 border-b border-gray-200"
          >
            Adaugă în listă
          </Dialog.Title>
          <div className="pt-6 px-6">
            {isDataLoading ? (
              <div className="flex justify-center items-center gap-4">
                <SpinnerIcon /> <span className="text-lg">Se încarcă</span>
              </div>
            ) : items.length === 0 ? (
              'Nu exista liste'
            ) : null}
            {items && items.length > 0 ? (
              <>
                <div className="flex flex-col">
                  {items.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          index === items.length - 1
                            ? 'py-4 flex justify-between'
                            : 'py-4 border-b border-gray-200 flex justify-between'
                        }
                      >
                        <label
                          className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer flex-grow"
                          htmlFor={item.id}
                        >
                          {item.title}{' '}
                          <span className="text-gray-500">
                            ({item.count_products || 0}{' '}
                            {item.count_products === 1 ? 'carte' : 'cărți'})
                          </span>
                        </label>
                        <input
                          className="hidden"
                          type="radio"
                          name="selectedList"
                          id={item.id}
                          onChange={() => {
                            handleChange(item.id);
                          }}
                        />
                        <span className="w-5 h-5 rounded-full border border-black border-opacity-10 flex items-center justify-center">
                          {list === item.id ? (
                            <span
                              className="bg-primary rounded-full"
                              style={{width: '10px', height: '10px'}}
                            ></span>
                          ) : null}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
          <div className="py-4 px-5 uppercase font-serif text-xs flex justify-between">
            {nextPage ? (
              <div className="flex items-center justify-center">
                <button
                  disabled={fetcher.state !== 'idle'}
                  onClick={fetchMoreItems}
                  width="full"
                  className="text-lg text-primary mr-4 md:mr-0"
                >
                  {fetcher.state !== 'idle' ? (
                    <SpinnerIcon size={24} />
                  ) : (
                    'Mai multe liste'
                  )}
                </button>
              </div>
            ) : (
              <span></span>
            )}
            <Button
              handleClick={handleAddToList}
              variant="sm_primary"
              label={
                isLoading ? (
                  <SpinnerIcon size={28} className="mr-0" />
                ) : (
                  'Adaugă'
                )
              }
              disabled={isLoading || !list}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
