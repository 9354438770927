import {CollectionIcon} from '~/components';
import {Image} from '@shopify/hydrogen';
import {
  ActiuneAventuraIcon,
  ActivitatiJocuriIcon,
  AnimaleIcon,
  FictiuneIcon,
  MistereDetectiviIcon,
  StiintificoFantasticIcon,
  IlustrateIcon,
  PremiateIcon,
  StickerIcon,
  SelectieIcon,
  CartiCautateIcon,
  CeleMaiBuneCartiAleAnuluiIcon,
} from '~/components';
import {colors} from '~/lib/utils';

export function CollectionCardSmall({image, title, handle, count, image_type}) {
  return (
    <div className="p-2 flex-grow-0 flex-shrink-0 basis-[216px] w-full h-full relative transition-transform">
      <div className="bg-white rounded-3xl overflow-hidden mb-4 lg:m-0">
        <div className="py-4 px-4 flex items-center justify-start">
          <a href={`/categorii/${handle}`}>
            {image_type && image_type !== 'icon' && image?.src ? (
              <Image
                data={{
                  src: image.url,
                  width: image.width,
                  height: image.height,
                  altText: title,
                }}
                style={{maxWidth: '96px'}}
                className="rounded-full"
              />
            ) : null}
            {image_type && image_type === 'icon' ? (
              <Icon handle={handle} />
            ) : null}
          </a>
        </div>
        <div className="px-4 border-b border-solid border-line">
          <div className="font-serif leading-tight text-xl h-14 mb-3 overflow-hidden">
            {title.replace('Tema', '')}
          </div>
        </div>
        <div className="flex text-lg font-serif justify-between px-4 py-4 lg:text-xl border-b border-solid border-line">
          <span className="flex items-center flex-grow">
            <CollectionIcon /> <span className="ml-2">{count}</span>
          </span>
        </div>
        <div className="px-4 py-4">
          <a
            href={`/categorii/${handle}`}
            className="block font-serif m-0 items-center justify-center text-lg text-center rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors px-6 py-2 text-white bg-primary hover:bg-gray-800 active:bg-gray-700 w-full"
          >
            Vezi cărți
          </a>
        </div>
      </div>
    </div>
  );
}

function Icon({handle}) {
  if (handle.includes('aventura'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <ActiuneAventuraIcon />
      </div>
    );

  if (handle.includes('activitati'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <ActivitatiJocuriIcon />
      </div>
    );

  if (handle.includes('animale'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <AnimaleIcon />
      </div>
    );

  if (handle.includes('fictiune'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <FictiuneIcon />
      </div>
    );

  if (handle.includes('mistere'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <MistereDetectiviIcon />
      </div>
    );

  if (handle.includes('stiintifico'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <StiintificoFantasticIcon />
      </div>
    );

  if (handle.includes('ilustrate'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <IlustrateIcon />
      </div>
    );

  if (handle.includes('premiate'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <PremiateIcon />
      </div>
    );

  if (handle.includes('sticker'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <StickerIcon />
      </div>
    );

  if (handle.includes('selectia'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <SelectieIcon />
      </div>
    );

  if (handle.includes('cele-mai-cautate'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <CartiCautateIcon />
      </div>
    );

  if (handle.includes('cele-mai-bune-carti'))
    return (
      <div
        className="w-24 h-24 rounded-full flex justify-center items-center"
        style={{
          background: colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        <CeleMaiBuneCartiAleAnuluiIcon />
      </div>
    );

  return null;
}
