import {useEffect, useState} from 'react';
import {Image} from '@shopify/hydrogen';
import {getHandle} from '~/lib/utils';

export function SearchCard({item, setIsSearchDropOpen, setTerm}) {
  const [handle, setHandle] = useState('');
  useEffect(() => {
    if (item?.handle) {
      setHandle(getHandle(item.handle));
    }
  }, [item.handle]);

  return (
    <div className="flex items-center mb-2 font-serif px-4">
      <div
        className="w-7 flex items-center justify-center mr-4"
        style={{flex: '0 0 28px'}}
      >
        <a href={`/carti/${handle}`}>
          <Image data={item.images[0]} className="w-7" />
        </a>
      </div>
      <div>
        <a href={`/carti/${handle}`} className="text-left leading-5">
          {item.title}
        </a>
      </div>
    </div>
  );
}
