import {FollowersIcon, LikeIcon} from './icons';

export function CollectionMetrics({followers, likes, margin = 6, padding = 6}) {
  return (
    <div className={`flex items-center justify-center mt-${margin}`}>
      <span
        className={`flex items-center font-serif p-${padding} text-primary`}
      >
        <FollowersIcon />{' '}
        <span className="ml-2 text-black">{followers || 0}</span>
      </span>
      <span
        className={`flex items-center font-serif p-${padding} text-primary`}
      >
        <LikeIcon width={18} height={18} />{' '}
        <span className="ml-2 text-black">{likes || 0}</span>
      </span>
    </div>
  );
}
