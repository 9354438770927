export function BookIcon() {
  return (
    <svg
      width="56"
      height="48"
      viewBox="0 0 56 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.595112 47.8198H21.9001C21.9596 47.8198 22.0191 47.8198 22.0786 47.7597C24.0425 48.0601 26.0659 48.0601 28.0298 47.8198H55.4049C55.762 47.8198 56 47.5795 56 47.219V10.8135C56 10.4531 55.762 10.2128 55.4049 10.2128H52.4293V6.00751C52.4293 5.70713 52.2508 5.46683 51.9532 5.40676L51.2391 5.22653C43.4431 3.48436 35.3496 5.04631 28.7439 9.55194L27.7917 10.2128C21.305 11.0538 14.8183 8.71089 10.3549 3.84481L6.96281 0.180225C6.72476 -0.0600751 6.36769 -0.0600751 6.12965 0.180225C6.01063 0.300375 5.95112 0.420526 5.95112 0.600751V10.2128H0.595112C0.238045 10.2128 0 10.4531 0 10.8135V47.219C0 47.5194 0.238045 47.8198 0.595112 47.8198ZM52.2508 42.8936C52.3698 42.7735 52.4888 42.5932 52.4888 42.413V11.4143H54.8693V46.6183H30.0531C36.4803 42.4731 44.3358 41.1514 51.7152 43.0138C51.8937 43.0138 52.0723 43.0138 52.2508 42.8936ZM29.458 10.5732C35.7662 6.24781 43.5622 4.74593 51.0606 6.42804L51.2986 6.48811V41.632C43.7407 39.9499 35.8257 41.3917 29.339 45.6571L28.6249 46.1377V11.1139L29.458 10.5732ZM9.46227 4.68586C14.0446 9.67209 20.7099 12.1952 27.4346 11.4743V46.6783C21.0669 47.3993 14.6993 44.9963 10.3549 40.2503L7.14134 36.766V11.1139C7.26036 10.9337 7.26036 10.6934 7.14134 10.5131V2.10263L9.46227 4.68586ZM1.19022 11.4143H5.95112V37.0063C5.95112 37.1865 6.01063 37.3066 6.12965 37.4268L9.46227 41.0914C11.7237 43.5544 14.4612 45.4168 17.5558 46.6183H1.19022V11.4143Z"
        fill="black"
      />
    </svg>
  );
}
