import {Image} from '@shopify/hydrogen';
import {Link} from '@remix-run/react';
import {Button} from './Button';

export function AuthorsList({title, collections, className}) {
  return (
    <div className={className ? className : `pt-10 pb-10 xl:pt-20 xl:pb-20`}>
      <div className="container mx-auto px-4 pr-0 md:pr-4 md:px-4">
        <div
          className={
            'text-black font-sans font-bold mb-6 text-2xl lg:text-3xl lg:mb-10 text-center'
          }
        >
          {title}
        </div>

        <div
          className="mx-auto relative list-none p-0 z-10 overflow-scroll hiddenScroll"
          style={{scrollbarWidth: 'none '}}
        >
          <div
            className="relative w-full h-full z-10 flex transition-transform items-end"
            style={{boxSizing: 'content-box'}}
          >
            {collections.map((item, index) => {
              const author = item;

              return (
                <div
                  key={item.id}
                  className={
                    index === 2 || index === 3
                      ? 'p-2 flex-grow-0 flex-shrink-0 basis-[332px] w-3/4 h-full relative transition-transform'
                      : 'p-2 flex-grow-0 flex-shrink-0 basis-[224px] w-3/4 h-full relative transition-transform'
                  }
                >
                  <div className="overflow-hidden mb-4 lg:m-0">
                    <div className="pb-4 flex items-center justify-center">
                      <Link
                        to={`/categorii/${author.handle}`}
                        className={
                          index === 2 || index === 3
                            ? 'flex justify-center items-center w-80 h-80 overflow-hidden rounded-2xl'
                            : 'flex justify-center items-center w-56 h-56 overflow-hidden rounded-2xl'
                        }
                      >
                        <Image
                          data={{
                            url:
                              author.image?.url ||
                              'https://via.placeholder.com/728x728.jpg',
                            width: author.image?.width || 728,
                            height: author.image?.height || 728,
                            altText: author.title,
                          }}
                          className="object-cover"
                        />
                      </Link>
                    </div>
                    <div>
                      <div className="font-serif leading-tight text-center text-xl h-14 overflow-hidden">
                        {author.title.replace('Autor ', '')}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            isExternal
            url="/autori?litera=a"
            label="Toți autorii"
            variant="sm_primary"
            className="inline-flex w-auto"
          />
        </div>
      </div>
    </div>
  );
}
