import {
  ArrowRightIcon,
  StarDrawIcon,
  CreateListIcon,
  DiscoverIcon,
  CreateList,
} from '~/components';
import {Link} from '@remix-run/react';
import {useOutletContext} from '@remix-run/react';

export function ListActions({shelveProducts = []}) {
  const {customer} = useOutletContext();
  return (
    <div className="">
      <div className="lg:hidden mb-10 md:mb-0 px-4 pt-10">
        <div className="flex flex-col border border-solid border-line rounded-3xl font-serif uppercase">
          {!customer ? (
            <Link
              to="/profil/login"
              className="p-5 border-b border-solid border-line flex justify-between items-center uppercase"
            >
              Listă nouă
              <ArrowRightIcon />
            </Link>
          ) : (
            <CreateList />
          )}

          <Link
            to="/liste/toate"
            className="p-5 border-b border-solid border-line flex justify-between items-center"
          >
            Toate listele
            <ArrowRightIcon />
          </Link>
          <Link
            to="/profil/liste"
            className="p-5 flex justify-between items-center"
          >
            Listele mele
            <ArrowRightIcon />
          </Link>
        </div>
      </div>
      <div className="hidden lg:block pt-0 pb-10 xl:pt-0 xl:pb-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-12 gap-6 items-start">
            <div className="col-span-12 lg:col-span-4">
              <div className="flex">
                <div className="icon icon-shield pt-3">
                  <CreateListIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">Creează o listă</span>
                  <span className="block mb-6">
                    Alătură-te comunității recenzii.ro și lorem ipsum dolor
                    liste pe orice subiect.{/*TODO: Adaugat text*/}
                  </span>
                  {!customer ? (
                    <Link
                      to="/profil/login"
                      className="text-primary border-b border-solid border-primary pb-1"
                    >
                      Listă nouă
                    </Link>
                  ) : (
                    <CreateList />
                  )}
                </div>
              </div>
            </div>

            <div className="col-span-12  lg:col-span-4">
              <div className="flex">
                <div className="icon icon-shipping pt-3">
                  <DiscoverIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">Descoperă</span>
                  <span className="block mb-6">
                    Sofiei, protagonista celui mai cunoscut roman al
                    norvegianului.{/*TODO: Adaugat text*/}
                  </span>
                  <Link
                    to="/liste/toate"
                    className="text-primary border-b border-solid border-primary pb-1"
                  >
                    Toate listele
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-span-12  lg:col-span-4">
              <div className="flex">
                <div className="icon icon-gift pt-3">
                  <StarDrawIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">Listele Mele</span>
                  <span className="block mb-6">
                    Într-o succesiune de oglinzi creatoare de iluzii nesfârșite,
                    Sofiei, protagonista{/*TODO: Adaugat text*/}
                  </span>
                  <Link
                    to="/profil/liste"
                    className="text-primary border-b border-solid border-primary pb-1"
                  >
                    Vezi listele tale
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
