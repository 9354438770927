export function BagIcon({width = 20, height = 21}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6293 26.8217L26.6293 26.8217L26.629 26.8295C26.5995 27.786 25.8746 28.5 24.8965 28.5H3.1035C2.13123 28.5 1.40042 27.7546 1.37097 26.829L1.37103 26.829L1.37069 26.8217L0.50065 8.09839C0.475413 7.27066 1.2263 6.5 2.23329 6.5H25.7667C26.7737 6.5 27.5246 7.27071 27.4994 8.09848L26.6293 26.8217Z"
        style={{stroke: 'currentcolor'}}
      ></path>
      <path
        d="M14.0206 0.5C17.0091 0.5 20.0622 2.83628 20.4981 6.5H7.53819C7.94124 2.84102 11.0268 0.5 14.0206 0.5Z"
        style={{stroke: 'currentcolor'}}
      ></path>
    </svg>
  );
}
