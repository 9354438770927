import {ATTR_LOADING_EAGER} from '~/lib/const';
import {Image} from '@shopify/hydrogen';
import {OpenBookIcon} from './icons';
import {Button} from './Button';

/**
 * A client component that defines a media gallery for hosting images, 3D models, and videos of products
 */
export function ProductGallery({media, className}) {
  if (!media.length) {
    return null;
  }

  const data = {
    ...media[0],
    image: {
      // @ts-ignore
      ...media[0].image,
      altText: media[0].alt || 'Product image',
    },
  };

  return (
    <div className="mb-3">
      <div
        className="gap-4 flex md:flex overflow-x-scroll no-scrollbar scroll-snap-x scroll-smooth md:h-auto place-content-start"
        tabIndex="-1"
      >
        <div className="flex-col lg:flex-row flex justify-between w-full">
          <div className="flex-grow relative justify-center items-center flex product-images-container">
            <div className="relative z-1 lg:px-10 max-w-full w-full">
              <div>
                <div className="relative">
                  <div className="product-page-images">
                    {/* <div className="slide h-full"></div> */}
                    <div className="slide h-72 md:h-full">
                      <div className="lg:w-3/4 mx-auto h-full text-center flex items-center justify-center">
                        {data.image && (
                          <Image
                            data={{
                              url: data.image.url,
                              altText: data.image.altText,
                            }}
                            className="w-[70vw] md:w-full h-full md:h-auto object-contain md:object-cover object-center flex-shrink-0 md:flex-shrink-none snap-start md:col-span-2"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center">
        <Button
          variant="naked"
          handleClick={() => {}}
          label={
            <span className="inline-flex items-center justify-center">
              <OpenBookIcon /> <span className="ml-3">Răsfoiește</span>
            </span>
          }
        />
      </div> */}
    </div>
  );
}
