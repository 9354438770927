import {Button, Grid, ProductCard, Link, SpinnerIcon} from '~/components';
import {getImageLoadingPriority} from '~/lib/const';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {useEffect, useState} from 'react';
import {getAuthor} from '~/lib/utils';
import {flattenConnection} from '@shopify/hydrogen';

export function ProductGrid({url, collection, source = null, ...props}) {
  const {customer} = useOutletContext();
  const [initialProducts, setInitialProducts] = useState(
    collection?.products?.nodes || [],
  );

  const [nextPage, setNextPage] = useState(
    collection?.products?.pageInfo?.hasNextPage,
  );
  const [endCursor, setEndCursor] = useState(
    collection?.products?.pageInfo?.endCursor,
  );
  const [products, setProducts] = useState(initialProducts);

  // props have changes, reset component state
  const productProps = collection?.products?.nodes || [];
  if (initialProducts !== productProps) {
    setInitialProducts(productProps);
    setProducts(productProps);
  }

  const fetcher = useFetcher();

  function fetchMoreProducts() {
    if (source && source === 'search') {
      //console.log('endCursor', `${url}&cursor=${endCursor}`);
      fetcher.load(`${url}&cursor=${endCursor}`);
    } else {
      fetcher.load(`${url}?index&cursor=${endCursor}`);
    }
  }

  useEffect(() => {
    if (!fetcher.data) return;

    const {collection} = fetcher.data;

    console.log('Colle', fetcher);

    setProducts((prev) => [...prev, ...collection.products.nodes]);
    setNextPage(collection.products.pageInfo.hasNextPage);
    setEndCursor(collection.products.pageInfo.endCursor);
  }, [fetcher.data]);

  const haveProducts = initialProducts.length > 0;

  if (!haveProducts) {
    return (
      <>
        <div className="container mx-auto px-4">
          <div className="text-center pb-20 md:pb-28 md:max-w-2xl mx-auto">
            <p>Nu există cărți in această categorie.</p>
            <a href="/carti">
              <p className="underline">Vezi toate cărțile</p>
            </a>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={
          nextPage
            ? 'container mx-auto px-4'
            : 'container mx-auto px-4 mb-9 md:mb-24'
        }
      >
        <div className="grid md:pb-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {products.map((product, i) => {
            const author = product
              ? getAuthor(flattenConnection(product.collections))
              : null;
            return (
              <ProductCard
                image={{
                  url: product.featuredImage.url,
                  width: product.featuredImage.width,
                  height: product.featuredImage.height,
                }}
                title={product.title}
                id={product.id}
                handle={product.handle}
                url={`/carti/${product.handle}`}
                author={author ? author.title.replace('Autor', '') : null}
                key={product.id}
                className={'snap-start w-80'}
                customer={customer}
                price={product.variants.nodes[0].price}
                compareAtPrice={product.variants.nodes[0].compareAtPrice}
              />
            );
          })}
        </div>
      </div>

      {nextPage && (
        <div className="flex items-center justify-center mt-6 pb-8 md:pb-28">
          <Button
            disabled={fetcher.state !== 'idle'}
            variant="secondary"
            handleClick={fetchMoreProducts}
            width="full"
            prefetch="intent"
            label={
              fetcher.state !== 'idle' ? (
                <SpinnerIcon size={24} />
              ) : (
                'Mai multe cărți'
              )
            }
          />
        </div>
      )}
    </>
  );
}
