export function AgeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9C8.55 9 9 9.45 9 10C9 10.55 9.45 11 10 11C10.55 11 11 10.55 11 10C11 8.35 9.65 7 8 7C6.35 7 5 8.35 5 10C5 10.55 5.45 11 6 11C6.55 11 7 10.55 7 10C7 9.45 7.45 9 8 9Z"
        fill="white"
      />
      <path
        d="M16 7C14.35 7 13 8.35 13 10C13 10.55 13.45 11 14 11C14.55 11 15 10.55 15 10C15 9.45 15.45 9 16 9C16.55 9 17 9.45 17 10C17 10.55 17.45 11 18 11C18.55 11 19 10.55 19 10C19 8.35 17.65 7 16 7Z"
        fill="white"
      />
      <path
        d="M12 0C5.375 0 0 5.375 0 12C0 18.625 5.375 24 12 24C18.625 24 24 18.625 24 12C24 5.375 18.625 0 12 0ZM12 22C6.475 22 2 17.525 2 12C2 6.475 6.475 2 12 2C17.525 2 22 6.475 22 12C22 17.525 17.525 22 12 22Z"
        fill="white"
      />
      <path
        d="M16 13H8C7.45 13 7 13.45 7 14C7 14.075 7 14.175 7.025 14.25L7.3 15.35C7.95 17.925 10.575 19.5 13.175 18.85C14.9 18.425 16.25 17.05 16.7 15.325L16.975 14.225C17.125 13.7 16.8 13.15 16.25 13C16.175 13 16.075 13 16 13ZM12 17C10.75 17 9.65 16.2 9.275 15H14.7C14.35 16.2 13.25 17 12 17Z"
        fill="white"
      />
    </svg>
  );
}
