export function AuthorIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_556_10675)">
        <path
          d="M0 21C0 21.55 0.45 22 1 22H2.675C5.075 22 7.35 21.05 9.05 19.375L20.675 7.75002C22.45 5.97502 22.45 3.10002 20.675 1.32502C18.9 -0.449976 16.025 -0.449976 14.25 1.32502L2.625 12.95C0.95 14.625 0 16.925 0 19.325V21ZM19.25 2.75002C20.25 3.75002 20.25 5.35002 19.25 6.32502L18 7.57502L14.425 4.00002L15.675 2.75002C16.675 1.80002 18.25 1.80002 19.25 2.75002ZM2 19.325C2 17.475 2.725 15.675 4.05 14.375L13 5.42502L16.575 9.00002L7.625 17.95C6.325 19.275 4.55 20 2.7 20H2V19.325Z"
          fill="white"
        />
        <path
          d="M3 9V6C3 4.35 4.35 3 6 3H9C9.55 3 10 2.55 10 2C10 1.45 9.55 1 9 1H6C3.25 1 1 3.25 1 6V9C1 9.55 1.45 10 2 10C2.55 10 3 9.55 3 9Z"
          fill="white"
        />
        <path
          d="M16 21C18.75 21 21 18.75 21 16V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V16C19 17.65 17.65 19 16 19H13C12.45 19 12 19.45 12 20C12 20.55 12.45 21 13 21H16Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_556_10675">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
