import {useState, useEffect} from 'react';
import {Dialog} from '@headlessui/react';
import {Button, CheckIcon, SpinnerIcon, WarningIcon} from '~/components';
import {v4 as uuidv4} from 'uuid';

export function AddToShelve({
  open,
  setOpen,
  shelve,
  product,
  author,
  customer,
  addToasts,
  addShelve,
}) {
  const [selectedShelve, setSelectedShelve] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);

  const handleShelveSelect = (shelve) => {
    setSelectedShelve(shelve);
    setShouldSave(true);
  };

  const handleSaveShelve = async () => {
    setIsLoading(true);
    if (selectedShelve) {
      addShelve({
        product_image_src: product.featuredImage.url,
        product_image_width: product.featuredImage.width,
        product_image_height: product.featuredImage.height,
        product_image_alt: product.featuredImage.alt,
        author_handle: author?.handle || 'Necunoscut',
        author_id: author?.id || 'Necunoscut',
        author_title: author?.title || 'Necunoscut',
        product_handle: product.handle,
        product_id: product.id,
        title: product.title,
        shelve: selectedShelve,
      });
    } else {
      setIsLoading(false);
      addToasts([
        {
          id: uuidv4(),
          message: 'Va rugăm să selectați un raft',
          type: 'warning',
          icon: <WarningIcon />,
        },
      ]);
    }
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="text-lg text-center font-medium leading-6 text-gray-900 font-serif flex justify-center pt-4 pb-3 px-5 border-b border-gray-200"
          >
            Adaugă în raftul meu
          </Dialog.Title>
          <div className="pt-6 px-6">
            <div>
              <Button
                label={
                  selectedShelve === 'carti-cumparate-citite' ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Cărți cumpărate, citite
                    </span>
                  ) : shelve?.shelve === 'carti-cumparate-citite' &&
                    selectedShelve === null ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Cărți cumpărate, citite
                    </span>
                  ) : (
                    'Cărți cumpărate, citite'
                  )
                }
                variant="sm_secondary"
                className="mb-1 w-full relative"
                handleClick={() => {
                  handleShelveSelect('carti-cumparate-citite');
                }}
                disabled={isLoading}
              />
              <Button
                label={
                  selectedShelve === 'carti-cumparate-necitite' ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Cărți cumpărate, necitite
                    </span>
                  ) : shelve?.shelve === 'carti-cumparate-necitite' &&
                    selectedShelve === null ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Cărți cumpărate, necitite
                    </span>
                  ) : (
                    'Cărți cumpărate, necitite'
                  )
                }
                className="mb-1 w-full relative"
                variant="sm_secondary"
                handleClick={() => {
                  handleShelveSelect('carti-cumparate-necitite');
                }}
                disabled={isLoading}
              />
              <Button
                label={
                  selectedShelve === 'wishlist' ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Wishlist
                    </span>
                  ) : shelve?.shelve === 'wishlist' &&
                    selectedShelve === null ? (
                    <span>
                      <span
                        className="absolute right-4 top-1/2"
                        style={{marginTop: '-11px'}}
                      >
                        <CheckIcon />
                      </span>{' '}
                      Wishlist
                    </span>
                  ) : (
                    'Wishlist'
                  )
                }
                variant="sm_secondary"
                className="w-full relative"
                handleClick={() => {
                  handleShelveSelect('wishlist');
                }}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="py-4 px-5 uppercase font-serif text-xs flex justify-center">
            {shouldSave ? (
              <Button
                handleClick={handleSaveShelve}
                variant="sm_primary"
                label={
                  isLoading ? (
                    <SpinnerIcon size={28} className="mr-0" />
                  ) : (
                    'Salvează'
                  )
                }
                disabled={isLoading}
              />
            ) : (
              <Button
                handleClick={() => {
                  setOpen(false);
                }}
                variant="sm_primary"
                label={
                  isLoading ? (
                    <SpinnerIcon size={28} className="mr-0" />
                  ) : (
                    'Anulează'
                  )
                }
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
