export function MistereDetectiviIcon() {
  return (
    <svg
      width="41"
      height="31"
      viewBox="0 0 41 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.8681 3.27829H35.8588V1.60039C35.8588 0.991664 35.3566 0.5 34.7349 0.5H5.60136C2.78764 0.5 0.5 2.7398 0.5 5.49468V12.1829C0.5 12.7916 1.00216 13.2833 1.62389 13.2833H4.05501L3.33763 26.5738C3.33763 30.6476 6.08758 30.4993 7.13974 30.4993H12.0976C13.1498 30.4993 15.8997 30.5774 15.8997 26.5738L15.5649 20.3148H19.4547C20.8815 20.3148 20.6344 19.0193 20.4352 18.676C19.4468 16.9512 20.0446 15.2577 21.3438 13.2911H34.7429C35.3646 13.2911 35.8668 12.7994 35.8668 12.1907V10.505H39.8761C40.4978 10.505 41 10.0133 41 9.40458V4.37868C40.992 3.76996 40.4899 3.27829 39.8681 3.27829ZM12.0976 28.2985H7.13974C5.69701 28.2985 5.5615 27.1123 5.57745 26.6909L5.72092 24.0921H6.58975C7.21147 24.0921 7.71364 23.6004 7.71364 22.9917C7.71364 22.383 7.21147 21.8913 6.58975 21.8913H5.84048L5.96005 19.6905H9.40346C10.0252 19.6905 10.5274 19.1988 10.5274 18.5901C10.5274 17.9814 10.0252 17.4897 9.40346 17.4897H6.07961L6.31077 13.2911H12.9346L13.6599 26.6987C13.6759 27.1123 13.644 28.2985 12.0976 28.2985ZM17.8207 18.1063H15.4454L15.1823 13.2833H18.7294C17.9243 14.7739 17.4222 16.3971 17.8207 18.1063ZM33.611 11.0825H2.74779V5.49468C2.74779 3.94945 4.0311 2.70078 5.60136 2.70078H33.611V11.0825ZM38.7442 8.30419H35.8588V5.47907H38.7442V8.30419Z"
        fill="#514D71"
      />
    </svg>
  );
}
