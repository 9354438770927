export function FileIcon({size = 20}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.5H20C20.2761 0.5 20.5 0.723858 20.5 1V15C20.5 17.4853 18.4853 19.5 16 19.5H5.5V1C5.5 0.723858 5.72386 0.5 6 0.5Z"
        stroke="#E75113"
      />
      <path
        d="M0.5 15C0.5 17.4853 2.51472 19.5 5 19.5H16C13.5147 19.5 11.5 17.4853 11.5 15H0.5Z"
        fill="white"
        stroke="#E75113"
      />
      <path d="M8 4.5H18M8 7.5H18M8 10.5H18" stroke="#E75113" />
    </svg>
  );
}
