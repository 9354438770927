export function ActivitatiJocuriIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7478 32.7179C18.7478 35.579 22.8714 38.9812 23.6912 39.6289C23.9044 39.7928 24.1585 39.8748 24.4045 39.8748C24.6668 39.8748 24.921 39.7846 25.1341 39.6125C25.9539 38.9402 30.0611 35.4315 30.0611 32.7097C30.0611 30.775 28.4871 29.2009 26.5524 29.2009C25.749 29.2009 24.9947 29.4715 24.3963 29.947C23.7896 29.4715 23.0354 29.2009 22.2402 29.2009C20.3218 29.2091 18.7478 30.7832 18.7478 32.7179ZM23.3469 32.2506C23.5273 32.6769 23.9454 32.9474 24.4045 32.9474C24.4045 32.9474 25.0603 33.013 25.4702 32.2506C25.6916 31.8325 26.0851 31.5292 26.5606 31.5292C27.2164 31.5292 27.7575 32.0621 27.7575 32.7261C27.7575 33.7591 25.9457 35.8004 24.3963 37.2104C22.855 35.8414 21.0597 33.8328 21.0597 32.7261C21.0597 32.0703 21.5925 31.5292 22.2484 31.5292C22.7239 31.521 23.1584 31.8079 23.3469 32.2506Z"
        fill="#514D71"
      />
      <path
        d="M17.2148 24.1513C17.428 24.3153 17.6821 24.3973 17.9362 24.3973C18.1986 24.3973 18.4527 24.3071 18.6659 24.1349C19.4857 23.4627 23.5929 19.9539 23.5929 17.2322C23.5929 15.2974 22.0189 13.7234 20.0841 13.7234C19.2807 13.7234 18.5265 13.9939 17.928 14.4694C17.3214 13.9939 16.5672 13.7234 15.772 13.7234C13.8372 13.7234 12.2714 15.2974 12.2714 17.2322C12.2632 20.0933 16.3868 23.4955 17.2148 24.1513ZM15.772 16.0434C16.2474 16.0434 16.6819 16.3304 16.8705 16.7649C17.0509 17.1912 17.469 17.4617 17.928 17.4617C17.928 17.4617 18.5839 17.5273 18.9938 16.7649C19.2151 16.3468 19.6087 16.0434 20.0841 16.0434C20.74 16.0434 21.2811 16.5763 21.2811 17.2404C21.2811 18.2733 19.4693 20.3146 17.9198 21.7247C16.3786 20.3556 14.5832 18.3471 14.5832 17.2404C14.5832 16.5763 15.1161 16.0434 15.772 16.0434Z"
        fill="#514D71"
      />
      <path
        d="M39.6447 3H16.4031C15.7637 3 15.2472 3.51648 15.2472 4.15593V8.96H8.65593C8.01648 8.96 7.5 9.47648 7.5 10.1159V43.4903C7.5 44.1298 8.01648 44.6462 8.65593 44.6462H33.6847C34.3241 44.6462 34.8406 44.1298 34.8406 43.4903V37.4975H39.6447C40.2841 37.4975 40.8006 36.981 40.8006 36.3416V4.15593C40.8006 3.51648 40.2841 3 39.6447 3ZM32.5287 42.3344H9.81186V33.8576H11.5499C12.1893 33.8576 12.7058 33.3411 12.7058 32.7016C12.7058 32.0622 12.1893 31.5457 11.5499 31.5457H9.81186V29.2338H14.4356C15.075 29.2338 15.5915 28.7174 15.5915 28.0779C15.5915 27.4385 15.075 26.922 14.4356 26.922H9.81186V11.2719H32.5287V42.3344ZM38.4887 35.1775H34.8406V10.1159C34.8406 9.47648 34.3241 8.96 33.6847 8.96H17.559V5.31186H38.4887V35.1775Z"
        fill="#514D71"
      />
    </svg>
  );
}
