import {Image} from '@shopify/hydrogen';
import {BooksInShelveIcon, CommentsIcon, LikeIcon} from '~/components';

export function ListCard({list, layout}) {
  return (
    <div
      className={
        layout && layout === 'grid'
          ? 'bg-white rounded-3xl flex-grow lg:flex-grow-0 flex-shrink-0 basis-[94%] sm:basis-[94%] lg:basis-[100%] xl:basis-[100%] h-full relative transition-transform font-serif'
          : 'bg-white rounded-3xl flex-grow lg:flex-grow-0 flex-shrink-0 basis-[94%] sm:basis-[94%] lg:basis-[100%] xl:basis-[100%] h-full relative transition-transform font-serif'
      }
    >
      <div className="flex flex-wrap pt-4 pl-4 pr-4">
        {list.labels.map((label) => {
          return (
            <div
              key={label.id}
              className="rounded-md uppercase text-xs border border-line flex items-center justify-center px-2 mr-2 mb-2 bg-white h-5"
            >
              {label.title}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-end py-10 px-5">
        {list &&
          list.products &&
          list.products.map((product, index) => {
            return (
              <div className="p-1" key={product.id}>
                <a href={`/liste/${list.id}`}>
                  {product.image ? (
                    <Image
                      data={{
                        width: product.image?.width,
                        height: product.image?.height,
                        url: product.image?.url,
                        altText: product.title,
                      }}
                      className="w-24"
                    />
                  ) : null}
                </a>
              </div>
            );
          })}
      </div>
      <div className="p-4 text-xl">{list.title}</div>
      <div className="flex justify-between  items-center p-4 border-t border-line">
        <div className="flex">
          <p className="font-serif mt-5 flex items-center justify-center mb-0 pl-3">
            <span className="mr-3 text-primary">
              <LikeIcon width={15} height={20} />
            </span>
            {list.count_likes || 0}
          </p>
          <p className="font-serif mt-5 flex items-center justify-center mb-0 pl-5">
            <span className="mr-3">
              <CommentsIcon />
            </span>
            {list.count_comments || 0}
          </p>
          <p className="font-serif mt-5 flex items-center justify-center mb-0 pl-5">
            <span className="mr-3">
              <BooksInShelveIcon />
            </span>
            {list.count_products || 0}
            <span className="opacity-70 ml-1">
              {list.count_products === 1 ? 'carte' : 'cărți'}
            </span>
          </p>
        </div>
        <a
          href={`/liste/${list.id}`}
          className="block font-serif m-0 items-center justify-center text-lg text-center rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors px-6 py-2 text-white bg-primary hover:bg-gray-800 active:bg-gray-700"
        >
          Vezi
        </a>
      </div>
    </div>
  );
}
