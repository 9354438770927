export function PremiateIcon() {
  return (
    <svg
      width="46"
      height="34"
      viewBox="0 0 46 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3311 9.57794H24.2443V1.74877C24.2443 1.05796 23.6864 0.5 22.9956 0.5C22.3048 0.5 21.7468 1.05796 21.7468 1.74877V9.57794H12.6512C5.95562 9.57794 0.5 15.0336 0.5 21.7379C0.5 28.4423 5.95562 33.8891 12.6512 33.8891C16.9377 33.8891 20.8169 31.6573 22.9956 28.1324C25.1743 31.6573 29.0534 33.8891 33.34 33.8891C40.0444 33.8891 45.5 28.4335 45.5 21.7379C45.5 15.0424 40.0355 9.57794 33.3311 9.57794ZM33.3311 31.3916C29.1686 31.3916 25.4931 28.7435 24.1735 24.8023C24.0052 24.2886 23.527 23.9521 22.9867 23.9521C22.4465 23.9521 21.9682 24.2975 21.7999 24.8023C20.4892 28.7435 16.8049 31.3916 12.6423 31.3916C7.31952 31.3916 2.98868 27.0607 2.98868 21.7379C2.98868 16.4152 7.31952 12.0843 12.6423 12.0843H33.3223C38.6451 12.0843 42.9759 16.4152 42.9759 21.7379C42.9759 27.0607 38.6628 31.3916 33.3311 31.3916Z"
        fill="#514D71"
      />
      <path
        d="M12.6511 15.0156C8.94908 15.0156 5.93787 18.0268 5.93787 21.7289C5.93787 25.4309 8.94908 28.4421 12.6511 28.4421C16.3531 28.4421 19.3644 25.4309 19.3644 21.7289C19.3644 18.0268 16.3531 15.0156 12.6511 15.0156ZM12.6511 25.9446C10.3307 25.9446 8.43541 24.0581 8.43541 21.7289C8.43541 19.4085 10.3218 17.5132 12.6511 17.5132C14.9715 17.5132 16.8668 19.4085 16.8668 21.7289C16.8668 24.0581 14.9715 25.9446 12.6511 25.9446Z"
        fill="#514D71"
      />
      <path
        d="M12.6512 22.977C13.3409 22.977 13.9 22.4179 13.9 21.7283C13.9 21.0386 13.3409 20.4795 12.6512 20.4795C11.9616 20.4795 11.4025 21.0386 11.4025 21.7283C11.4025 22.4179 11.9616 22.977 12.6512 22.977Z"
        fill="#514D71"
      />
      <path
        d="M36.7586 20.4889H34.5888V18.3191C34.5888 17.6283 34.0308 17.0703 33.34 17.0703C32.6492 17.0703 32.0912 17.6283 32.0912 18.3191V20.4889H29.9214C29.2306 20.4889 28.6726 21.0469 28.6726 21.7377C28.6726 22.4285 29.2306 22.9865 29.9214 22.9865H32.0912V25.1563C32.0912 25.8471 32.6492 26.4051 33.34 26.4051C34.0308 26.4051 34.5888 25.8471 34.5888 25.1563V22.9865H36.7586C37.4494 22.9865 38.0074 22.4285 38.0074 21.7377C38.0074 21.0469 37.4406 20.4889 36.7586 20.4889Z"
        fill="#514D71"
      />
    </svg>
  );
}
