export function PersonalInfoIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.375 0 0 5.375 0 12C0 18.625 5.375 24 12 24C18.625 24 24 18.625 24 12C24 5.375 18.625 0 12 0ZM7 20.65V20C7 17.25 9.25 15 12 15C14.75 15 17 17.25 17 20V20.65C13.9 22.45 10.1 22.45 7 20.65ZM9 10C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10C15 11.65 13.65 13 12 13C10.35 13 9 11.65 9 10ZM18.95 19.175C18.675 16.875 17.275 14.85 15.225 13.8C17.325 12.025 17.6 8.875 15.825 6.75C14.05 4.625 10.9 4.375 8.775 6.15C6.65 7.925 6.4 11.075 8.175 13.2C8.35 13.425 8.55 13.625 8.775 13.8C6.725 14.875 5.325 16.875 5.05 19.175C1.075 15.35 0.975 9 4.8 5.025C8.625 1.05 14.975 0.95 18.95 4.775C22.925 8.6 23.025 14.95 19.2 18.925C19.1 19.025 19.025 19.1 18.95 19.175Z"
        fill="#E75113"
      />
    </svg>
  );
}
