export function SelectieIcon() {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.092 19.9014C19.5868 17.3962 16.1225 16.8092 14.3448 18.5785C12.5672 20.3479 13.1625 23.8205 15.6677 26.3257C18.1729 28.8309 21.6373 29.4179 23.4149 27.6486C25.1925 25.871 24.5972 22.4066 22.092 19.9014ZM17.3213 24.6721C15.7256 23.0764 15.1386 21.0838 15.9984 20.2239C16.8583 19.364 18.8509 19.951 20.4467 21.5468C22.0424 23.1425 22.6294 25.1351 21.7695 25.995C20.9014 26.8631 18.9088 26.2678 17.3213 24.6721Z"
        fill="#514D71"
      />
      <path
        d="M42.0014 16.272C42.0262 15.7181 41.6541 15.2303 41.1167 15.0897C41.0588 15.0732 38.7521 14.3621 35.3787 14.9244C34.6842 13.031 33.5763 11.2947 32.1376 9.85605C30.699 8.41741 28.9627 7.30949 27.0693 6.61497C27.5985 3.72116 26.937 1.01751 26.904 0.885222C26.7717 0.347799 26.2756 -0.0159947 25.7216 0.000541363C25.4819 0.0088094 19.7604 0.281654 16.9492 4.03535C16.0397 5.23421 15.337 6.54883 14.8988 7.85518C13.9893 8.41741 13.1459 9.08712 12.377 9.85605C9.7395 12.4936 8.28433 16.0075 8.28433 19.7364C8.28433 22.2664 8.95404 24.6972 10.2108 26.8221L1.71123 33.8086C0.710802 34.6271 -1.39755 37.8268 1.38051 40.6049C4.19165 43.416 7.35831 41.2746 8.17684 40.2742L15.1633 31.7829C17.2882 33.0314 19.7108 33.7011 22.2408 33.7011C25.9779 33.7011 29.4836 32.2459 32.1294 29.6084C32.8983 28.8395 33.568 27.9961 34.122 27.0949C35.4118 26.665 36.7264 25.9539 37.9501 25.0444C41.7203 22.225 41.9931 16.5118 42.0014 16.272ZM6.38268 38.7942C5.96928 39.2903 4.40662 40.332 3.03412 38.9596C1.65336 37.5788 2.69513 36.0244 3.19948 35.611L11.5585 28.732C12.3109 29.6911 12.964 30.1789 13.27 30.4435L6.38268 38.7942ZM36.561 23.1841C35.4283 24.0357 34.2046 24.6641 33.0388 25.0031C32.7495 25.0858 32.5014 25.2842 32.3443 25.5488C31.8482 26.4252 31.2199 27.2355 30.4923 27.9713C28.293 30.1706 25.3661 31.386 22.2491 31.386C19.1403 31.386 16.2134 30.1789 14.0141 27.9796C11.8148 25.7803 10.6076 22.8617 10.6076 19.7446C10.6076 16.6358 11.823 13.709 14.0223 11.5097C14.7582 10.7738 15.5767 10.1537 16.4532 9.64935C16.7177 9.50052 16.9079 9.25248 16.9906 8.9631C17.3378 7.78077 17.9662 6.56537 18.8095 5.44091C20.3557 3.38217 23.2826 2.67939 24.8204 2.43961C24.9692 3.53099 25.0767 5.34996 24.5724 7.06145C24.4814 7.3591 24.4732 8.26031 25.3826 8.51662C27.3008 9.04578 29.0702 10.0793 30.5006 11.5014C31.9227 12.9235 32.9396 14.6929 33.4853 16.611C33.783 17.6528 34.6346 17.5123 34.9405 17.4213C36.652 16.8756 39.0828 17.1154 39.5706 17.1733C39.3226 18.7194 38.6198 21.638 36.561 23.1841Z"
        fill="#514D71"
      />
      <path
        d="M20.3805 15.098C21.0088 15.7264 22.0258 15.7264 22.6624 15.098C23.2908 14.4696 23.2908 13.4527 22.6624 12.8243C22.0341 12.1959 21.0171 12.1959 20.3805 12.8243C19.7521 13.4527 19.7521 14.4696 20.3805 15.098Z"
        fill="#514D71"
      />
      <path
        d="M26.8951 21.6129C27.5235 22.2413 28.5404 22.2413 29.1771 21.6129C29.8055 20.9845 29.8055 19.9676 29.1771 19.3392C28.5487 18.7108 27.5317 18.7108 26.8951 19.3392C26.2667 19.9676 26.2667 20.9845 26.8951 21.6129Z"
        fill="#514D71"
      />
    </svg>
  );
}
