import {StarFullIcon, StarIcon} from '~/components';
import {AddReview} from '~/components';
import {useState, useEffect} from 'react';
import {useFetcher, useNavigate, useOutletContext} from '@remix-run/react';

const stars = [1, 2, 3, 4, 5];
export function NotaTa({
  nota,
  product = null,
  firebaseProduct = null,
  author = null,
}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [selectedStars, setSelectedStars] = useState(0);
  const {customer} = useOutletContext();
  const navigate = useNavigate();

  const fetcher = useFetcher();

  const addReview = ({
    review,
    title,
    stars,
    product_handle,
    product_id,
    product_title,
    product_image_src,
    product_image_width,
    product_image_height,
    product_image_alt,
    product_author,
    shelve,
  }) => {
    setError(null);
    if (customer) {
      fetcher.submit(
        {
          review,
          title,
          stars,
          product_handle,
          product_id,
          product_title,
          product_image_src,
          product_image_width,
          product_image_height,
          product_image_alt: product_image_alt || title,
          product_author,
          shelve,
        },
        {
          method: 'post',
          action: `/api/reviews/add`,
        },
      );
    }
  };

  useEffect(() => {
    if (
      fetcher.type == 'actionReload' &&
      fetcher.state == 'loading' &&
      !fetcher.data?.error
    ) {
      window.location.reload();
    }

    if (
      fetcher.state !== 'idle' &&
      fetcher.state !== 'submitting' &&
      fetcher.data?.error
    ) {
      setError(fetcher.data.error);
    }
  }, [fetcher.state]);

  return (
    <>
      <div className="font-serif flex items-center">
        <div className="flex items-center gap-1">
          {stars.map((star, index) => {
            if (!customer) {
              return (
                <a
                  href="/profil/login"
                  style={{padding: '1px'}}
                  key={`nota-${index}`}
                >
                  {nota && star <= nota ? <StarFullIcon /> : <StarIcon />}
                </a>
              );
            }
            return (
              <button
                style={{padding: '1px'}}
                key={`nota-${index}`}
                onClick={
                  customer
                    ? () => {
                        setSelectedStars(index + 1);
                        setOpen(true);
                      }
                    : () => {
                        navigate('/profil/login');
                      }
                }
              >
                {nota && star <= nota ? <StarFullIcon /> : <StarIcon />}
              </button>
            );
          })}
        </div>
        <span className="ml-3 border border-line rounded-lg px-3 py-1 mr-1 whitespace-nowrap flex items-center text-black">
          {nota}/5
        </span>
      </div>
      {customer && !firebaseProduct?.userReview && product ? (
        <AddReview
          customer={customer}
          id={product.id}
          handle={product.handle}
          title={product.title}
          stars={selectedStars}
          image={{
            url: product.featuredImage.url,
            width: product.featuredImage.width,
            height: product.featuredImage.height,
            altText: product.title,
          }}
          author={author?.title || 'Necunoscut'}
          open={open}
          setOpen={setOpen}
          addReview={addReview}
          error={error}
        />
      ) : null}
    </>
  );
}
