/**
 * A shared component that specifies the icon to represent a cart
 */
export function CloseIcon({stroke, size = 12}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L11 11M1 11L11 1"
        style={{stroke: stroke || '#E75113'}}
        strokeWidth="1.5"
      />
    </svg>
  );
}
