export function ConnectIcon() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0502 13C20.2502 13 22.0502 11.225 22.0752 9.025C22.0752 6.825 20.3002 5.025 18.1002 5C17.1002 5 16.1252 5.375 15.3752 6.05L10.9502 4.275C11.0252 4.025 11.0502 3.75 11.0502 3.5C11.0502 1.575 9.47517 0 7.55017 0C5.62517 0 4.05017 1.575 4.05017 3.5C4.05017 5.425 5.62517 7 7.55017 7C8.45017 7 9.30017 6.65 9.95017 6.05L14.2502 7.775C14.0252 8.425 13.9752 9.1 14.1252 9.775L7.37517 12.775C6.15017 10.925 3.65017 10.425 1.80017 11.65C-0.0498298 12.875 -0.54983 15.375 0.67517 17.225C1.90017 19.075 4.40017 19.575 6.25017 18.35C6.42517 18.225 6.57517 18.1 6.72517 17.975L11.1502 19.75C11.0752 20 11.0502 20.275 11.0502 20.525C11.0502 22.45 12.6252 24.025 14.5502 24.025C16.4752 24.025 18.0502 22.45 18.0502 20.525C18.0502 18.6 16.4752 17.025 14.5502 17.025C13.6502 17.025 12.8002 17.375 12.1502 17.975L7.82517 16.25C7.97517 15.85 8.02517 15.425 8.05017 15C8.05017 14.875 8.02517 14.775 8.02517 14.675L15.0252 11.575C15.7502 12.475 16.8752 13 18.0502 13ZM4.05017 17C2.95017 17 2.05017 16.1 2.05017 15C2.05017 13.9 2.95017 13 4.05017 13C5.15017 13 6.05017 13.9 6.05017 15C6.05017 16.1 5.15017 17 4.05017 17ZM14.5502 19C15.3752 19 16.0502 19.675 16.0502 20.5C16.0502 21.325 15.3752 22 14.5502 22C13.7252 22 13.0502 21.325 13.0502 20.5C13.0502 20.35 13.0752 20.175 13.1252 20.025C13.3252 19.425 13.9002 19 14.5502 19ZM18.0502 7C19.1502 7 20.0502 7.9 20.0502 9C20.0502 10.1 19.1502 11 18.0502 11C16.9502 11 16.0502 10.1 16.0502 9C16.0502 7.9 16.9252 7 18.0502 7ZM7.55017 5C6.72517 5 6.05017 4.325 6.05017 3.5C6.05017 2.675 6.72517 2 7.55017 2C8.37517 2 9.05017 2.675 9.05017 3.5C9.05017 3.65 9.02517 3.825 8.97517 3.975C8.75017 4.6 8.17517 5 7.55017 5Z"
        fill="white"
      />
    </svg>
  );
}
