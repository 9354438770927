export function LogIntoAccountIcon() {
  return (
    <svg
      width="46"
      height="51"
      viewBox="0 0 46 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.327402 41.9297L22.809 50.9457C22.9181 51 23.0819 51 23.191 50.9457L45.6726 41.9297C45.8909 41.8211 46 41.6581 46 41.4409V4.18211C46 3.85623 45.7817 3.63898 45.4543 3.63898C45.3998 3.63898 45.2906 3.63898 45.2361 3.69329L41.2527 5.32268V0.543131C41.2527 0.217252 40.9798 0 40.707 0C40.5979 0 40.4887 0.0543131 40.3796 0.108626L23.0273 12.5463L5.6204 0.108626C5.34757 -0.0543131 5.02017 0 4.85646 0.217252C4.8019 0.325879 4.74733 0.434505 4.74733 0.543131V5.26837L0.763938 3.63898C0.491103 3.53035 0.163701 3.63898 0.054567 3.96486C0 4.01917 0 4.07348 0 4.1278V41.4409C0 41.6581 0.109134 41.8754 0.327402 41.9297ZM44.9087 41.0607L23.573 49.6422V13.5783L32.7402 9.88498V30.0351C32.7402 30.361 32.9585 30.5783 33.2859 30.5783C33.5042 30.5783 33.6679 30.4696 33.777 30.3067L36.9964 24.115L40.0522 27.5367C40.2705 27.754 40.5979 27.754 40.8161 27.5911C40.9253 27.4824 40.9798 27.3738 40.9798 27.2109V6.57189L44.9632 4.99681V41.0607H44.9087ZM39.8885 7.00639V25.7444L37.2693 22.8115C37.051 22.5942 36.7236 22.5942 36.5053 22.7572C36.4508 22.8115 36.3962 22.8658 36.3962 22.9201L33.8316 27.8083V9.45048L39.8885 7.00639ZM40.1613 5.59425C40.0522 5.64856 39.9976 5.70288 39.9431 5.8115L33.013 8.58147L27.0107 11.0256L40.1613 1.57508V5.59425ZM5.83867 1.57508L18.9893 10.9712L5.83867 5.70288V1.57508ZM1.09134 4.99681L22.4816 13.5783V49.6965L1.09134 41.0607V4.99681Z"
        fill="black"
      />
    </svg>
  );
}
