import {NotificationCard} from './NotificationCard';
import {Button, SpinnerIcon} from '~/components';

export function NotificationGrid({
  notifications,
  isLoading,
  nextPage,
  fetchMoreNotifications,
}) {
  return (
    <div className="container mx-auto px-0 md:px-4">
      <div className="px-4 md:px-0">
        <div className={nextPage ? 'mb-12 lg:mb-16' : 'mb-12 lg:mb-24'}>
          <div className="relative w-full h-full z-10 flex-wrap transition-transform grid grid-cols-1 gap-3">
            <div className="bg-white p-5 rounded-lg">
              {notifications &&
                notifications.map((notification, index) => {
                  return (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                      type={notification.type}
                      index={index}
                      length={notifications.length}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {nextPage && (
          <div className="flex items-center justify-center  pb-8 md:pb-28">
            <Button
              disabled={isLoading}
              variant="secondary"
              handleClick={fetchMoreNotifications}
              width="full"
              prefetch="intent"
              label={
                isLoading ? <SpinnerIcon size={24} /> : 'Mai multe notificări'
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
