import {ProductSwimlane, Link} from '~/components';

export function ProductsList({
  label,
  buttonLabel,
  title,
  subtitle,
  handle,
  className,
  products,
  customer,
}) {
  if (!products) {
    return (
      <div className="pt-10 pb-10 xl:pt-20 xl:pb-20">
        <div className="container mx-auto px-4">
          <div className="text-primary uppercase mb-3 font-serif text-xl lg:text-2xl">
            Colectia este goala
          </div>
          <div className="text-black font-sans font-bold text-3xl lg:text-4xl">
            {title || 'Recomandari'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`pt-10 pb-10 xl:pt-20 xl:pb-20 ${className}`}>
      <div className="container mx-auto px-4 pr-0 md:pr-4 md:px-4">
        <div className="text-primary uppercase mb-3 font-sans tracking-wider text-lg lg:text-2xl">
          {label}
        </div>
        <div
          className={
            subtitle
              ? 'text-black font-sans font-bold text-2xl lg:text-3xl mb-3'
              : 'text-black font-sans font-bold mb-6 text-2xl lg:text-3xl lg:mb-10'
          }
        >
          {title || 'Recomandări'}
        </div>
        {subtitle ? (
          <div className="text-black font-serif mb-6 text-lg lg:mb-8">
            {subtitle}
          </div>
        ) : null}

        <ProductSwimlane
          products={products}
          count={products.length}
          customer={customer}
        />

        {buttonLabel && handle ? (
          <div className="border-b border-solid border-line pt-5 pb-8">
            <Link
              className="block font-serif m-0 w-full items-center justify-center text-lg text-center px-6 py-4 rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors bg-none text-primary"
              to={`/categorii/${handle}`}
            >
              {buttonLabel}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
