import {ListCard} from './ListCard';
import {Button, SpinnerIcon} from '~/components';

export function ListGrid({lists, isLoading, nextPage, fetchMoreLists}) {
  return (
    <div className="container mx-auto px-0 md:px-4">
      <div className="px-4 md:px-0">
        <div className={nextPage ? 'mb-12 lg:mb-16' : 'mb-12 lg:mb-24'}>
          <div className="relative w-full h-full z-10 flex-wrap transition-transform grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
            {lists &&
              lists.map((list) => {
                return <ListCard key={list.id} list={list} />;
              })}
          </div>
        </div>
        {nextPage && (
          <div className="flex items-center justify-center  pb-8 md:pb-28">
            <Button
              disabled={isLoading}
              variant="secondary"
              handleClick={fetchMoreLists}
              width="full"
              prefetch="intent"
              label={isLoading ? <SpinnerIcon size={24} /> : 'Mai multe liste'}
            />
          </div>
        )}
      </div>
    </div>
  );
}
