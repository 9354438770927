import {Image} from '@shopify/hydrogen';
import {Button} from './Button';
import {CollectionActions} from './CollectionActions';

export function ProductAuthor({product, author, customer}) {
  if (!author) return null;
  return (
    <div
      className="mx-4 md:mx-auto mb-0 pb-4 md:pb-12 lg:pb-20 xl:pb-28"
      style={{maxWidth: '500px'}}
    >
      <div className="bg-white rounded-3xl overflow-hidden">
        <div className="px-7 pt-7 pb-4 font-serif">
          <div className="text-center text-4xl font-sans font-bold">Autor</div>
          {author.image ? (
            <div className="w-28 h-28 flex justify-center items-center overflow-hidden rounded-2xl mt-9 mb-9 mx-auto">
              <Image
                data={{
                  url: author.image.url,
                  width: author.image.width,
                  height: author.image.height,
                  altText: author.title,
                }}
                className="object-cover"
              />
            </div>
          ) : null}
          <div className="text-center text-3xl">
            {author?.title.replace('Autor', '')}
          </div>
          <div className="text-center text-lg leading-8">
            {author.description}
          </div>
          <div className="flex mt-8 items-end justify-center">
            {author.products?.nodes?.map((product) => {
              return (
                <div
                  className="p-1 w-full flex-grow"
                  key={product.id}
                  style={{maxWidth: '25%'}}
                >
                  <Image
                    data={{
                      url: product.featuredImage.url,
                      width: product.featuredImage.width,
                      height: product.featuredImage.height,
                    }}
                    className="w-full"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="border-t border-gray-200 px-0 flex items-center justify-center">
          <CollectionActions handle={author.handle} collection={author} />
        </div>

        <Button
          url={`/categorii/${author.handle}`}
          variant="naked"
          label="Pagina autorului"
        />
      </div>
    </div>
  );
}
