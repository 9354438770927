export function CartiCautateIcon() {
  return (
    <svg
      width="42"
      height="47"
      viewBox="0 0 42 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5862 4.58925C22.983 2.80726 23.0379 1.39995 23.0379 1.3634C23.0835 0.650607 22.5444 0.0474737 21.8316 0.00178182C21.1188 -0.0347717 20.5065 0.495254 20.4608 1.20805C20.4517 1.30857 20.3877 2.63364 20.8446 4.4339C19.5287 4.44303 18.2493 4.57097 17.0065 4.81771C15.8003 3.03572 15.4256 1.63755 15.4164 1.61014C15.2428 0.924758 14.5483 0.495254 13.8629 0.668884C13.1684 0.842513 12.748 1.53703 12.9125 2.23155C12.9399 2.33207 13.2872 3.69369 14.3747 5.50309C6.04047 8.27202 0 16.1493 0 25.4156C0 36.9939 9.42167 46.4156 21 46.4156C32.5783 46.4156 42 36.9939 42 25.4156C42 14.7146 33.9491 5.85948 23.5862 4.58925ZM21 43.8386C10.8473 43.8386 2.58616 35.5775 2.58616 25.4248C2.58616 17.0174 8.25196 9.90779 15.9739 7.71458C16.577 8.42737 17.2715 9.17672 18.1305 9.87123C18.8616 10.4561 19.6932 10.0174 19.9491 9.71588C20.406 9.16758 20.3329 8.35426 19.7937 7.89734C19.4922 7.64147 19.218 7.38559 18.953 7.12972C20.5248 6.89212 21.5483 7.02006 21.8133 7.0292C22.2154 7.82424 22.718 8.64669 23.3851 9.44173C23.9791 10.1454 24.9569 9.80726 25.2037 9.60622C25.752 9.1493 25.8251 8.33599 25.3681 7.78768C25.2768 7.68716 25.2037 7.5775 25.1214 7.46784C33.3003 9.34121 39.423 16.6793 39.423 25.4156C39.4138 35.5775 31.1527 43.8386 21 43.8386Z"
        fill="#514D71"
      />
      <path
        d="M11.6608 17.7398C11.4323 17.0453 10.7195 16.7072 10.0159 16.9539C9.34877 17.1915 8.99238 17.9226 9.22998 18.5988C9.76 20.1158 11.1947 21.1301 12.7939 21.1301C14.3932 21.1301 15.8279 20.1066 16.3579 18.5988C16.5955 17.9226 16.2391 17.1915 15.572 16.9539C14.8958 16.7163 14.1647 17.0727 13.9271 17.7398C13.7535 18.2241 13.3057 18.5531 12.7939 18.5531C12.2913 18.5531 11.8253 18.2333 11.6608 17.7398Z"
        fill="#514D71"
      />
      <path
        d="M29.2061 21.1206C30.8053 21.1206 32.24 20.0971 32.77 18.5893C33.0076 17.913 32.6512 17.182 31.9841 16.9444C31.3079 16.7068 30.5768 17.0632 30.3392 17.7303C30.1656 18.2146 29.7178 18.5436 29.2061 18.5436C28.7034 18.5436 28.2374 18.2237 28.0729 17.7303C27.8444 17.0358 27.1316 16.6976 26.428 16.9444C25.7609 17.182 25.4045 17.913 25.6421 18.5893C26.1721 20.0971 27.6068 21.1206 29.2061 21.1206Z"
        fill="#514D71"
      />
      <path
        d="M21 37.9714C25.4778 37.9714 29.252 34.3343 29.252 29.8565C29.252 29.1437 28.8042 28.3944 27.7441 28.5589C23.2572 29.2808 18.7154 29.3722 14.2467 28.5589C13.2963 28.3852 12.748 29.226 12.748 30.0484C12.9308 34.444 16.5953 37.9714 21 37.9714ZM26.4282 31.3644C25.7154 33.6855 23.5405 35.3944 21 35.3944C18.4595 35.3944 16.2846 33.6946 15.5718 31.3644C19.1906 31.8304 22.8094 31.8304 26.4282 31.3644Z"
        fill="#514D71"
      />
    </svg>
  );
}
