/**
 * A shared component that specifies the icon to render when opened
 */
export function TwitterIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55016 19.7497C16.6045 19.7497 21.5583 12.2465 21.5583 5.74162C21.5583 5.53068 21.5536 5.31505 21.5442 5.10412C22.5079 4.40722 23.3395 3.54401 24 2.55505C23.1025 2.95436 22.1496 3.21514 21.1739 3.32849C22.2013 2.71266 22.9705 1.74523 23.3391 0.605523C22.3726 1.17831 21.3156 1.58237 20.2134 1.80037C19.4708 1.01132 18.489 0.488872 17.4197 0.313807C16.3504 0.138742 15.2532 0.320806 14.2977 0.831852C13.3423 1.3429 12.5818 2.15446 12.1338 3.14107C11.6859 4.12767 11.5754 5.23437 11.8195 6.29005C9.86249 6.19185 7.94794 5.68346 6.19998 4.79785C4.45203 3.91225 2.90969 2.66919 1.67297 1.14927C1.0444 2.233 0.852057 3.5154 1.13503 4.73585C1.418 5.95629 2.15506 7.0232 3.19641 7.71974C2.41463 7.69492 1.64998 7.48444 0.965625 7.10568V7.16662C0.964925 8.3039 1.3581 9.40634 2.07831 10.2865C2.79852 11.1667 3.80132 11.7703 4.91625 11.9947C4.19206 12.1929 3.43198 12.2218 2.69484 12.0791C3.00945 13.0572 3.62157 13.9126 4.44577 14.5261C5.26997 15.1395 6.26512 15.4804 7.29234 15.501C5.54842 16.8709 3.39417 17.6139 1.17656 17.6104C0.783287 17.6098 0.390399 17.5857 0 17.5382C2.25286 18.9835 4.87353 19.7511 7.55016 19.7497Z"
        fill="#E75113"
      />
    </svg>
  );
}
