import {
  Button,
  SpinnerIcon,
  QuestionCard,
  Toast,
  CheckIcon,
} from '~/components';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {useEffect, useState, useCallback} from 'react';
import {v4 as uuidv4} from 'uuid';
import remove from 'lodash/remove';

export function QuestionGrid({url, data, product, ...props}) {
  const {customer} = useOutletContext();
  const [initialQuestions, setInitialQuestions] = useState(data.items || []);

  const [nextPage, setNextPage] = useState(data.pageInfo.hasNextPage);
  const [endCursor, setEndCursor] = useState(data.pageInfo.endCursor);
  const [questions, setQuestions] = useState(initialQuestions);
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [error, setError] = useState(null);

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  // props have changes, reset component state
  const questionProps = questions || [];
  if (initialQuestions !== questionProps) {
    setInitialQuestions(questionProps);
    setQuestions(questionProps);
  }

  const fetcher = useFetcher();

  function fetchMoreQuestions() {
    fetcher.load(`${url}?index&lastQuestion=${endCursor}`);
  }

  useEffect(() => {
    if (!fetcher.data) return;

    const {questions} = fetcher.data;

    if (questions) {
      setQuestions((prev) => [...prev, ...questions.items]);
      setNextPage(questions.pageInfo.hasNextPage);
      setEndCursor(questions.pageInfo.endCursor);
    }
  }, [fetcher.data]);

  const handleQuestionSubmit = async () => {
    setIsLoading(true);
    // setQuestions([
    //   {
    //     product_handle: product.handle,
    //     product_id: product.id,
    //     question,
    //   },
    //   ...questions,
    // ]);

    if (question) {
      fetcher.submit(
        {
          product_handle: product.handle,
          product_id: product.id,
          product_title: product.title,
          product_image_src: product.featuredImage?.url || '',
          product_image_width: product.featuredImage?.width || 0,
          product_image_height: product.featuredImage?.height || 0,
          product_image_alt: product.featuredImage?.alt || product.title,
          question,
        },
        {method: 'post', action: `/api/questions/add`},
      );
    }
  };

  useEffect(() => {
    if (
      fetcher.type == 'actionReload' &&
      fetcher.state == 'loading' &&
      !fetcher.data?.error
    ) {
      window.location.reload();
    }

    if (
      fetcher.state !== 'idle' &&
      fetcher.state !== 'submitting' &&
      fetcher.data?.error
    ) {
      setError(fetcher.data.error);
    }
  }, [fetcher.state]);

  if (!initialQuestions) {
    return null;
  }

  return (
    <>
      <div className="bg-white p-4 rounded-2xl mt-7 mb-7">
        <div className="relative">
          <input
            type="text"
            placeholder="Întreabă orice despre această carte"
            value={question}
            onChange={(event) => {
              setQuestion(event.target.value);
            }}
            className="font-serif w-full h-14 border border-gray-300 rounded-lg px-3"
          />
          {error?.message ? (
            <p className="text-primary mt-1">{error.message}</p>
          ) : null}
          {question ? (
            customer ? (
              <Button
                label={isLoading ? <SpinnerIcon size="34" /> : 'Adaugă'}
                variant="sm_primary"
                handleClick={handleQuestionSubmit}
                className="absolute h-12 top-1 right-1"
                disabled={isLoading}
              />
            ) : (
              <Button
                label={isLoading ? <SpinnerIcon size="34" /> : 'Adaugă'}
                variant="sm_primary"
                url="/profil/login"
                className="absolute h-12 top-1 right-1"
                disabled={isLoading}
              />
            )
          ) : null}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8">
        <div className="col-span-1 text-left md:text-center">
          {questions.map((question, i) => {
            return (
              <QuestionCard
                key={question.id}
                question={question}
                isLast={i === questions.length - 1}
                product={product}
              />
            );
          })}
        </div>
      </div>

      {nextPage && (
        <div className="flex items-center justify-center mt-6 pb-8 md:pb-28">
          <Button
            disabled={fetcher.state !== 'idle'}
            variant="secondary"
            handleClick={fetchMoreQuestions}
            width="full"
            prefetch="intent"
            label={
              fetcher.state !== 'idle' ? (
                <SpinnerIcon size={24} />
              ) : (
                'Mai multe întrebări'
              )
            }
          />
        </div>
      )}
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
