import {useState, useEffect, useCallback} from 'react';
import {Dialog} from '@headlessui/react';
import {AnswerCard, Button, CloseIcon, SpinnerIcon, Toast} from '~/components';
import {v4 as uuidv4} from 'uuid';
import remove from 'lodash/remove';
import {useFetcher, useOutletContext} from '@remix-run/react';

export function QuestionAnswers({open, closeModal, question, product}) {
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState('');
  const [answers, setAnswers] = useState([]);
  const [nextPage, setNextPage] = useState(false);
  const [endCursor, setEndCursor] = useState('');
  const [toasts, setToasts] = useState([]);
  const fetcher = useFetcher();
  let {customer} = useOutletContext();

  let sortBy = 'created_at';
  let direction = 'DESCENDING';
  let number = 10;

  useEffect(() => {
    fetcher.load(
      `/api/questions/${question.id}/answers?sortBy=${sortBy}&direction=${direction}&limit=${number}&endCursor=${endCursor}`,
    );
  }, []);

  function fetchMoreAnswers() {
    fetcher.load(
      `/api/questions/${question.id}/answers?index&sortBy=${sortBy}&direction=${direction}&limit=${number}&endCursor=${endCursor}`,
    );
  }

  const handleAnswerSubmit = () => {
    setIsLoading(true);
    fetcher.submit(
      {
        answer,
        product_handle: product.handle,
        product_title: product.title,
      },
      {method: 'post', action: `/api/questions/${question.id}/answers/add`},
    );
  };

  useEffect(() => {
    if (!fetcher.data) return;

    setAnswers((prev) => [...prev, ...fetcher.data.items]);
    setNextPage(fetcher.data.pageInfo.hasNextPage);
    setEndCursor(fetcher.data.pageInfo.endCursor);
  }, [fetcher.data]);

  useEffect(() => {
    console.log(fetcher);
    if (
      fetcher.type == 'actionRedirect' &&
      fetcher.state == 'loading' &&
      !fetcher.data?.error
    ) {
      setAnswers([
        {
          answer,
          customer: customer.id.replace('gid://shopify/Customer/', ''),
          id: uuidv4(),
          question: question.id,
          status: 'published',
        },
        ...answers,
      ]);
      setAnswer('');
      setIsLoading(false);
    }
  }, [fetcher.state]);

  const addToasts = useCallback(
    (items) => {
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  if (!question) {
    return null;
  }

  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={() => {
          closeModal();
          ///setAnswers([]);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between pt-4 px-5"
            >
              {question?.question || null}
              <button
                className="w-8 h-8 flex items-center justify-center"
                onClick={() => {
                  closeModal();
                  //setAnswers([]);
                }}
              >
                <CloseIcon />
              </button>
            </Dialog.Title>

            <>
              {customer ? (
                <div>
                  <div className="py-4 px-5 font-serif">
                    <div className="flex flex-col">
                      <textarea
                        className="border rounded-lg px-4 py-2 h-24  border-gray-300"
                        placeholder="Scrie răspunsul tău"
                        value={answer}
                        onChange={(event) => {
                          setAnswer(event.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="pb-4 px-5">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center px-4 h-12 text-lg w-full font-medium text-white bg-primary border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary font-serif disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed"
                      onClick={handleAnswerSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <SpinnerIcon size={24} />
                      ) : (
                        'Adaugă răspunsul tău'
                      )}
                    </button>
                  </div>
                </div>
              ) : null}

              {answers.length > 0 ? (
                <div className="py-4 px-5 border-t border-gray-100">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 font-serif flex justify-between pb-4">
                    Răspunsuri
                  </h3>
                  {answers.map((item, index) => {
                    return (
                      <AnswerCard
                        key={item.id}
                        resource={question}
                        answer={item}
                        isLast={index === answers.length - 1}
                      />
                    );
                  })}

                  {nextPage && (
                    <div className="flex items-center justify-center mt-6">
                      <Button
                        disabled={fetcher.state !== 'idle'}
                        variant="sm_secondary"
                        handleClick={fetchMoreAnswers}
                        width="full"
                        prefetch="intent"
                        label={
                          fetcher.state !== 'idle' ? (
                            <SpinnerIcon size={24} />
                          ) : (
                            'Mai multe răspunsuri'
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </>
          </div>
        </div>
      </Dialog>
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
