export function SeriesIcon() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11C22 4.925 17.075 0 11 0C4.925 0 0 4.925 0 11V21C0 22.65 1.35 24 3 24C4.65 24 6 22.65 6 21V20C6 19.45 6.45 19 7 19C7.55 19 8 19.45 8 20V21C8 22.65 9.35 24 11 24C12.65 24 14 22.65 14 21V20C14 19.45 14.45 19 15 19C15.55 19 16 19.45 16 20V21C16 22.65 17.35 24 19 24C20.65 24 22 22.65 22 21V11ZM20 21C20 21.55 19.55 22 19 22C18.45 22 18 21.55 18 21V20C18 18.35 16.65 17 15 17C13.35 17 12 18.35 12 20V21C12 21.55 11.55 22 11 22C10.45 22 10 21.55 10 21V20C10 18.35 8.65 17 7 17C5.35 17 4 18.35 4 20V21C4 21.55 3.55 22 3 22C2.45 22 2 21.55 2 21V11C2 6.025 6.025 2 11 2C15.975 2 20 6.025 20 11V21Z"
        fill="white"
      />
      <path
        d="M10.5 13C11.3284 13 12 12.3284 12 11.5C12 10.6716 11.3284 10 10.5 10C9.67157 10 9 10.6716 9 11.5C9 12.3284 9.67157 13 10.5 13Z"
        fill="white"
      />
      <path
        d="M16.5 13C17.3284 13 18 12.3284 18 11.5C18 10.6716 17.3284 10 16.5 10C15.6716 10 15 10.6716 15 11.5C15 12.3284 15.6716 13 16.5 13Z"
        fill="white"
      />
    </svg>
  );
}
