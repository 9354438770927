export function CollectionIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 3.5H17C17.2761 3.5 17.5 3.72386 17.5 4V19C17.5 19.2761 17.2761 19.5 17 19.5H13.5V3.5Z"
        stroke="#E75113"
      />
      <rect x="0.5" y="0.5" width="13" height="19" rx="0.5" stroke="#E75113" />
      <rect x="3" y="1" width="1" height="18" fill="#E75113" />
    </svg>
  );
}
