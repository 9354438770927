import {useFetcher} from '@remix-run/react';
import {useState, useEffect} from 'react';
import {Button, SpinnerIcon, UserIcon} from '~/components';

export function FollowUser({user, customer}) {
  const fetcher = useFetcher();

  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState(false);

  useEffect(() => {
    fetcher.load(`/api/users/${user.id}/followers/customer`);
  }, []);

  useEffect(() => {
    // if (fetcher.type === 'done') {
    //   setData(fetcher?.data);
    //   setUserReview(fetcher.data.review);
    // }
  }, [fetcher.type]);

  useEffect(() => {
    if (!fetcher.data) return;
    setFollowing(fetcher.data.following);
    setIsLoading(false);
  }, [fetcher.data]);

  const handleFollow = () => {
    setIsLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/users/${user.id}/follow`},
    );
  };

  const handleUnfollow = () => {
    setIsLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/users/${user.id}/unfollow`},
    );
  };

  if (!customer) {
    return (
      <Button
        url="/profil/login"
        variant="sm_primary"
        className="flex justify-center w-full"
        label={
          <span className="flex items-center">
            <UserIcon /> <span className="ml-2">Urmărește</span>
          </span>
        }
      />
    );
  }
  return (
    <Button
      handleClick={following ? handleUnfollow : handleFollow}
      variant={following ? 'sm_outlined_primary' : 'sm_primary'}
      className="flex justify-center w-full"
      label={
        isLoading ? (
          <SpinnerIcon size={26} />
        ) : following ? (
          'Anulați urmărirea'
        ) : (
          <span className="flex items-center">
            <UserIcon /> <span className="ml-2">Urmărește</span>
          </span>
        )
      }
      disabled={isLoading}
    />
  );
}
