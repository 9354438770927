import {Image} from '@shopify/hydrogen';
export function ListProductCard({product, index}) {
  return (
    <div className="flex justify-between font-serif bg-white rounded-2xl p-5 mb-4">
      <div className="w-full">
        <div className="flex flex-col md:flex-row">
          <div className="hidden md:block text-center md:text-left text-2xl text-primary border-r border-line pr-5 mr-9">
            {index + 1}
          </div>
          <div className="flex flex-col md:flex-row items-center text-left md:text-left flex-grow">
            {product.image ? (
              <a href={`/carti/${product.handle}`}>
                {product.image?.url ? (
                  <Image
                    data={{
                      url: product.image.url,
                      altText: product.title,
                      width: product.image.width,
                      height: product.image.width,
                    }}
                    className="w-36 md:w-24"
                  />
                ) : (
                  <Image
                    data={{
                      url: 'https://via.placeholder.com/400x600/eeeeee/ffffff?text=...',
                      altText: product.title,
                      width: 400,
                      height: 600,
                    }}
                    className="w-36 md:w-24"
                  />
                )}
              </a>
            ) : null}
            <div className="md:pl-8 w-full flex-grow">
              <div className="text-2xl">
                {' '}
                <a href={`/carti/${product.handle}`}>
                  {product.title.split(' -')[0]}
                </a>
              </div>
              <div className="font-serif mt-2 mb-4">
                {product.author?.replace('Autor ', '')}
              </div>

              {/* <div className="flex flex-col gap-3 md:flex-row justify-between border-t border-line pt-4 mt-8">
                <ProductMetrics handle={product.handle} />
                <Suspense fallback={null}>
                  <ProductReviewAdd
                    product={product}
                    uid={uid}
                    profile={profile}
                    customer={customer}
                    userReview={userReview}
                    className=""
                    showAvatar={false}
                  />
                </Suspense>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
