/**
 * A shared component that specifies the icon to render when opened
 */
export function LogoIcon() {
  return (
    <svg
      height="46"
      x="0px"
      y="0px"
      viewBox="0 0 121.4 81"
      style={{enableBackground: 'new 0 0 121.4 81'}}
    >
      <g>
        <g>
          <g>
            <path
              d="M11,78.3L0.3,67.8c0,0,0,0,0,0c0,0,7.2-2.7,19-2.4c11.3,0.3,27.3,4.7,38.2,14.5c0.3,0.2,0,0.6-0.3,0.5
				C51.5,77.9,33.1,70.9,11,78.3C11,78.3,11,78.3,11,78.3z"
            />
            <path
              d="M56.9,77.5c-5.8-4.6-21.1-14.9-40.1-14.2c0,0,0,0,0,0l-6.5-11.8c0,0,0,0,0,0c0,0,28.6-1.6,47.3,25.4
				C57.9,77.3,57.4,77.8,56.9,77.5z"
            />
            <path
              d="M110.3,78.3L121,67.8c0,0,0,0,0,0c0,0-7.2-2.7-19-2.4c-11.4,0.3-27.4,4.8-38.4,14.7c-0.2,0.2,0,0.6,0.3,0.4
				C69.4,78.1,88.1,70.8,110.3,78.3C110.3,78.3,110.3,78.3,110.3,78.3z"
            />
            <path
              d="M64.4,77.5c5.8-4.6,21.1-14.9,40.1-14.2c0,0,0,0,0,0l6.5-11.8c0,0,0,0,0,0c0,0-28.6-1.6-47.3,25.4
				C63.4,77.3,64,77.8,64.4,77.5z"
            />
          </g>
          <path
            d="M55.2,28.8l-4.3-1.6c-0.2-0.1-0.5-0.1-0.7,0.1l-3.7,2.6c-0.5,0.3-1.2,0-1.1-0.6l0.2-4.6c0-0.2-0.1-0.5-0.3-0.6l-3.7-2.7
			c-0.5-0.4-0.3-1.1,0.2-1.3l4.4-1.2c0.2-0.1,0.4-0.2,0.5-0.5l1.5-4.3c0.2-0.6,0.9-0.7,1.3-0.2l2.5,3.8c0.1,0.2,0.4,0.3,0.6,0.3
			l4.6,0.1c0.6,0,0.9,0.7,0.6,1.2l-2.8,3.6c-0.1,0.2-0.2,0.4-0.1,0.7l1.3,4.4C56.3,28.5,55.8,29,55.2,28.8z"
          />
          <path
            d="M75.9,13l-2.3-2.6c-0.1-0.1-0.3-0.2-0.5-0.2l-3.5,0.4c-0.5,0.1-0.8-0.4-0.5-0.8l1.8-3c0.1-0.2,0.1-0.3,0-0.5l-1.5-3.2
			c-0.2-0.4,0.2-0.9,0.6-0.8L73.4,3c0.2,0,0.4,0,0.5-0.1l2.6-2.4c0.3-0.3,0.9-0.1,0.9,0.3l0.4,3.5c0,0.2,0.1,0.3,0.3,0.4l3.1,1.7
			c0.4,0.2,0.4,0.8,0,1l-3.2,1.4c-0.2,0.1-0.3,0.2-0.3,0.4l-0.7,3.4C76.8,13.1,76.2,13.3,75.9,13z"
          />
          <path
            d="M64.5,56.4l-2.2-1c-0.1-0.1-0.2,0-0.4,0l-2.1,1.2c-0.3,0.2-0.6-0.1-0.6-0.4l0.3-2.4c0-0.1,0-0.2-0.1-0.3l-1.8-1.6
			c-0.2-0.2-0.1-0.6,0.2-0.7l2.4-0.4c0.1,0,0.2-0.1,0.3-0.2l1-2.2c0.1-0.3,0.5-0.3,0.7,0l1.2,2.1c0.1,0.1,0.2,0.2,0.3,0.2l2.4,0.2
			c0.3,0,0.5,0.4,0.2,0.6l-1.6,1.7c-0.1,0.1-0.1,0.2-0.1,0.3l0.5,2.3C65.1,56.3,64.7,56.5,64.5,56.4z"
          />
          <path
            d="M84.6,39l-2.2-2c-0.1-0.1-0.3-0.1-0.4-0.1l-2.9,0.6c-0.4,0.1-0.7-0.3-0.5-0.6l1.2-2.7c0.1-0.1,0.1-0.3,0-0.4l-1.5-2.5
			c-0.2-0.3,0.1-0.7,0.5-0.7l2.9,0.4c0.2,0,0.3,0,0.4-0.2l2-2.2c0.3-0.3,0.7-0.2,0.8,0.2l0.6,2.9c0,0.2,0.1,0.3,0.3,0.3l2.7,1.2
			c0.4,0.2,0.4,0.6,0,0.8l-2.6,1.4c-0.1,0.1-0.2,0.2-0.2,0.4l-0.3,2.9C85.4,39.1,84.9,39.3,84.6,39z"
          />
          <path
            d="M39.2,44.4l-1.6-0.3c-0.1,0-0.2,0-0.2,0.1l-1.2,1.1c-0.2,0.1-0.4,0.1-0.4-0.2l-0.2-1.6c0-0.1-0.1-0.2-0.1-0.2l-1.5-0.8
			c-0.2-0.1-0.2-0.4,0-0.5l1.5-0.7c0.1,0,0.1-0.1,0.1-0.2l0.3-1.6c0-0.2,0.3-0.3,0.4-0.1l1.1,1.2c0.1,0.1,0.1,0.1,0.2,0.1l1.6-0.2
			c0.2,0,0.4,0.2,0.3,0.4l-0.8,1.4c0,0.1,0,0.2,0,0.2l0.7,1.5C39.6,44.2,39.4,44.5,39.2,44.4z"
          />
        </g>
      </g>
    </svg>
  );
}
