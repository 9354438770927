export function VideoIcon() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 18H7V20C7 21.1 7.9 22 9 22H15C16.1 22 17 21.1 17 20V18H21C22.65 18 24 16.65 24 15V3C24 1.35 22.65 0 21 0H3C1.35 0 0 1.35 0 3V15C0 16.65 1.35 18 3 18ZM15 20H9V18H15V20ZM2 3C2 2.45 2.45 2 3 2H21C21.55 2 22 2.45 22 3V15C22 15.55 21.55 16 21 16H3C2.45 16 2 15.55 2 15V3Z"
        fill="white"
      />
      <path
        d="M9.525 13.875C9.85 14.05 10.25 14.025 10.55 13.825L16.55 9.82499C17 9.52499 17.125 8.89999 16.825 8.44999C16.75 8.34999 16.65 8.24999 16.55 8.17499L10.55 4.17499C10.1 3.87499 9.475 3.99999 9.175 4.44999C9.05 4.59999 9 4.79999 9 4.99999V13C9 13.375 9.2 13.7 9.525 13.875ZM11 6.87499L14.2 8.99999L11 11.125V6.87499Z"
        fill="white"
      />
    </svg>
  );
}
