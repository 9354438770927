export function LogoutIcon() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L1 6.5M1 6.5L7 12M1 6.5H14.5M14.5 6.5V2M14.5 6.5V11"
        stroke="#E75113"
      />
    </svg>
  );
}
