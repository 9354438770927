export function CheckIcon({stroke}) {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L8 17L22 1"
        style={{stroke: stroke || '#E75113'}}
        strokeWidth="1.5"
      />
    </svg>
  );
}
