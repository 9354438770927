export function FollowersIcon({width = 25, height = 20}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.161 5.82982C20.161 8.18975 19.0638 9.71323 17.6006 10.2595C17.1009 10.446 16.5411 10.446 16.0413 10.2595C14.5781 9.71323 13.481 8.18975 13.481 5.82982C13.481 4.97802 13.7274 4.23777 14.2326 3.71279C14.7333 3.19254 15.5497 2.81982 16.821 2.81982C17.9421 2.81982 18.7702 3.18521 19.3159 3.7224C19.8638 4.26173 20.161 5.00704 20.161 5.82982Z"
        stroke="#E75113"
      />
      <path
        d="M10.0353 15.6495C10.3922 13.7756 12.0305 12.4199 13.9381 12.4199L19.701 12.4199C21.6085 12.4199 23.2469 13.7756 23.6038 15.6495C23.7551 16.4439 23.1461 17.1799 22.3374 17.1799H11.3017C10.493 17.1799 9.88394 16.4439 10.0353 15.6495Z"
        stroke="#E75113"
      />
      <path
        d="M14.4005 4.6899C14.4005 7.71577 12.9045 9.66471 10.8942 10.2834C10.3789 10.442 9.82216 10.442 9.30691 10.2834C7.29662 9.66471 5.80054 7.71577 5.80054 4.6899C5.80054 3.63115 6.11373 2.69733 6.77117 2.02939C7.42549 1.36462 8.48324 0.899902 10.1005 0.899902C11.5336 0.899902 12.603 1.35747 13.3109 2.0389C14.0202 2.72158 14.4005 3.66069 14.4005 4.6899Z"
        fill="#ffffff"
        stroke="#E75113"
      />
      <path
        d="M1.49096 16.8976C1.95364 14.3066 4.20705 12.4199 6.83904 12.4199L13.3619 12.4199C15.9939 12.4199 18.2473 14.3066 18.71 16.8976C18.915 18.0456 18.0323 19.0999 16.8661 19.0999H3.33486C2.16864 19.0999 1.28595 18.0456 1.49096 16.8976Z"
        fill="#ffffff"
        stroke="#E75113"
      />
    </svg>
  );
}
