export function SettingsIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" width="1" height="11" fill="#E75113" />
      <rect x="3" y="16" width="1" height="4" fill="#E75113" />
      <rect x="12" width="1" height="4" fill="#E75113" />
      <rect x="12" y="9" width="1" height="11" fill="#E75113" />
      <circle cx="3.5" cy="13.5" r="3" stroke="#E75113" />
      <circle cx="12.5" cy="6.5" r="3" stroke="#E75113" />
    </svg>
  );
}
