import {ProductCard} from '~/components';
import {getAuthor} from '~/lib/utils';
import {flattenConnection} from '@shopify/hydrogen';

export function ProductSwimlane({products, customer, count = ''}) {
  const classes = `swimlane hiddenScroll md:pb-8 grid-cols-93 sm:grid-col-50${count} md:grid-cols-50${count} lg:grid-cols-25${count}`;
  console.log('Productd', products);
  return (
    <div>
      <div className={classes}>
        {products &&
          products.map((product) => {
            const author =
              product && product.collections
                ? getAuthor(flattenConnection(product.collections))
                : product.author;

            return (
              <ProductCard
                image={{
                  url:
                    product.featuredImage?.url ||
                    product.image?.url ||
                    'https://via.placeholder.com/500x700',
                  width: product.featuredImage?.width || product.image.width,
                  height: product.featuredImage?.height || product.image.height,
                }}
                title={product.title}
                id={product.id}
                handle={product.handle || product.product_handle}
                url={`/carti/${product.handle || product.product_handle}`}
                author={
                  author?.title
                    ? author.title?.replace('Autor', '')
                    : author || null
                }
                review={product.review}
                count_reviews={product.count_reviews || 0}
                count_reviews_with_description={
                  product.count_reviews_with_description || 0
                }
                count_five_star_reviews={product.count_five_star_reviews || 0}
                count_four_star_reviews={product.count_four_star_reviews || 0}
                count_three_star_reviews={product.count_three_star_reviews || 0}
                count_two_star_reviews={product.count_two_star_reviews || 0}
                count_one_star_reviews={product.count_one_star_reviews || 0}
                key={product.id}
                className={'snap-start w-80'}
                customer={customer}
                price={product.variants.nodes[0].price}
                compareAtPrice={product.variants.nodes[0].compareAtPrice}
              />
            );
          })}
      </div>
    </div>
  );
}
