import {useOutletContext} from '@remix-run/react';
import {QuestionLikes} from './QuestionLikes';
import {Button} from './Button';
import {DateFromTimestamp} from './DateFromTimestamp';
import {AnswerIcon, ChatIcon} from './icons';
import {QuestionAnswers} from './QuestionAnswers';
import {useState} from 'react';

export function QuestionCard({question, product, isLast}) {
  const {customer} = useOutletContext();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const handleAnswer = ({question}) => {
    openModal();
  };
  return (
    <div
      className={
        isLast
          ? 'font-serif text-left text-lg mb-0 pb-0'
          : 'font-serif text-left text-lg mb-5 pb-3 border-b border-gray-200'
      }
    >
      <div className="text-base leading-6 md:text-lg md:leading-8 mb-2">
        {question.question}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <QuestionLikes question={question} />
          {question.answers ? (
            <Button
              label={
                <span className="flex items-center">
                  <ChatIcon />
                  <span className="ml-2">({question.answers})</span>
                </span>
              }
              variant="naked"
              handleClick={() => {
                handleAnswer(question);
              }}
              className="h-8 !py-0 !px-3 rounded-lg bg-secondary !text-onSecondary ml-1"
            />
          ) : null}
          {customer ? (
            <Button
              label={
                <span className="flex items-center text-sm md:text-lg">
                  <AnswerIcon /> <span className="ml-2">Răspunde</span>
                </span>
              }
              variant="naked"
              handleClick={() => {
                handleAnswer(question);
              }}
            />
          ) : (
            <Button
              label={
                <span className="flex items-center text-sm md:text-lg">
                  <AnswerIcon /> <span className="ml-2">Răspunde</span>
                </span>
              }
              variant="naked"
              url="/profil/login"
            />
          )}
        </div>
        <span className="opacity-40 hidden md:inline-block">
          <DateFromTimestamp timestamp={question.created_at} />
        </span>
      </div>
      <QuestionAnswers
        closeModal={closeModal}
        open={open}
        question={question}
        product={product}
      />
    </div>
  );
}
