export function StickerIcon() {
  return (
    <svg
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5202 5.66476C24.9979 3.67821 23.6536 1.79442 21.3844 0.843957C19.4065 0.0219375 17.0688 0.706954 15.5618 2.53937C14.774 3.4984 14.3801 4.61155 14.363 5.7247C6.15995 8.21645 0.5 15.7944 0.5 24.4428C0.5 35.2232 9.27677 44 20.0572 44C30.8377 44 39.6144 35.2232 39.6144 24.4428C39.6144 15.7345 33.8346 8.07944 25.5202 5.66476ZM20.0572 41.5853C10.604 41.5853 2.91468 33.896 2.91468 24.4428C2.91468 16.9076 7.82968 10.3057 14.9538 8.08801C15.0224 8.21645 16.0413 10.3742 18.6187 10.3742C18.6187 10.3742 20.4597 10.4428 21.5899 8.93571C21.9924 8.40483 21.8554 7.63418 21.3074 7.24886C20.7679 6.86354 20.0915 7.06048 19.6205 7.53143C18.8156 8.33633 17.8138 8.07088 17.163 7.11186C16.2211 5.7247 17.1288 4.43173 17.4285 4.0721C18.0964 3.25864 19.3294 2.59931 20.4597 3.07026C22.7373 4.02072 23.6193 6.42684 23.3025 8.30207C23.1569 9.1926 22.489 11.2905 19.5435 11.4703C18.8756 11.5131 18.3704 12.0868 18.4132 12.7547C18.456 13.3969 18.9869 13.9278 19.6976 13.885C22.8915 13.6966 25.2719 11.4018 25.7429 8.25926C32.5502 10.6483 37.1998 17.1131 37.1998 24.4342C37.1998 33.896 29.5104 41.5853 20.0572 41.5853Z"
        fill="#514D71"
      />
      <path
        d="M15.2279 21.5317C15.2279 19.5366 13.601 17.9097 11.6059 17.9097C9.6108 17.9097 7.98389 19.5366 7.98389 21.5317C7.98389 23.5268 9.6108 25.1537 11.6059 25.1537C13.601 25.1537 15.2279 23.5268 15.2279 21.5317ZM11.6059 22.739C10.938 22.739 10.3986 22.1996 10.3986 21.5317C10.3986 20.8638 10.938 20.3244 11.6059 20.3244C12.2738 20.3244 12.8133 20.8638 12.8133 21.5317C12.8133 22.1996 12.2652 22.739 11.6059 22.739Z"
        fill="#514D71"
      />
      <path
        d="M28.5087 17.9097C26.5136 17.9097 24.8867 19.5366 24.8867 21.5317C24.8867 23.5268 26.5136 25.1537 28.5087 25.1537C30.5039 25.1537 32.1308 23.5268 32.1308 21.5317C32.1308 19.5366 30.5124 17.9097 28.5087 17.9097ZM28.5087 22.739C27.8409 22.739 27.3014 22.1996 27.3014 21.5317C27.3014 20.8638 27.8409 20.3244 28.5087 20.3244C29.1766 20.3244 29.7161 20.8638 29.7161 21.5317C29.7161 22.1996 29.1766 22.739 28.5087 22.739Z"
        fill="#514D71"
      />
      <path
        d="M24.8268 32.0378C22.198 34.6665 17.9167 34.6665 15.2879 32.0378C14.817 31.5668 14.0549 31.5668 13.5754 32.0378C13.1044 32.5087 13.1044 33.2708 13.5754 33.7503C15.365 35.5399 17.7026 36.4305 20.0488 36.4305C22.395 36.4305 24.7411 35.5399 26.5222 33.7503C26.9931 33.2794 26.9931 32.5173 26.5222 32.0378C26.0598 31.5668 25.2977 31.5668 24.8268 32.0378Z"
        fill="#514D71"
      />
    </svg>
  );
}
