export function MoreIcon() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2C14.55 2 15 1.55 15 1C15 0.45 14.55 0 14 0H6C5.45 0 5 0.45 5 1C5 1.55 5.45 2 6 2H14Z"
        fill="white"
      />
      <path
        d="M2 5C2 5.55 2.45 6 3 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H3C2.45 4 2 4.45 2 5Z"
        fill="white"
      />
      <path
        d="M0 10V22C0 23.1 0.9 24 2 24H18C19.1 24 20 23.1 20 22V10C20 8.9 19.1 8 18 8H2C0.9 8 0 8.9 0 10ZM2 10H18V22H2V10Z"
        fill="white"
      />
      <path
        d="M14 12C13.45 12 13 12.45 13 13V14C13 14.55 12.55 15 12 15H8C7.45 15 7 14.55 7 14V13C7 12.45 6.55 12 6 12C5.45 12 5 12.45 5 13V14C5 15.65 6.35 17 8 17H12C13.65 17 15 15.65 15 14V13C15 12.45 14.55 12 14 12Z"
        fill="white"
      />
    </svg>
  );
}
