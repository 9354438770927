import {LogIntoAccountIcon, Button} from '~/components';
import {UserAvatar} from './global/UserAvatar';

export function AccountSection({
  sectionTitle = 'Recomandat pentru tine',
  title = 'INTRA ÎN CONT',
  subtitle = 'Dă note, scrie recenzii si salvează cărți în raftul tău pentru recomandări personalizare',
  showIcon = true,
  customer,
}) {
  if (customer) {
    return (
      <div className="pt-10 pb-10 xl:pt-20 xl:pb-20">
        <div className="container mx-auto px-4 pr-0 md:pr-4 md:px-4">
          <div className="font-serif text-center max-w-md mx-auto flex flex-col justify-center items-center">
            <UserAvatar size={100} customer={customer} background="#ffffff" />
            <div className="uppercase my-5">Bine ai revenit!</div>
            <p className="mb-6">{subtitle}</p>
            <Button url="/profil" variant="sm_primary" label="Vezi profil" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-10 pb-10 xl:pt-20 xl:pb-20">
      <div className="container mx-auto px-4 pr-0 md:pr-4 md:px-4">
        {sectionTitle ? (
          <div className="text-black font-sans font-bold mb-6 text-2xl lg:text-3xl lg:mb-10">
            {sectionTitle}
          </div>
        ) : null}
        <div className="font-serif text-center max-w-md mx-auto flex flex-col justify-center items-center">
          {showIcon ? <LogIntoAccountIcon /> : null}
          <div className="uppercase my-5"> {title}</div>
          <p className="mb-6">{subtitle}</p>
          <Button
            url="/profil/login"
            variant="sm_primary"
            label="Intra în cont"
          />
        </div>
      </div>
    </div>
  );
}
