/**
 * A shared component that specifies the icon to render when opened
 */
export function ArrowDownIcon() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.5L5 4.5L9 0.5" stroke="#E75113" />
    </svg>
  );
}
