export function LegalIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 24H19C21.75 24 24 21.75 24 19V18C24 17.45 23.55 17 23 17H19V6C19 4.35 17.65 3 16 3H10C10 1.35 8.65 0 7 0C5.35 0 4 1.35 4 3H3C1.35 3 0 4.35 0 6V19C0 21.75 2.25 24 5 24ZM19 22H8.975C9.625 21.15 10 20.075 10 19H22C22 20.65 20.65 22 19 22ZM2 6C2 5.45 2.45 5 3 5H4V8C4 9.65 5.35 11 7 11C8.65 11 10 9.65 10 8C10 7.45 9.55 7 9 7C8.45 7 8 7.45 8 8C8 8.55 7.55 9 7 9C6.45 9 6 8.55 6 8V3C6 2.45 6.45 2 7 2C7.55 2 8 2.45 8 3V4C8 4.55 8.45 5 9 5H16C16.55 5 17 5.45 17 6V17H9C8.45 17 8 17.45 8 18V19C8 20.65 6.65 22 5 22C3.35 22 2 20.65 2 19V6Z"
        fill="white"
      />
    </svg>
  );
}
