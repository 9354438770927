import {useEffect, useState} from 'react';
import {Link} from '@remix-run/react';
import {ListCard} from '~/components';
import {useFetcher} from '@remix-run/react';

export function ListsList({
  label,
  buttonLabel,
  title,
  subtitle,
  className,
  lists,
}) {
  if (lists && lists?.length === 0) {
    return null;
  }

  return (
    <div className={`pt-10 pb-10 xl:pt-20 xl:pb-20 ${className}`}>
      <div className="container mx-auto px-4 md:pr-4 md:px-4">
        {label ? (
          <div className="text-primary uppercase mb-3 font-sans tracking-wider text-lg lg:text-2xl">
            {label}
          </div>
        ) : null}
        <div
          className={
            subtitle
              ? 'text-black font-sans font-bold text-2xl lg:text-3xl mb-3'
              : 'text-black font-sans font-bold mb-6 text-2xl lg:text-3xl lg:mb-10'
          }
        >
          {title || 'Recomandări'}
        </div>
        {subtitle ? (
          <div className="text-black font-serif mb-6 text-lg lg:mb-8">
            {subtitle}
          </div>
        ) : null}

        <div className="md:pb-8 grid grid-cols-1 lg:grid-cols-2 gap-4">
          {lists &&
            lists.map((list) => {
              return <ListCard key={list.id} list={list} layout="grid" />;
            })}
        </div>

        {buttonLabel ? (
          <div className="border-b border-solid border-line pt-5 pb-8">
            <Link
              className="block font-serif m-0 w-full items-center justify-center text-lg text-center px-6 py-4 rounded-lg disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors bg-none text-primary"
              to={`/liste/toate`}
            >
              {buttonLabel}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
