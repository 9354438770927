import {useEffect, useState} from 'react';
// import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {PencilIcon} from '../index';
import {SpinnerIcon} from '../index';
import {useFetcher, useOutletContext} from '@remix-run/react';

//TODO: Replace package with REST

export function UploadUserImage({setImage}) {
  //const storage = getStorage();
  // const storageRef = ref(
  //   storage,
  //   `avatars/${customer.id.replace('gid://shopify/Customer/', '')}`,
  // );
  const fetcher = useFetcher();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (event) => {
    setIsLoading(true);
    let file = event.target.files[0];
    //read data from the blob objects(file)
    let reader = new FileReader();

    //reads the binary data and encodes it as base64 data url
    reader.readAsDataURL(file);

    //reads it finish with either success or failure
    reader.onloadend = () => {
      //reader.result is the result of the reading in base64 string

      uploadFile(reader.result);
    };
  };

  const uploadFile = (file) => {
    fetcher.submit(
      {
        file,
      },
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        action: `/api/users/avatar/upload`,
      },
    );
  };

  return (
    <div className="group">
      {!isLoading ? (
        <span className="opacity-0 group-hover:opacity-100 bg-black bg-opacity-80 text-white transition-opacity flex absolute left-0 right-0 top-0 bottom-0 items-center justify-center">
          <PencilIcon />
        </span>
      ) : null}
      {isLoading ? (
        <span className="text-white transition-opacity bg-primary bg-opacity-80 flex absolute left-0 right-0 top-0 bottom-0 items-center justify-center">
          <SpinnerIcon />
        </span>
      ) : null}
      <input
        type="file"
        name="avatar"
        accept="image/png, image/jpeg"
        onChange={handleUpload}
        style={{opacity: 0}}
        className="absolute left-0 top-0 bottom-0 right-0 z-20 cursor-pointer"
      />
    </div>
  );
}
