export function UserIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 3.65625C11.5 6.13882 10.3372 7.74025 8.78547 8.30246C8.28084 8.4853 7.71916 8.4853 7.21453 8.30246C5.66284 7.74025 4.5 6.13882 4.5 3.65625C4.5 2.76508 4.75788 1.98826 5.28883 1.43656C5.81524 0.889589 6.67164 0.5 8 0.5C9.17261 0.5 10.0407 0.882258 10.6135 1.44617C11.1886 2.01221 11.5 2.79409 11.5 3.65625Z"
        style={{stroke: 'currentcolor'}}
      />
      <path
        d="M0.912592 13.8811C1.28626 11.9193 3.00144 10.5 4.99849 10.5L11.0015 10.5C12.9986 10.5 14.7137 11.9193 15.0874 13.8811C15.2475 14.7215 14.6032 15.5 13.7478 15.5H2.25225C1.39676 15.5 0.75252 14.7215 0.912592 13.8811Z"
        style={{stroke: 'currentcolor'}}
      />
    </svg>
  );
}
