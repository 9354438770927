import {useState} from 'react';
import {useOutletContext} from '@remix-run/react';
import {Button} from './Button';
import {DateFromTimestamp} from './DateFromTimestamp';
import {UserAvatar} from './global/UserAvatar';
import {AnswerIcon, ChatIcon, StarIcon} from './icons';
import {ReviewLikes} from './ReviewLikes';
import {ReviewAnswers} from './ReviewAnswers';

export function AccountReviewCard({isLast, review}) {
  const {customer} = useOutletContext();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const handleAnswer = ({review}) => {
    openModal();
  };
  return (
    <div
      className={
        isLast
          ? 'font-serif text-left text-lg mb-0 pb-0 00'
          : 'font-serif text-left text-lg mb-8 pb-8 border-b border-gray-200'
      }
      key={review.id}
    >
      <div className="flex items-center mb-3">
        <div>
          <div className="flex items-center">
            <StarIcon size={24} />{' '}
            <span className="ml-2">{review.stars}/5</span>
          </div>
          <div className="text-2xl">{review.title}</div>
        </div>
      </div>
      <div className="text-base leading-6 md:text-lg md:leading-8 mb-2">
        {review.review}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ReviewLikes review={review} />
          {review.answers ? (
            <Button
              label={
                <span className="flex items-center">
                  <ChatIcon />
                  <span className="ml-2">({review.answers})</span>
                </span>
              }
              variant="naked"
              handleClick={() => {
                handleAnswer(review);
              }}
              className="h-8 !py-0 !px-3 rounded-lg bg-secondary !text-onSecondary ml-1"
            />
          ) : null}
          {customer ? (
            <Button
              label={
                <span className="flex items-center text-sm md:text-lg">
                  <AnswerIcon /> <span className="ml-2">Răspunde</span>
                </span>
              }
              variant="naked"
              handleClick={() => {
                handleAnswer(review);
              }}
            />
          ) : (
            <Button
              label={
                <span className="flex items-center text-sm md:text-lg">
                  <AnswerIcon /> <span className="ml-2">Răspunde</span>
                </span>
              }
              variant="naked"
              url="/profil/login"
            />
          )}
        </div>
        <DateFromTimestamp timestamp={review.created_at} />
      </div>
      <ReviewAnswers closeModal={closeModal} open={open} review={review} />
    </div>
  );
}
