/**
 * A shared component that specifies the icon to render when opened
 */
export function MenuMobileIcon() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.5H24M0 16.5H24M0 8.99999H24"
        stroke="#E75113"
        strokeWidth="1.2"
      />
    </svg>
  );
}
