export function ListsIcon() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3335 3H14.0002C14.2763 3 14.5002 3.22386 14.5002 3.5V15.6667C14.5002 15.9428 14.2763 16.1667 14.0002 16.1667H11.3335V3Z"
        stroke="#E75113"
      />
      <rect
        x="0.5"
        y="0.5"
        width="10.6667"
        height="15.6667"
        rx="0.5"
        stroke="#E75113"
      />
      <rect x="3" y="0.333252" width="1" height="16" fill="#E75113" />
    </svg>
  );
}
