import {StarIcon, FileIcon} from '~/components';

export function Metrics({
  isDataLoading,
  count_reviews,
  count_reviews_with_description,
  average,
  className = 'flex text-lg font-serif justify-between px-4 py-4 lg:text-xl border-b border-solid border-line',
}) {
  if (isDataLoading) return null;

  if (count_reviews || count_reviews_with_description) {
    return (
      <div className={className}>
        <span className="flex items-center flex-grow">
          <StarIcon />{' '}
          <span className="ml-2">
            {average > 0 ? average.toFixed(2) : 0}
            <span className="opacity-60 ml-3">({count_reviews || 0})</span>{' '}
          </span>
        </span>
        <span className="flex items-center ml-4 flex-grow">
          <FileIcon />{' '}
          <span className="ml-2"> {count_reviews_with_description || 0}</span>
        </span>
      </div>
    );
  }

  return null;
}
