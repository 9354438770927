export function ProfileIcon({stroke = '#E75113', fill = 'white'}) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4.02188C13 6.82245 11.6285 8.62785 9.78625 9.21973C9.27699 9.38334 8.72301 9.38334 8.21375 9.21973C6.3715 8.62785 5 6.82245 5 4.02188C5 3.03531 5.29177 2.16852 5.90099 1.54957C6.5071 0.933777 7.48984 0.5 9 0.5C10.3366 0.5 11.331 0.92663 11.9881 1.55907C12.6465 2.19278 13 3.06485 13 4.02188Z"
        fill={fill}
        stroke={stroke}
      />
      <path
        d="M0.959329 15.4721C1.39043 13.0579 3.49004 11.3 5.94239 11.3L12.0576 11.3C14.51 11.3 16.6096 13.0579 17.0407 15.4721C17.2294 16.5293 16.4167 17.5001 15.3428 17.5001H2.65722C1.58335 17.5001 0.770551 16.5293 0.959329 15.4721Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
}
