export function CommentsIcon() {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1H4C2.34315 1 1 2.34314 1 4V11.9977C1 13.6202 2.31529 14.9355 3.93779 14.9355C3.99187 14.9355 4.03571 14.9793 4.03571 15.0334V19L10.1071 14.9355H15C16.6569 14.9355 18 13.5923 18 11.9355V4C18 2.34315 16.6569 1 15 1Z"
        stroke="#E75113"
        strokeWidth="1.1"
        strokeLinejoin="round"
      />
    </svg>
  );
}
