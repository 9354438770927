import {SearchIcon} from '~/components';

export function SearchForm({handleSearch, term, setIsSearchDropOpen}) {
  return (
    <form action="/cauta">
      <input
        type="text"
        placeholder="Caută cărți sau autori"
        className="focus:outline-none focus:ring-2 focus:ring-gray-300 h-10 py-0 px-4 rounded-lg w-full font-serif text-lg font-normal border-none"
        onChange={handleSearch}
        onFocus={
          setIsSearchDropOpen === null
            ? null
            : () => {
                setIsSearchDropOpen(true);
              }
        }
        autoComplete="off"
        value={term}
        name="q"
      />
      <button
        type="submit"
        className="absolute w-10 h-10 flex items-center justify-center top-0 right-0"
      >
        <SearchIcon />
      </button>
    </form>
  );
}
