export function PriceIcon({size = 26}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1421 1.59619C14.2534 1.48491 14.4105 1.43252 14.5663 1.45477L22.4859 2.58614C23.1459 2.68042 23.6644 3.19897 23.7587 3.85894L24.8901 11.7785C24.9124 11.9343 24.86 12.0915 24.7487 12.2028L12.3743 24.5772C11.7885 25.163 10.8388 25.163 10.253 24.5772L1.76771 16.0919C1.18192 15.5061 1.18192 14.5563 1.76771 13.9706L14.1421 1.59619ZM15.3795 10.7214C16.5511 11.893 18.4506 11.893 19.6221 10.7214C20.7937 9.54988 20.7937 7.65038 19.6221 6.47881C18.4506 5.30724 16.5511 5.30724 15.3795 6.47881C14.2079 7.65038 14.2079 9.54988 15.3795 10.7214Z"
        style={{stroke: '#E75113'}}
      />
    </svg>
  );
}
