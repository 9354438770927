import {
  StarFullHoverIcon,
  StarHoverIcon,
  StarFullIcon,
  StarIcon,
} from '~/components';

export function ReviewStarSelection({
  handleSelectedStars,
  handleFocusedStar,
  handleHoveredStars,
  hoveredStars,
  selectedStars,
  focusedStar,
  source,
  setOpen,
}) {
  return (
    <div className="flex items-center gap-1">
      {[1, 2, 3, 4, 5].map((star) => {
        return (
          <button
            key={star}
            onClick={() => {
              handleSelectedStars(star);
              if (source === 'page') {
                setOpen(true);
              }
            }}
            onMouseOver={() => {
              handleHoveredStars(star);
            }}
            onFocus={() => {
              handleFocusedStar(star);
            }}
            onMouseLeave={() => {
              handleHoveredStars(0);
            }}
            className={source === 'page' ? '' : 'px-1'}
          >
            {selectedStars >= star ? (
              hoveredStars >= star ? (
                <StarFullIcon size={source === 'page' ? 20 : 28} />
              ) : hoveredStars > 0 ? (
                <StarFullHoverIcon size={source === 'page' ? 20 : 28} />
              ) : (
                <StarFullIcon size={source === 'page' ? 20 : 28} />
              )
            ) : hoveredStars >= star ? (
              <StarHoverIcon size={source === 'page' ? 20 : 28} />
            ) : (
              <StarIcon size={source === 'page' ? 20 : 28} />
            )}
          </button>
        );
      })}
    </div>
  );
}
