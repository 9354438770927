export function PlusIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      style={{fill: 'currentcolor'}}
    >
      <rect x="9" y="1" width="1" height="17"></rect>
      <rect x="1" y="9" width="17" height="1"></rect>
    </svg>
  );
}
