import {useState, useEffect, useCallback} from 'react';
import {
  Button,
  LikeIcon,
  SpinnerIcon,
  UserIcon,
  Toast,
  CheckIcon,
} from '~/components';
import {useOutletContext} from '@remix-run/react';
import {remove} from 'lodash/remove';
import {useFetcher} from '@remix-run/react';

export function CollectionActions({handle, collection, hideLike}) {
  const {customer} = useOutletContext();

  const [following, setFollowing] = useState(collection?.following);
  const [liked, setLiked] = useState(collection?.liked);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);

  const fetcher = useFetcher();
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (!fetcher.data) return;

    setLiked(fetcher.data.liked);
    setFollowing(fetcher.data.following);
    setIsLikeLoading(false);
    setIsFollowingLoading(false);
  }, [fetcher.data]);

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  const handleUnlike = () => {
    setIsLikeLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/categorii/${handle}/unlike`},
    );
  };
  const handleLike = () => {
    setIsLikeLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/categorii/${handle}/like`},
    );
  };

  const handleUnfollow = () => {
    setIsFollowingLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/categorii/${handle}/unfollow`},
    );
  };
  const handleFollow = () => {
    setIsFollowingLoading(true);
    fetcher.submit(
      {},
      {method: 'post', action: `/api/categorii/${handle}/follow`},
    );
  };

  if (customer) {
    return (
      <>
        <div
          className={
            'md:px-7 pt-4 flex justify-center flex-grow w-full md:max-w-lg mx-auto'
          }
        >
          <div className="block md:flex md:justify-center gap-2 w-full">
            <Button
              handleClick={following ? handleUnfollow : handleFollow}
              variant={following ? 'sm_outlined_primary' : 'sm_primary'}
              className="flex justify-center flex-grow w-full disabled:text-onSecondary mb-3 md:mb-0"
              label={
                isFollowingLoading ? (
                  <SpinnerIcon size={24} />
                ) : following ? (
                  'Anulează Urmărirea'
                ) : (
                  <span className="flex items-center justify-center gap-x-2">
                    <UserIcon /> Urmărește
                  </span>
                )
              }
              disabled={isFollowingLoading || isLikeLoading}
            />
            {hideLike ? null : (
              <Button
                handleClick={liked ? handleUnlike : handleLike}
                variant={liked ? 'sm_outlined_secondary' : 'sm_secondary'}
                className="flex justify-center flex-grow w-full mb-2 md:mb-0"
                label={
                  isLikeLoading ? (
                    <SpinnerIcon size={24} />
                  ) : liked ? (
                    <span className="flex items-center justify-center gap-x-2">
                      <LikeIcon /> Îmi place
                    </span>
                  ) : (
                    <span className="flex items-center justify-center gap-x-2">
                      <LikeIcon /> Îmi place
                    </span>
                  )
                }
                disabled={isLikeLoading || isFollowingLoading}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={
          'md:px-7 pt-4 flex justify-center flex-grow w-full md:max-w-lg mx-auto'
        }
      >
        <div className="block md:flex md:justify-center gap-2 w-full">
          <Button
            url="/profil/login"
            variant="sm_primary"
            className="flex justify-center flex-grow w-full"
            label={
              <span className="flex items-center justify-center gap-x-2">
                <UserIcon /> Urmărește
              </span>
            }
          />
          {hideLike ? null : (
            <Button
              url="/profil/login"
              variant="sm_secondary"
              className="flex justify-center flex-grow w-full mb-2 md:mb-0"
              label={
                <span className="flex items-center justify-center gap-x-2">
                  <LikeIcon /> Îmi place
                </span>
              }
            />
          )}
        </div>
      </div>
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
