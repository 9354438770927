export function AboutUsIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.130269 8.99017C-0.156376 9.8501 0.0781518 10.7622 0.729618 11.3876L4.899 15.4788L3.90877 21.2377C3.67424 22.5146 4.53418 23.7394 5.78499 23.9739C6.30617 24.0781 6.8534 23.9739 7.29639 23.7394L12.456 21.0032L17.6156 23.7394C17.9544 23.9218 18.3192 23.9999 18.7101 23.9999C20.013 23.9999 21.0554 22.9576 21.0554 21.6547C21.0554 21.5244 21.0553 21.3941 21.0293 21.2377L20.0651 15.4788L24.2606 11.3876C25.1987 10.4755 25.2247 8.99017 24.3127 8.07812C23.9479 7.71329 23.4788 7.45271 22.9576 7.40059L17.1726 6.56672L14.5928 1.32893C14.0195 0.156292 12.6124 -0.312764 11.4658 0.260526C10.9967 0.495054 10.6058 0.859875 10.3974 1.32893L7.81757 6.56672L2.00649 7.40059C1.1205 7.53088 0.390855 8.15629 0.130269 8.99017ZM2.11073 9.66769C2.13678 9.56346 2.21496 9.51134 2.31919 9.48528L8.65144 8.57323C8.9902 8.52111 9.27685 8.31264 9.4332 7.99994L12.2475 2.24098C12.3778 2.00645 12.6124 2.00645 12.7166 2.24098L15.5309 7.97388C15.6873 8.28658 15.9739 8.49505 16.3127 8.54717L22.6449 9.45922C22.8013 9.45922 22.9055 9.58952 22.8795 9.74587C22.8795 9.82405 22.8534 9.87616 22.8013 9.92828L18.215 14.3843C17.9804 14.6188 17.8501 14.9576 17.9023 15.2964L18.9967 21.6025C19.0228 21.7068 18.9707 21.811 18.8925 21.8631C18.8143 21.9153 18.7101 21.9413 18.6058 21.8892L12.9772 18.8924C12.6645 18.7361 12.2997 18.7361 12.013 18.8924L6.35828 21.8631C6.22799 21.9413 6.07164 21.8892 5.99346 21.7589C5.9674 21.7068 5.9674 21.6547 5.9674 21.5765L7.03581 15.2703C7.08792 14.9315 6.98369 14.5928 6.74916 14.3582L2.16284 9.92828C2.08467 9.8501 2.08467 9.74587 2.11073 9.66769Z"
        fill="white"
      />
    </svg>
  );
}
