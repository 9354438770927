import {useState, useEffect} from 'react';
import {Image} from '@shopify/hydrogen';
import {UploadUserImage} from './UploadUserImage';
import {colors} from '~/lib/utils';

export function UserAvatar({
  customer = {},
  size = 100,
  background = null,
  className,
  disableMarginRight,
  showEdit = false,
}) {
  const [image, setImage] = useState(customer?.image);

  useEffect(() => {
    if (customer?.image) {
      setImage(customer.image);
    }
  }, [customer]);

  return (
    <div className={className}>
      <div
        className={
          disableMarginRight
            ? !image
              ? `relative rounded-full text-onSecondary flex items-center justify-center font-serif text-xs overflow-hidden`
              : `relative rounded-full text-onSecondary flex font-serif text-xs overflow-hidden`
            : !image
            ? `relative rounded-full mr-3 text-onSecondary flex items-center justify-center font-serif text-xs overflow-hidden`
            : `relative rounded-full mr-3 text-onSecondary flex font-serif text-xs overflow-hidden`
        }
        style={{
          width: `${size}px`,
          height: `${size}px`,
          background:
            background || colors()[Math.floor(Math.random() * colors().length)],
        }}
      >
        {showEdit && customer ? <UploadUserImage setImage={setImage} /> : null}
        {image ? (
          <Image
            data={{url: image, width: size, height: size, altText: 'Avatar'}}
            className="object-cover"
          />
        ) : customer ? (
          <span className={size > 70 ? 'text-2xl' : 'text-sm'}>
            {customer?.firstName && customer.lastName
              ? customer.firstName?.charAt(0) + customer.lastName?.charAt(0)
              : '...'}
          </span>
        ) : (
          'N/A'
        )}
      </div>
    </div>
  );
}
