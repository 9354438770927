/**
 * A shared component that specifies the icon to render when opened
 */
export function AccountMobileIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6008 9.84749C17.6008 13.1385 15.9592 15.2334 13.7748 15.8719C13.271 16.0192 12.7306 16.0192 12.2268 15.8719C10.0423 15.2334 8.40078 13.1385 8.40078 9.84749C8.40078 8.71173 8.73662 7.71627 9.43554 7.00618C10.1307 6.29989 11.26 5.79998 13.0008 5.79998C14.5404 5.79998 15.6837 6.29131 16.4383 7.01759C17.1944 7.74538 17.6008 8.74718 17.6008 9.84749Z"
        stroke="#E75113"
        strokeWidth="1.2"
      />
      <mask id="path-2-inside-1_631_11894" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.45703 21.8318C4.38669 19.3716 6.7579 17.6801 9.46851 17.6801H16.535C19.2427 17.6801 21.6118 19.368 22.5435 21.8239C20.1686 24.3921 16.7704 26.0001 12.9966 26.0001C9.22668 26.0001 5.83155 24.3954 3.45703 21.8318Z"
        />
      </mask>
      <path
        d="M3.45703 21.8318L2.3345 21.4076L2.07197 22.1023L2.57665 22.6472L3.45703 21.8318ZM22.5435 21.8239L23.4245 22.6386L23.9291 22.093L23.6655 21.3982L22.5435 21.8239ZM4.57956 22.256C5.33554 20.2554 7.26475 18.8801 9.46851 18.8801V16.4801C6.25106 16.4801 3.43784 18.4878 2.3345 21.4076L4.57956 22.256ZM9.46851 18.8801H16.535V16.4801H9.46851V18.8801ZM16.535 18.8801C18.7364 18.8801 20.6639 20.2525 21.4215 22.2496L23.6655 21.3982C22.5597 18.4835 19.749 16.4801 16.535 16.4801V18.8801ZM21.6625 21.0092C19.5052 23.3421 16.4221 24.8001 12.9966 24.8001V27.2001C17.1187 27.2001 20.8321 25.4422 23.4245 22.6386L21.6625 21.0092ZM12.9966 24.8001C9.57465 24.8001 6.49435 23.3451 4.33741 21.0164L2.57665 22.6472C5.16875 25.4458 8.87871 27.2001 12.9966 27.2001V24.8001Z"
        fill="#E75113"
        mask="url(#path-2-inside-1_631_11894)"
      />
      <circle cx="13" cy="13" r="12.4" stroke="#E75113" strokeWidth="1.2" />
    </svg>
  );
}
