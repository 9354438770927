export function OpenBookIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 13.75V1.75C19.5 1.47298 19.2766 1.25 19.0013 1.25H12.5C11.9126 1.25 11.2834 1.54873 10.7911 2.04105C10.2987 2.53338 10 3.16262 10 3.75V16.5C10 16.5883 10.0047 16.6581 10.0135 16.7121C10.0157 16.7259 10.018 16.7377 10.0203 16.7476C10.062 16.7386 10.1498 16.6983 10.294 16.5319C10.4629 16.3371 10.6273 16.066 10.8213 15.7428L10.8339 15.7217C11.0121 15.4246 11.2184 15.0808 11.4503 14.8132C11.682 14.5458 12.0276 14.25 12.5 14.25H19C19.2761 14.25 19.5 14.0261 19.5 13.75Z"
        stroke="#E75113"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 13.75V1.75C0.5 1.47314 0.723475 1.25 0.998901 1.25H7.78571C8.28315 1.25 8.83637 1.53151 9.28102 2.02296C9.72264 2.51106 10 3.14575 10 3.75V16.5C10 16.5883 9.99527 16.6581 9.98654 16.7121C9.98429 16.726 9.98194 16.7378 9.97966 16.7477C9.93473 16.7386 9.84049 16.6964 9.68667 16.5252C9.51115 16.3298 9.33763 16.057 9.13942 15.7368C9.12771 15.7179 9.1159 15.6988 9.10399 15.6795C8.92698 15.3932 8.72708 15.0698 8.51311 14.8173C8.29828 14.5639 7.96217 14.25 7.5 14.25H1C0.723858 14.25 0.5 14.0261 0.5 13.75Z"
        stroke="#E75113"
        strokeLinejoin="round"
      />
    </svg>
  );
}
