import {
  Button,
  SpinnerIcon,
  QuestionCard,
  Toast,
  CheckIcon,
  ReviewStarSelection,
  NotaTa,
  AddReview,
  SeeReview,
  ReviewCard,
} from '~/components';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {useEffect, useState, useCallback} from 'react';
import {v4 as uuidv4} from 'uuid';
import remove from 'lodash/remove';
import {UserAvatar} from './global/UserAvatar';
import {getAuthor} from '~/lib/utils';
import {flattenConnection} from '@shopify/hydrogen';

export function ReviewGrid({url, data, product, userReview, ...props}) {
  const {customer} = useOutletContext();
  const [initialReviews, setInitialReviews] = useState(data.items || []);

  const [nextPage, setNextPage] = useState(data.pageInfo.hasNextPage);
  const [endCursor, setEndCursor] = useState(data.pageInfo.endCursor);
  const [reviews, setReviews] = useState(initialReviews);
  const [review, setReview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toasts, setToasts] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedStars, setSelectedStars] = useState(null);
  const [hoveredStars, setHoveredStars] = useState(null);
  const [focusedStar, setFocusedStar] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  const author = product
    ? getAuthor(flattenConnection(product.collections))
    : null;

  const addToasts = useCallback(
    (items) => {
      //setToasts([...toasts, ...items]);
      setToasts([...items]);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    (items) => {
      let previousToasts = [...toasts];
      for (let index = 0; index < items.length; index++) {
        const toast = items[index];
        remove(previousToasts, function (o) {
          return o.id === toast.id;
        });
      }

      setToasts([...previousToasts]);
    },
    [setToasts, toasts],
  );

  // props have changes, reset component state
  const reviewProps = reviews || [];
  if (initialReviews !== reviewProps) {
    setInitialReviews(reviewProps);
    setReviews(reviewProps);
  }

  const fetcher = useFetcher();

  function fetchMoreReviews() {
    fetcher.load(`${url}?index&lastReview=${endCursor}`);
  }

  useEffect(() => {
    if (!fetcher.data) return;

    const {reviews} = fetcher.data;

    if (reviews) {
      setReviews((prev) => [...prev, ...reviews.items]);
      setNextPage(reviews.pageInfo.hasNextPage);
      setEndCursor(reviews.pageInfo.endCursor);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (
      fetcher.type == 'actionReload' &&
      fetcher.state == 'loading' &&
      !fetcher.data?.error
    ) {
      window.location.reload();
    }

    if (
      fetcher.state !== 'idle' &&
      fetcher.state !== 'submitting' &&
      fetcher.data?.error
    ) {
      setError(fetcher.data.error);
    }
  }, [fetcher.state]);

  const handleHoveredStars = (star) => {
    setHoveredStars(star);
  };
  const handleSelectedStars = (star) => {
    setSelectedStars(star);
  };
  const handleFocusedStar = (star) => {
    setFocusedStar(star);
  };

  const addReview = ({
    review,
    title,
    stars,
    product_handle,
    product_id,
    product_title,
    product_image_src,
    product_image_width,
    product_image_height,
    product_image_alt,
    product_author,
    shelve,
  }) => {
    fetcher.submit(
      {
        review,
        title,
        stars,
        product_handle,
        product_id,
        product_title,
        product_image_src,
        product_image_width,
        product_image_height,
        product_image_alt: product_image_alt || title,
        product_author,
        shelve,
      },
      {method: 'post', action: `/api/reviews/add`},
    );
  };

  if (!initialReviews) {
    return null;
  }

  return (
    <>
      <div className="relative flex flex-col md:flex-row items-center gap-4 justify-between pt-4">
        <div className="flex-grow md:flex-grow-0 w-full md:w-auto">
          <div className="flex mb-2 md:mb-0 items-center justify-center md:justify-start border border-gray-200 rounded-lg p-2 pr-5 overflow-hidden">
            {customer ? (
              <UserAvatar size={32} customer={customer} background="#ffffff" />
            ) : null}
            <div className="text-xs font-serif">NOTA TA</div>
            {!userReview ? (
              <div className="ml-3">
                <ReviewStarSelection
                  handleSelectedStars={handleSelectedStars}
                  handleFocusedStar={handleFocusedStar}
                  handleHoveredStars={handleHoveredStars}
                  hoveredStars={hoveredStars}
                  selectedStars={selectedStars}
                  focusedStar={focusedStar}
                  setOpen={setOpen}
                  source="page"
                />
              </div>
            ) : (
              <span className="ml-2">
                <NotaTa
                  nota={parseInt(userReview?.stars) || 0}
                  customer={customer}
                />
              </span>
            )}
          </div>
        </div>

        {userReview ? (
          <Button
            label="Vezi recenzia"
            variant="sm_outlined_primary"
            handleClick={() => {
              setOpen(true);
            }}
            className="flex-grow md:flex-grow-0 w-full md:w-auto"
            disabled={isSending}
          />
        ) : customer ? (
          <Button
            label={isSending ? <SpinnerIcon size="34" /> : 'Scrie o recenzie'}
            variant="sm_primary"
            handleClick={() => {
              setOpen(true);
            }}
            className="flex-grow md:flex-grow-0 w-full md:w-auto"
            disabled={isSending}
          />
        ) : (
          <Button
            label={isSending ? <SpinnerIcon size="34" /> : 'Scrie o recenzie'}
            variant="sm_primary"
            url="/profil/login"
            className="flex-grow md:flex-grow-0 w-full md:w-auto"
            disabled={isSending}
          />
        )}
      </div>

      {reviews?.length > 0 ? (
        <div className="grid grid-cols-1 gap-x-8">
          <div className="col-span-1 text-left md:text-center">
            <div className="border-t border-gray-200 mt-10 pt-8">
              {reviews && reviews.length > 0 ? (
                <div className="font-serif text-left uppercase text-xs mb-9">
                  Recenzii prieteni
                </div>
              ) : null}
              {reviews.map((review, i) => {
                return (
                  <ReviewCard
                    key={review.id}
                    review={review}
                    isLast={i === reviews.length - 1}
                    product={product}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {nextPage && (
        <div className="flex items-center justify-center mt-6 pb-8 md:pb-28">
          <Button
            disabled={fetcher.state !== 'idle'}
            variant="secondary"
            handleClick={fetchMoreReviews}
            width="full"
            prefetch="intent"
            label={
              fetcher.state !== 'idle' ? (
                <SpinnerIcon size={24} />
              ) : (
                'Mai multe întrebări'
              )
            }
          />
        </div>
      )}
      {customer && !userReview ? (
        <AddReview
          customer={customer}
          image={{
            url: product.featuredImage.url,
            width: product.featuredImage.width,
            height: product.featuredImage.height,
          }}
          title={product.title}
          id={product.id}
          handle={product.handle}
          author={author?.title || 'Necunoscut'}
          open={open}
          setOpen={setOpen}
          addToasts={addToasts}
          addReview={addReview}
          stars={selectedStars}
          setStars={setSelectedStars}
          error={error}
        />
      ) : null}

      {userReview ? (
        <SeeReview
          review={userReview}
          open={open}
          setOpen={setOpen}
          title={product.title}
          customer={customer}
        />
      ) : null}
      <div className="fixed bottom-0 right-3 left-3 md:left-auto z-50 w-auto md:w-1/2 xl:w-1/3">
        {toasts.map((toast) => {
          return (
            <Toast toast={toast} key={toast.id} removeToasts={removeToasts} />
          );
        })}
      </div>
    </>
  );
}
