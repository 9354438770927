import {useState, useEffect} from 'react';
import {Image, Money} from '@shopify/hydrogen';
import {Metrics, ProductCardActions, WarningIcon} from '~/components';
import {useFetcher} from '@remix-run/react';

export function ProductCard({
  url,
  handle,
  id,
  title,
  image,
  author,
  customer,
  price,
  compareAtPrice,
}) {
  console.log('PRICE', price, compareAtPrice);
  const [data, setData] = useState(null);
  const [userReview, setUserReview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [average, setAverage] = useState(0);
  const [open, setOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const product = useFetcher();
  const [error, setError] = useState(null);

  useEffect(() => {
    product.load(`/api/carti/${handle}/details`);
  }, []);

  useEffect(() => {
    if (product.type === 'done') {
      setData(product?.data);
      setUserReview(product.data.review);
    }
  }, [product.type]);

  useEffect(() => {
    if (data) {
      let total = data?.count_reviews || 0;
      let five_star_reviews = data?.count_five_star_reviews || 0;
      let four_star_reviews = data?.count_four_star_reviews || 0;
      let three_star_reviews = data?.count_three_star_reviews || 0;
      let two_star_reviews = data?.count_two_star_reviews || 0;
      let one_star_reviews = data?.count_one_star_reviews || 0;
      let total_five = five_star_reviews * 5;
      let total_four = four_star_reviews * 4;
      let total_three = three_star_reviews * 3;
      let total_two = two_star_reviews * 2;
      let total_one = one_star_reviews * 1;

      let tempAverage =
        total > 0
          ? (total_five + total_four + total_three + total_two + total_one) /
            total
          : 0;

      setAverage(tempAverage);
      setIsLoading(false);
    }
  }, [data]);

  const addReview = ({
    review,
    title,
    stars,
    product_handle,
    product_id,
    product_title,
    product_image_src,
    product_image_width,
    product_image_height,
    product_image_alt,
    product_author,
    shelve,
  }) => {
    product.submit(
      {
        review,
        title,
        stars,
        product_handle,
        product_id,
        product_title,
        product_image_src,
        product_image_width,
        product_image_height,
        product_image_alt: product_image_alt || title,
        product_author,
        shelve,
      },
      {method: 'post', action: `/api/reviews/add`},
    );
  };

  // useEffect(() => {
  //   // if (product.state === 'submitting' || product.state === 'loading') {
  //   //   setIsLoading(true);
  //   // } else {
  //   //   setIsLoading(false);
  //   // }
  // }, [product.state]);

  useEffect(() => {
    if (
      product.type == 'actionReload' &&
      product.state == 'loading' &&
      !product.data?.error
    ) {
      window.location.reload();
    }

    if (
      product.state !== 'idle' &&
      product.state !== 'submitting' &&
      product.data?.error
    ) {
      setError(product.data.error);
    }
  }, [product.state]);

  return (
    <div className="flex-grow-0 flex-shrink-0 basis-[90%] sm:basis-[80%] lg:basis-[33%] xl:basis-[25%] h-full relative transition-transform">
      <div className="bg-white rounded-3xl overflow-hidden lg:m-0 h-full">
        <div className="p-10 flex items-center justify-center">
          {image.url ? (
            <a
              href={url}
              style={{height: '200px', overflow: 'hidden', display: 'flex'}}
            >
              {image.url && image.url !== 'undefined' ? (
                <Image
                  data={{
                    url: image.url,
                    width: image.width,
                    height: image.height,
                    altText: title,
                  }}
                  style={{maxWidth: '134px', objectFit: 'cover'}}
                />
              ) : (
                <Image
                  data={{
                    url: 'https://via.placeholder.com/400x600/eeeeee/ffffff?text=...',
                    width: 400,
                    height: 600,
                    altText: title,
                  }}
                  style={{maxWidth: '134px', objectFit: 'cover'}}
                />
              )}
            </a>
          ) : null}
        </div>
        <div className="px-4 border-b border-solid border-line">
          <div className="font-serif leading-tight text-xl h-14 overflow-hidden">
            {title?.split(' -')[0]}
          </div>
          <div className="font-serif mt-2 mb-4 h-6 overflow-hidden flex justify-between">
            {author}
            <Money
              data={price}
              as="span"
              className="text-gray-500 font-serif"
            />
          </div>
        </div>

        <Metrics
          isDataLoading={isLoading}
          count_reviews={data?.count_reviews}
          count_reviews_with_description={data?.count_reviews_with_description}
          average={average}
        />

        <ProductCardActions
          id={id}
          image={image}
          author={author}
          handle={handle}
          url={url}
          title={title}
          userReview={userReview}
          customer={customer}
          addReview={addReview}
          open={open}
          setOpen={setOpen}
          listOpen={listOpen}
          setListOpen={setListOpen}
          isDataLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
}
