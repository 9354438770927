/**
 * A shared component that specifies the icon to render when opened
 */
export function HeartIcon({className}) {
  return (
    <span className={className}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.65709 1.98862C6.84968 2.17011 7.15033 2.17011 7.34292 1.98862C8.79186 0.623164 11.0126 0.671289 12.4075 2.13839C13.8642 3.67053 13.8642 6.16457 12.4075 7.69671L7.62759 12.7242C7.27795 13.0919 6.72205 13.0919 6.37241 12.7242L1.59251 7.69671C0.135828 6.16457 0.135828 3.67053 1.59251 2.13839C2.98737 0.671289 5.20814 0.623164 6.65709 1.98862Z"
          style={{stroke: 'currentcolor'}}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
