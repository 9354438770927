export function AnimaleIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6684 8.46138C27.2663 8.46138 27.7509 7.97673 27.7509 7.37888C27.7509 6.78104 27.2663 6.29639 26.6684 6.29639C26.0706 6.29639 25.5859 6.78104 25.5859 7.37888C25.5859 7.97673 26.0706 8.46138 26.6684 8.46138Z"
        fill="#514D71"
      />
      <path
        d="M38.891 11.1484L32.9257 8.20797C32.3039 3.83961 28.542 0.5 24.1122 0.5C19.1988 0.5 15.1989 4.54592 15.1989 9.51311C15.1989 12.3537 16.4887 14.9563 18.7074 16.6837C19.337 17.175 19.7439 19.1327 17.8092 19.2939H9.36419C7.99763 19.2939 7.22223 18.0886 6.27025 16.3843C5.55626 15.1098 4.82692 13.7893 3.46037 13.7893C1.6255 13.7893 0.926873 15.5705 0.773328 16.4917C-0.0942028 21.7123 0.37411 37.1282 13.3948 39.4851C13.4562 39.4928 22.2083 39.5005 22.2083 39.5005C25.5939 39.5005 28.7723 38.1647 31.1676 35.7463C33.5553 33.328 34.8681 30.1112 34.8681 26.6871C34.8681 23.5855 32.9334 20.791 30.0467 19.7315C29.4633 19.5166 29.0717 18.9638 29.0717 18.3496V17.0061C30.0698 16.3228 30.9296 15.4476 31.5822 14.4189L38.5992 13.1905C39.0598 13.1061 39.4207 12.7376 39.4821 12.2769C39.5512 11.8086 39.3132 11.3557 38.891 11.1484ZM30.7223 12.3691C30.3999 12.4228 30.1235 12.6224 29.9623 12.9065C29.3635 13.9736 28.4959 14.8565 27.4441 15.4707C27.1063 15.6626 26.9067 16.0234 26.9067 16.4073V18.3496C26.9067 19.8697 27.8664 21.244 29.302 21.766C31.3365 22.5107 32.7031 24.4915 32.7031 26.6871C32.7031 29.5354 31.6129 32.2148 29.6322 34.2185C27.6438 36.2223 25.0105 37.3278 22.2159 37.3278H13.6942C2.47 35.2319 2.04008 21.4896 2.90761 16.8526C3.0458 16.1232 3.36824 16.0234 3.42966 15.985C3.68301 16.2 4.08991 16.937 4.37397 17.436C5.31827 19.1327 6.61573 21.4513 9.36419 21.4513H17.8092C19.7976 21.4513 21.4098 19.8237 21.4098 17.8199C21.4098 16.699 20.8954 15.6319 20.0279 14.964C18.3312 13.6588 17.3639 11.6704 17.3639 9.51311C17.3639 5.73589 20.3887 2.66499 24.1122 2.66499C27.6284 2.66499 30.5765 5.44416 30.8298 8.99873C30.8605 9.3826 31.0832 9.7204 31.4286 9.89697L34.9525 11.632L30.7223 12.3691Z"
        fill="#514D71"
      />
      <path
        d="M21.8094 24.668C21.249 24.4607 20.6271 24.7448 20.4198 25.3129C20.2126 25.8733 20.4966 26.4952 21.0647 26.7025C22.0014 27.048 23.0455 28.2072 22.9917 29.1822C22.938 30.2417 21.4563 30.7714 20.5811 30.9941C13.5948 32.7752 9.29548 26.1344 9.11123 25.858C8.79646 25.3513 8.12086 25.1977 7.61416 25.5202C7.10746 25.835 6.95392 26.5106 7.27636 27.0173C7.32243 27.094 11.4221 33.4662 18.2472 33.4662C22.5388 33.4662 25.1567 31.6697 25.149 29.2974C25.149 27.2322 23.4984 25.2899 21.8094 24.668Z"
        fill="#514D71"
      />
    </svg>
  );
}
