import {useState, useEffect} from 'react';
import {useFetcher, useOutletContext} from '@remix-run/react';
import {Button, SpinnerIcon, LikeIcon} from '~/components';

export function ListPageActions({list}) {
  const {customer} = useOutletContext();
  const customer_id = customer
    ? customer.id.replace('gid://shopify/Customer/', '')
    : null;
  const [isLiked, setIsLiked] = useState(list.liked || false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const fetcher = useFetcher();

  const handleLike = () => {
    setIsLikeLoading(true);
    fetcher.submit({id: list.id}, {method: 'post', action: `/api/lists/like`});
  };
  const handleDelete = () => {
    setIsDeleteLoading(true);
    fetcher.submit(
      {id: list.id},
      {method: 'post', action: `/api/lists/delete`},
    );
  };

  useEffect(() => {
    if (!fetcher.data) return;

    setIsLiked(fetcher.data.liked);
    setIsLikeLoading(false);
  }, [fetcher.data]);

  return (
    <div className="border-t border-line pt-6 mt-8">
      <div className="flex">
        {customer ? (
          <div className="flex flex-grow items-center justify-center gap-3">
            <Button
              className={
                isLiked
                  ? 'flex flex-grow justify-center !bg-onSecondary !text-secondary hover:!bg-secondary hover:!text-onSecondary'
                  : 'flex flex-grow justify-center bg-secondary !text-onSecondary hover:bg-onSecondary hover:!text-secondary'
              }
              label={
                isLikeLoading ? (
                  <SpinnerIcon size={30} />
                ) : (
                  <span className="flex items-center">
                    <span className="mr-3">
                      <LikeIcon />
                    </span>{' '}
                    {`Îmi place (${list.count_likes || 0})`}
                  </span>
                )
              }
              disabled={isLikeLoading}
              variant="naked"
              handleClick={() => {
                handleLike(list.id);
              }}
            />

            {customer_id && list.customer?.id === customer_id ? (
              <Button
                className={
                  'flex flex-grow justify-center bg-secondary !text-onSecondary hover:bg-onSecondary hover:!text-secondary'
                }
                label={
                  isDeleteLoading ? (
                    <SpinnerIcon size={30} />
                  ) : (
                    <span className="flex items-center">Șterge lista</span>
                  )
                }
                disabled={isDeleteLoading}
                variant="naked"
                handleClick={() => {
                  handleDelete(list.id);
                }}
              />
            ) : null}
          </div>
        ) : (
          <Button
            className="flex flex-grow justify-center bg-secondary !text-onSecondary"
            label={
              <span className="flex items-center">
                <span className="mr-3">
                  <LikeIcon />
                </span>{' '}
                {`Îmi place (${list.count_likes || 0})`}
              </span>
            }
            variant="naked"
            url="/profil/login"
          />
        )}
      </div>
    </div>
  );
}
